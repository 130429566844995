import { useContext } from 'react'
import { DateTime } from 'luxon'

import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import GarageIcon from '@mui/icons-material/Garage'

import MakeModelYear from 'presentation/components/MakeModelYear'
import VisitCount from './VisitCount'
import { Dollars } from 'tools'
import { TechnicianContext } from 'GlobalStore'
import { calculateCommissionAmountForHours } from '@carbodylab/payroll-calculator'

const useStyles = makeStyles(theme => ({
  appointmentCard: {
    padding: '0rem 0rem 0rem 0rem',
    fontFamily: 'Open Sans',
  },
  appointmentHeading: {
    color: theme.mode === 'dark' ? '#fff' : '#373845',
    fontWeight: 700,
    display: 'block',
    padding: '.5rem',
    textAlign: 'center',
  },
  appointmentBody: {
    padding: '.5rem 1rem',
  },
}))

const LaborCostCommissionComponent = ({ technician, quote }) => {
  let commissionRate
  let commissionLabel

  let suppliesCostPercentage = technician.suppliesCostPercentage
  let netLaborRevenuePercentage = technician.netLaborRevenuePercentage

  if (quote.payoutData) {
    commissionRate = quote.payoutData.commissionPercentApplied
    commissionLabel = 'Labor commission: '

    if (commissionRate === 0) {
      return <div>Total paid out: ${quote.payoutData.totalPayout}</div>
    }
  } else {
    if (quote.commissionPercentOverride && Number(quote.commissionPercentOverride) > 0) {
      commissionRate = quote.commissionPercentOverride
      commissionLabel = 'Labor Commission Overridden to '
    } else {
      commissionRate = technician.commissionPercent
      commissionLabel = 'Labor commission: '
    }

    if (Number(suppliesCostPercentage) > 0 && Number(netLaborRevenuePercentage) > 0) {
      const netRevenue =
        ((100 - Number(suppliesCostPercentage)) / 100) * (quote?.primaryLaborSum + quote.technicianChargesSum)

      return (
        <Grid container sx={{ fontSize: '14px', textAlign: 'center' }}>
          <Grid item xs={6}>
            Net Commission: {netLaborRevenuePercentage}%
          </Grid>
          <Grid item xs={6}>
            Net Revenue: ${netRevenue.toFixed(2)}
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <div>
      {commissionLabel}
      {commissionRate}%
    </div>
  )
}

const HourlyCommissionComponent = ({ technician, hourDifference }) => {
  let commissionRate = technician.commissionAmount
  let commissionLabel = 'Labor commission: $'

  const { commissionAmount } = calculateCommissionAmountForHours({
    hours: hourDifference,
    commissionRate: commissionRate,
  })

  return (
    <div>
      {commissionLabel}
      {commissionAmount}
    </div>
  )
}

const Appointment = ({ job }) => {
  const classes = useStyles()
  const theme = useTheme()

  const quote = job.quote
  const lead = quote.lead
  const [technician] = useContext(TechnicianContext)

  const formatTime = ts => DateTime.fromISO(ts, { zone: 'utc' }).toFormat('h:mma')

  // calculate the difference in hours of 2 dates
  const calculateHourDifference = (startDateTime, endDateTime) => {
    const start = DateTime.fromISO(startDateTime, { zone: 'utc' })
    const end = DateTime.fromISO(endDateTime, { zone: 'utc' })
    const diff = end.diff(start, 'hours').toObject()
    return diff.hours
  }

  const hourDifference = calculateHourDifference(job.startDatetime, job.endDatetime)

  return (
    <>
      <Paper className={classes.appointmentCard}>
        <Typography
          style={{
            fontFamily: 'Open Sans',
            fontSize: '.9rem',
            background: theme.palette.mode === 'dark' ? '#222430' : '#eef1f5',
          }}
          className={classes.appointmentHeading}
        >
          {DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toFormat('cccc LLLL d')},{' '}
          {formatTime(job.startDatetime)} - {formatTime(job.endDatetime)}
        </Typography>

        <VisitCount job={job} />

        <Grid container spacing={1}>
          <Grid item xs={12} style={{ padding: '1em', position: 'relative' }}>
            <Typography>
              <b>{lead.name}</b>
            </Typography>
            <Typography>
              {lead.addressLineOne}
              {lead.addressLineTwo && (
                <>
                  <br />
                  {lead.addressLineTwo}
                </>
              )}
            </Typography>
            <Typography>
              {lead.city}, {lead.state} {lead.zip}
              <br />
              {DateTime.fromISO(job.startDatetime, { zone: 'utc' }) < DateTime.now().endOf('day') && (
                <> phone: {lead.phone}</>
              )}
            </Typography>

            <div style={{ margin: '9px 0' }}>
              {lead.garageConfirmed && (
                <>
                  <Chip
                    icon={<GarageIcon sx={{ fontSize: 24 }} />}
                    label='Garage Confirmed'
                    color='success'
                    variant='outlined'
                  />
                </>
              )}
            </div>

            <hr />

            {lead.year && lead.make && lead.model ? (
              <strong>
                {' '}
                {lead.year} - {lead.make} - {lead.model}
              </strong>
            ) : (
              lead.year &&
              lead.makeShortName &&
              lead.modelShortName && (
                <MakeModelYear
                  year={lead.year}
                  makeShortName={lead.makeShortName}
                  modelShortName={lead.modelShortName}
                />
              )
            )}

            <hr />

            {technician.quoteCommissionStructure === 'hourly' ? (
              <HourlyCommissionComponent technician={technician} hourDifference={hourDifference} quote={quote} />
            ) : (
              <LaborCostCommissionComponent technician={technician} hourDifference={hourDifference} quote={quote} />
            )}

            {quote.technicianPayoutBonus && Number(quote.technicianPayoutBonus) > 0 && (
              <Typography style={{ color: 'green' }}>
                Payout Bonus:&nbsp;
                <Dollars value={quote.technicianPayoutBonus} />
              </Typography>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default Appointment
