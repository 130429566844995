import { Drawer, List, ListItem, useTheme, Paper, Grid, Typography, Switch } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import { useQuery, gql } from '@apollo/client'
import { ThemeContext } from 'GlobalStore'
import { useContext, useState, useEffect } from 'react'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'

const Settings = () => {
  const [theme, setTheme] = useContext(ThemeContext)
  const [darkmode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'true' ? true : false )
  const currentTheme = useTheme()

  const useStyles = makeStyles(theme => ({
    payoutCard: {
      border: '1px solid #dddddd64', padding: '.125rem 1rem .5rem 1rem', marginBottom: '1rem',
      color: '#555',
      '& p': {
        display: 'block', fontFamily: 'Open Sans', color: '#3E3745', fontWeight: 600,
        paddingBottom: '0px', margin: '0px'
      },
      '& span': {
        fontFamily: 'Open Sans', color: '#3E3745',
      }
    },
    pageHeading: {
      padding: '0 1rem 2rem 1rem',
      fontFamily: 'Open Sans',
      color: theme.mode === 'dark' ? '#fff' : '#373845',
      fontWeight: 700,
      display: 'block',
    },
    sectionHeading: {
      display: 'block', fontSize: '1rem', color: theme.mode === 'dark' ? '#fff' : '#373845', fontWeight: 500,
      borderBottom: '1px solid #ddd', padding: '1rem 0 1rem 0'
    },
    switchHeading: {
      color: theme.mode === 'dark' ? '#fff' : '#373845', fontWeight: 500, fontFamily: 'Open Sans', padding: '1rem 0rem 1rem 0rem'
    }
  }))

  useEffect(() => {
    if(darkmode) {
      setTheme(true)
      localStorage.setItem('darkMode', true)
    }
    else {
      setTheme(false)
      localStorage.setItem('darkMode', false)
    }
  }, [darkmode])

  const history = useHistory()
  const classes = useStyles(currentTheme)

  return <Grid container>
    <Grid item xs={12} className={classes.pageHeading} >
      <Typography variant='p' style={{fontSize: '2rem'}}>
        Settings
      </Typography>
      <Typography variant='p' style={{display: 'block', marginTop: '.5rem', fontSize: '1rem', opacity: .75, fontWeight: 400}}>
        Nothing here yet!
      </Typography>
    </Grid>

    {/* <Grid item xs={12}>
      <Paper style={{margin: '1rem 0'}}>
        <Grid container style={{padding: '0 1rem '}}>
          <Grid item xs={12} className={classes.sectionHeading}>
            <Typography variant='p'>
              Notifications
            </Typography>
          </Grid>
          <Grid item xs={9} className={classes.switchHeading}>
            <span>
              Allow emails
            </span>
            <br />
            <span style={{color: '#999', fontWeight: 400}}>
              Send me emails about new jobs, reschedules or notes.
            </span>
          </Grid>
          <Grid item xs={3} style={{padding: '1rem 0rem 1rem 0rem'}} align='right'>
            <Switch />
          </Grid>
          <Grid item xs={9} className={classes.switchHeading}>
            <span>
              Allow texts
            </span>
            <br />
            <span style={{color: '#999', fontWeight: 400}}>
              Send me texts about new jobs, reschedules or notes.
            </span>
          </Grid>
          <Grid item xs={3} style={{padding: '1rem 0rem 0rem 0rem'}} align='right'>
            <Switch />
          </Grid>
        </Grid>
      </Paper>
    </Grid> */}

    <Grid item xs={12}>
      <Paper>
        <Grid container style={{padding: '0 1rem'}}>
          <Grid item xs={12} className={classes.sectionHeading}>
            <Typography variant='p'>
              Appearance
            </Typography>
          </Grid>
          <Grid item xs={9} className={classes.switchHeading}>
            Dark Mode
          </Grid>
          <Grid item xs={3} style={{padding: '1rem 0rem'}} align='right'>
            <Switch checked={darkmode} onChange={() => setDarkMode(!darkmode)} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>

  </Grid>
}

export default Settings
