import Index from './Index'
import InvoiceRouter from '../BToBInvoice/Router'
import WorkOrderRouter from '../WorkOrder/Router'
import { Switch, Route, useHistory } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'
import { useEventHandler } from '@ericlathrop/phoenix-js-react-hooks'
import { TechnicianChannelContext } from 'TechnicianChannelStore'

const BtoBInvoicesRouter = () => {
  const history = useHistory()
  const channel = useContext(TechnicianChannelContext)
  const [prefetchedInvoice, setPrefetchedInvoice] = useState(null)
  const [prefetchedWorkOrder, setPrefetchedWorkOrder] = useState(null)

  useEventHandler(channel, 'b_to_b_invoice_created', ({ bToBInvoice }) => setPrefetchedInvoice(bToBInvoice))

  useEffect(() => {
    prefetchedInvoice && history.push(`/b-to-b-invoices/${prefetchedInvoice.id}`)
  }, [prefetchedInvoice])

  useEventHandler(channel, 'b_to_b_work_order_created', ({ bToBWorkOrder }) => setPrefetchedWorkOrder(bToBWorkOrder))

  useEffect(() => {
    prefetchedWorkOrder && history.push(`/b-to-b-work-orders/${prefetchedWorkOrder.id}`)
  }, [prefetchedWorkOrder])

  return (
    <Switch>
      <Route path='/b-to-b-invoices/:bToBInvoiceId'>
        <InvoiceRouter invoice={prefetchedInvoice} />
      </Route>
      <Route path='/b-to-b-invoices'>
        <Index />
      </Route>
      <Route path='/b-to-b-work-orders/:bToBWorkOrderId'>
        <WorkOrderRouter workOrder={prefetchedWorkOrder} />
      </Route>
      <Route path='/b-to-b-work-orders'>
        <Index />
      </Route>
    </Switch>
  )
}

export default BtoBInvoicesRouter
