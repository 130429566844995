import { Grid, Grow, Collapse, Select, MenuItem, FormControl, InputLabel, Button, Box } from '@mui/material'
import { useQuery, gql } from '@apollo/client'
import { useEffect, useState, useContext } from 'react'
import { TechnicianContext } from 'GlobalStore'
import { bearerTokenHeaders, Dollars } from 'tools'
import { UserContext } from 'UserStore'

const PRICE_CATEGORIES = gql`
  query BToBPriceCategories($dealerId: String) {
    bToBPriceCategories(dealerId: $dealerId) {
      id
      createdAt
      name
      isCustom
      pricePoints {
        id
        price
        commissionAmount
        pointAmount
        panelGroup {
          id
          name
        }
        customPricePoint {
          id
          price
          commissionAmount
        }
      }
      categoriesWithoutPricePoints {
        id
        name
      }
    }
  }
`

const PANELS_QUERY = gql`
  query BToBPanels {
    bToBPanels {
      id
      name
      side
      panel
      type
      panelGroupId
      inheritPrice
      isCustom
      panelGroup {
        name
      }
    }
  }
`

const PANEL_GROUPS = gql`
  query bToBPanelGroups {
    bToBPanelGroups {
      id
      name
      isCustom
    }
  }
`

export const LineItemsSection = ({ workOrder, refetch }) => {
  const [technician] = useContext(TechnicianContext)
  const [user] = useContext(UserContext)
  const [editingLineItems, setEditingLineItems] = useState(false)
  const [waiting, setWaiting] = useState(false)

  const jasonId = '75267851-2fc0-4fed-8654-8709114e58fc'
  const shouldEnableLineItemPrices = technician.id === jasonId

  const {
    loading: loadingPanels,
    error: errorPanels,
    data: panelsData,
    refetch: refetchPanels,
  } = useQuery(PANELS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  const {
    loading: loadingPanelGroups,
    error: errorPanelGroups,
    data: panelGroupsData,
    refetch: refetchPanelGroups,
  } = useQuery(PANEL_GROUPS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  const {
    loading: loadingCategories,
    error: errorCategories,
    data: categoriesdata,
    refetch: refetchCategories,
  } = useQuery(PRICE_CATEGORIES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      dealerId: workOrder.dealerId,
    },
  })

  if (errorCategories || errorPanels || errorPanelGroups) return <div>Error!</div>

  if (loadingCategories || loadingPanels || loadingPanelGroups)
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '1rem' }}>Loading...</Box>
    )

  const priceCategories = categoriesdata?.bToBPriceCategories || []
  const panelsList = panelsData?.bToBPanels || []
  const panelGroups = panelGroupsData?.bToBPanelGroups || []

  const lineItems = []

  !loadingCategories &&
    !loadingPanels &&
    !loadingPanelGroups &&
    priceCategories.forEach(pricingCategory => {
      pricingCategory.pricePoints.forEach(pricePoint => {
        const categoryForPricePoint = panelGroups.find(panelGroup => panelGroup.id === pricePoint.panelGroup.id)
        const panelsInCategory = panelsList.filter(panel => panel.panelGroupId === categoryForPricePoint.id)

        panelsInCategory.forEach(panel => {
          let name = `${panel.panel} - ${pricingCategory.name}`

          if (panel.side) {
            if (panel.side === 'front' || panel.side === 'rear') {
              name = `${panel.panel} - ${panel.side} Side - ${pricingCategory.name}`
            } else if (panel.side !== '') {
              name = `${panel.panel} - ${panel.side} - ${pricingCategory.name}`
            } else {
              name = `${panel.panel} - ${pricingCategory.name}`
            }
          }

          if (pricePoint?.customPricePoint) {
            if (pricePoint.customPricePoint.price > 0) {
              lineItems.push({
                name: name,
                panelType: panel.panelType,
                panel: panel.panel,
                side: panel.side,
                type: pricingCategory.name,
                panelGroupId: categoryForPricePoint.id,
                price: pricePoint.customPricePoint?.price || pricePoint.price,
                pricePoint: {
                  ...pricePoint,
                },
                customPricePoint: {
                  ...pricePoint.customPricePoint,
                },
                panelGroup: {
                  name: categoryForPricePoint.name,
                },
              })
            }
          } else {
            lineItems.push({
              name: name,
              panelType: panel.panelType,
              panel: panel.panel,
              side: panel.side,
              type: pricingCategory.name,
              panelGroupId: categoryForPricePoint.id,
              price: pricePoint.customPricePoint?.price || pricePoint.price,
              pricePoint: {
                ...pricePoint,
              },
              panelGroup: {
                name: categoryForPricePoint.name,
              },
            })
          }
        })
      })
    })

  const lookupPrice = ({ lineItem, hasAtLeastOneSide = false }) => {
    const foundItem = lineItems.find(
      item =>
        item.panel === lineItem.panel &&
        (hasAtLeastOneSide ? item.side === lineItem.side : true) &&
        item.type === lineItem.type
    )

    const price = foundItem?.price || null
    return price
  }

  const availableSideOptionsForLineItemv2 = lineItem => {
    return (
      (lineItem.panel && [
        ...new Set(lineItems.filter(item => item.panel === lineItem.panel).map(item => item.side)),
      ]) ||
      []
    )
  }

  const requiresSideSelection = lineItem => availableSideOptionsForLineItemv2(lineItem).length > 1

  const lineItemIsValid = lineItem =>
    (requiresSideSelection(lineItem) ? lineItem.side : true) && lineItem.panel && lineItem.type && lineItem.price

  const NewLineItem = () => {
    const [lineItem, setLineItem] = useState({})

    const resetLineItem = () => setLineItem({})

    const handleSelectedPanel = panel => setLineItem({ panel })

    const handleSelectedSide = side => setLineItem(lineItem => ({ ...lineItem, side }))

    const possiblePanels = [...new Set(lineItems.slice().map(item => item.panel))].sort()
    const possibleSides = [
      ...new Set(lineItems.filter(item => item.panel === lineItem.panel).map(item => item.side)),
    ].sort()

    const hasAtLeastOneSide = possibleSides.length > 1

    const handleClickAdd = () => {
      setWaiting(true)

      const foundItem = lineItems.find(
        item => lineItem.panel === item.panel && (hasAtLeastOneSide ? item.side === lineItem.side : true) && item.type === lineItem.type
      )

      fetch(`https://${process.env.REACT_APP_API_HOST}/create_b_to_b_work_order_line_item`, {
        method: 'POST',
        headers: bearerTokenHeaders(user.token),
        body: JSON.stringify({
          bToBWorkOrderId: workOrder.id,
          lineItem: {
            ...lineItem,
            ...foundItem,
            technician_id: technician.id,
          },
          actorType: 'technician',
          actorId: technician.id,
        }),
      })
        .then(() => refetch && refetch())
        .catch(() => window.alert('There was an error creating a new workOrder.'))
        .finally(() => setWaiting(false))
    }

    const possibleTypes = lineItems
      .filter(item => item.panel === lineItem.panel && (hasAtLeastOneSide ? item.side === lineItem.side : true))
      .map(item => item.type)
      .sort()

    const lineItemIsSavable =
      (hasAtLeastOneSide ? lineItem.side : true) && lineItem.panel && lineItem.type && lineItem.price

    const handleSelectedType = type =>
      setLineItem(prev => ({
        ...prev,
        ...{
          type,
          price: lookupPrice({ lineItem: { ...lineItem, type }, hasAtLeastOneSide }),
        },
      }))

    useEffect(() => {
      setLineItem(prev => ({
        ...prev,
        price: lookupPrice({ lineItem, hasAtLeastOneSide }),
      }))
    }, [lineItem.panel, lineItem.side, lineItem.type])

    return (
      <Grid container spacing={0} sx={{ mb: '1.25rem' }}>
        <Grid item xs={12} sx={{ mb: '.25rem' }}>
          Add
        </Grid>

        <Grid item xs={12} sx={{ pr: '.25rem' }}>
          <Grid container>
            <Grid item xs={hasAtLeastOneSide ? 5 : 8} sx={{ pr: '.25rem' }}>
              <FormControl fullWidth size='small'>
                <InputLabel>Type</InputLabel>
                <Select
                  label='type'
                  size='small'
                  value={lineItem.panel || ''}
                  onChange={e => handleSelectedPanel(e.target.value)}
                >
                  {possiblePanels.map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {hasAtLeastOneSide && (
              <Grid item xs={3}>
                <FormControl fullWidth size='small'>
                  <InputLabel>Side</InputLabel>
                  <Select label='side' value={lineItem.side || ''} onChange={e => handleSelectedSide(e.target.value)}>
                    {possibleSides.map(item => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={4} sx={{ pr: '.5rem', pl: '.5rem' }}>
              <FormControl fullWidth size='small'>
                <InputLabel>Type</InputLabel>
                <Select label='type' value={lineItem.type || ''} onChange={e => handleSelectedType(e.target.value)}>
                  {possibleTypes.map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Collapse in={lineItemIsSavable} sx={{ width: '100%' }}>
          <Grid container>
            <Grid item xs={12} sx={{ display: 'flex', mt: '.5rem' }}>
              <Button onClick={resetLineItem} color='error'>
                Cancel
              </Button>
              <Button
                disabled={waiting}
                onClick={handleClickAdd}
                disableElevation
                variant='contained'
                sx={{ ml: 'auto' }}
              >
                Add {shouldEnableLineItemPrices && <>({`$${lineItem.price}`}) </>}
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    )
  }

  const EditLineItem = ({ lineItem }) => {
    const [editableLineItem, setEditableLineItem] = useState({ ...lineItem })

    const resetEditableLineItem = () => setEditableLineItem({ ...lineItem })

    const handleSelectPanel = panel =>
      setEditableLineItem(prev => ({
        ...prev,
        ...{ panel, side: null, type: null, price: null },
      }))

    const handleSelectSide = side =>
      setEditableLineItem(prev => ({
        ...prev,
        ...{ side },
      }))

    const handleSelectType = type =>
      setEditableLineItem(prev => ({
        ...prev,
        ...{
          type,
          price: lookupPrice({ lineItem: { ...lineItem, type } }),
        },
      }))

    const handleClickSave = () =>
      fetch(`https://${process.env.REACT_APP_API_HOST}/update_b_to_b_work_order_line_item`, {
        method: 'POST',
        headers: bearerTokenHeaders(user.token),
        body: JSON.stringify({
          bToBWorkOrderId: workOrder.id,
          lineItem: editableLineItem,
          actorType: 'technician',
          actorId: technician.id,
        }),
      })
        .then(() => refetch && refetch())
        .catch(() => window.alert('Error'))

    const handleClickDelete = () =>
      window.confirm('Are you sure you want to delete this line item?') &&
      fetch(`https://${process.env.REACT_APP_API_HOST}/delete_b_to_b_work_order_line_item`, {
        method: 'POST',
        headers: bearerTokenHeaders(user.token),
        body: JSON.stringify({
          bToBWorkOrderId: workOrder.id,
          lineItemId: lineItem.id,
          actorType: 'technician',
          actorId: technician.id,
        }),
      })
        .then(() => refetch && refetch())
        .catch(() => window.alert('There was an error creating a new workOrder.'))

    const editableHasDiff =
      editableLineItem.panel !== lineItem.panel ||
      editableLineItem.side !== lineItem.side ||
      editableLineItem.type !== lineItem.type ||
      editableLineItem.price !== lineItem.price

    const allowSave = editableLineItem && lineItemIsValid(editableLineItem)

    const lineItemHasSide = !!editableLineItem.side

    const possiblePanels = [...new Set(lineItems.slice().map(item => item.panel))].sort()
    const possibleSides = [
      ...new Set(lineItems.filter(item => item.panel === editableLineItem.panel).map(item => item.side)),
    ].sort()

    const possibleTypes = [
      ...new Set(
        lineItems
          .filter(
            item =>
              item.panel === editableLineItem.panel && (lineItemHasSide ? item.side === editableLineItem.side : true)
          )
          .map(item => item.type)
      ),
    ].sort()

    return (
      <Grid
        container
        sx={{
          borderRadius: '6px',
          border: '1px solid #e8ddd9',
          background: '#fffdf8',
          mb: '0.5em',
        }}
      >
        <Grid container xs={12} sx={{ margin: '4px 0' }}>
          <Grid item xs={6}>
            <FormControl fullWidth size='small' sx={{ background: '#fff' }}>
              <InputLabel>Panel</InputLabel>
              <Select label='Panel' value={editableLineItem.panel} onChange={e => handleSelectPanel(e.target.value)}>
                {possiblePanels.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {lineItemHasSide && (
            <Grid item xs={6}>
              <FormControl fullWidth size='small' sx={{ background: '#fff' }}>
                <InputLabel>Side</InputLabel>
                <Select label='side' value={editableLineItem.side} onChange={e => handleSelectSide(e.target.value)}>
                  {possibleSides.map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>

        <Grid container xs={12} sx={{ mb: '4px' }}>
          <Grid item xs={6}>
            <FormControl fullWidth size='small' sx={{ background: '#fff' }}>
              <InputLabel>Type</InputLabel>
              <Select label='Type' value={editableLineItem.type} onChange={e => handleSelectType(e.target.value)}>
                {possibleTypes.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {shouldEnableLineItemPrices ? `$${editableLineItem.price || 0}` : ''}&nbsp;
            <Button disabled={editableHasDiff} color='error' onClick={handleClickDelete}>
              [delete]
            </Button>
          </Grid>
        </Grid>

        <Collapse in={editableHasDiff} sx={{ width: '100%' }}>
          <Grid container>
            <Grid item xs={12} sx={{ p: '5px', display: 'flex' }}>
              <Button onClick={resetEditableLineItem} color='warning' size='small'>
                cancel/reset
              </Button>
              <Button
                disabled={!allowSave}
                onClick={handleClickSave}
                disableElevation
                variant='contained'
                size='small'
                sx={{ ml: 'auto' }}
              >
                Save changes
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    )
  }

  const ShowLineItem = ({ lineItem }) => {
    const hasSide = !!lineItem.side

    return (
      <Grid
        container
        style={{
          padding: '4px',
          borderBottom: '1px dashed lightsteelblue',
        }}
      >
        <Grid item xs={shouldEnableLineItemPrices ? 10 : 12} sx={{ p: '4px', textTransform: 'capitalize' }}>
          {lineItem.panel} {hasSide && ` - ${lineItem.side} Side`} - {lineItem.type}
        </Grid>

        <Grid item xs={2} sx={{ textAlign: 'right', p: '4px' }}>
          {shouldEnableLineItemPrices ? `$${lineItem.price}` : ''}
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            borderRadius: '6px 6px 0px 0px',
            fontWeight: 700,
            fontSize: '14px',
            padding: '.5rem 1rem',
            background: '#fff',
            border: '1px solid #ddd',
          }}
        >
          Line Items
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            background: '#fff',
            padding: '.5rem .5rem 1rem .5rem',
            borderRadius: '0px 0px 6px 6px',
            border: '1px solid #ddd',
            borderTop: '0px',
          }}
        >
          {!workOrder.completedAt && <NewLineItem />}

          <Grid
            container
            sx={{
              border: '1px solid #d8dBdd',
              mt: '.25rem',
              background: '#F8FBFF',
              borderRadius: '8px',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '.5rem .5rem',
                fontWeight: 600,
                fontSize: '14px',
              }}
            >
              Current line items
              <Grow in={!workOrder.payoutData && !workOrder.completedAt && workOrder?.lineItems?.length > 0}>
                <Button
                  color='primary'
                  onClick={() => setEditingLineItems(!editingLineItems)}
                  sx={{ ml: 'auto', padding: '.125rem' }}
                >
                  [ Edit{editingLineItems && 'ing'} ]
                </Button>
              </Grow>
            </Grid>

            <Grid item xs={12}>
              {workOrder.lineItems.map(item =>
                editingLineItems ? (
                  <EditLineItem key={item.id} lineItem={item} />
                ) : (
                  <ShowLineItem key={item.id} lineItem={item} />
                )
              )}
            </Grid>

            {shouldEnableLineItemPrices && (
              <>
                <Grid
                  item
                  xs={12}
                  sx={{
                    fontWeight: 600,
                    padding: '0.75rem .5rem',
                    textAlign: 'right',
                    ml: 'auto',
                  }}
                >
                  Grand total: <Dollars value={invoice.grandTotal || invoice.lineItemsSum} />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
