import { Grid, Select, MenuItem, FormControl, InputLabel, Input } from '@mui/material'
import { useQuery, gql } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import { bearerTokenHeaders } from 'tools'
import { UserContext } from 'UserStore'
import { TechnicianContext } from 'GlobalStore'
import { Button } from '@material-ui/core'

export const ReferenceNumbers = ({ workOrder, refetch }: { workOrder: any; refetch: any }) => {
  const [stock, setStock] = useState(workOrder.stock || '')
  const [repairOrder, setRepairOrder] = useState(workOrder.repairOrder || '')
  const [technician] = useContext(TechnicianContext)
  const [user] = useContext(UserContext)
  const [isDisabled, setIsDisabled] = useState(!!workOrder.completedAt)
  // const [selectsDisabled, setSelectsDisabled] = useState(!!workOrder.completedAt)

  const onClickSave = () => {
    fetch(`https://${process.env.REACT_APP_API_HOST}/update_b_to_b_work_order_attributes`, {
      method: 'POST',
      headers: bearerTokenHeaders(user.token),
      body: JSON.stringify({
        bToBWorkOrderId: workOrder.id,
        updatedAttributes: {
          stock,
          repairOrder,
          repair_order: repairOrder,
        },
        actorType: 'technician',
        actorId: technician.id,
      }),
    })
      .catch(() => window.alert('Error'))
      .then(() => refetch())
      .finally(() => setIsDisabled(false))
  }

  useEffect(() => {
    workOrder && setIsDisabled(!!workOrder.completedAt)
  }, [workOrder])

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={5} sx={{ pr: '.5rem' }}>
          <FormControl fullWidth size='small'>
            <InputLabel>Stock</InputLabel>
            <Input value={stock} placeholder='#####' onChange={e => setStock(e.target.value)} />
          </FormControl>
        </Grid>

        <Grid item xs={5} sx={{ pl: '.5rem' }}>
          <FormControl fullWidth size='small'>
            <InputLabel>Repair Order</InputLabel>
            <Input value={repairOrder} placeholder='####' onChange={e => setRepairOrder(e.target.value)} />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Button onClick={() => onClickSave()} variant='outlined'>
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ReferenceNumbers
