import Index from '../Payouts/Index'
import Payout from './Payout'
import { Switch, Route, } from 'react-router-dom'

const PayoutsRouter = () => {
  return <Switch>
    <Route path="/payouts/:payoutId">
      <Payout />
    </Route>
    <Route path="/payouts/">
      <Index />
    </Route>
  </Switch>
}

export default PayoutsRouter