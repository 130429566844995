import React, { useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@mui/material/Typography'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

import { Dollars } from 'tools'
import TinyTable from 'presentation/components/TinyTable'
import { TechnicianContext } from 'GlobalStore'

const useStyles = makeStyles(theme => ({
  laborInfoHeader: {
    position: 'sticky',
    zIndex: 2,
    top: 0,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    backgroundColor: '#98e7fb',
    padding: '0.5rem',
    borderRadius: '3px 3px 0 0',
    color: '#111',
    borderBottom: '1px solid #0d2c93',
    marginBottom: '.25rem',
  },
  highlightRow: {
    backgroundColor: '#eaeae8',
  },
}))

const Labor = ({ quote }) => {
  const classes = useStyles()
  const [technician] = useContext(TechnicianContext)
  const { isEmployee } = technician

  return (
    <>
      <Typography className={classes.laborInfoHeader}>
        <b>Labor Info</b>
      </Typography>
      <TableContainer style={{ backgroundColor: 'white' }}>
        <TinyTable>
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell>Detail</TableCell>
              <TableCell align='right'>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.highlightRow}>
              <TableCell colSpan={3}>
                <b>Labor</b>
              </TableCell>
            </TableRow>

            {quote.primaryLaborItems.length > 0 &&
              quote.primaryLaborItems.map(labor => (
                <TableRow key={labor.id}>
                  <TableCell colSpan={2}>{labor.name}</TableCell>
                  <TableCell />
                </TableRow>
              ))}

            {quote.otherLaborItems.length > 0 &&
              quote.otherLaborItems.map(labor => (
                <TableRow key={labor.id}>
                  <TableCell colSpan={2}>{labor.name}</TableCell>
                  <TableCell />
                </TableRow>
              ))}

            {Number(quote.laborSumAfterAllDiscounts) > 0 && (
              <TableRow>
                <TableCell> Labor total </TableCell>
                <TableCell />
                <TableCell align='right' className={classes.dollarAmount}>
                  <b>
                    <Dollars value={quote.laborSumAfterAllDiscounts} />
                  </b>
                </TableCell>
              </TableRow>
            )}

            {quote.parts.length > 0 && (
              <TableRow className={classes.highlightRow}>
                <TableCell className='has-text-centered' colSpan={3}>
                  <b>Parts</b>
                </TableCell>
              </TableRow>
            )}

            {quote.parts.map(part => (
              <TableRow key={part.id}>
                <TableCell>{part.name}</TableCell>
                <TableCell>part number: {part.number}</TableCell>
                <TableCell align='right' className={classes.dollarAmount}>
                  <Dollars value={part.price} />
                </TableCell>
              </TableRow>
            ))}

            {Number(quote.partsSumotal) > 0 && (
              <TableRow>
                <TableCell>Parts total</TableCell>
                <TableCell />
                <TableCell align='right' className={classes.dollarAmount}>
                  <b>
                    <Dollars value={quote.partsSumotal} />
                  </b>
                </TableCell>
              </TableRow>
            )}

            {quote.technicianChargesSum > 0 && (
              <TableRow className={classes.highlightRow}>
                <TableCell colSpan={3}>
                  <b> {isEmployee ? 'Additional Charges' : 'Technician Reimbursement (100% payout)'} </b>
                </TableCell>
              </TableRow>
            )}

            {quote.technicianCharges.length > 0 &&
              quote.technicianCharges.map(labor => (
                <TableRow key={labor.id}>
                  <TableCell colSpan={2}>{labor.name}</TableCell>
                  <TableCell align='right' className={classes.dollarAmount}>
                    <Dollars value={labor.price} />
                  </TableCell>
                </TableRow>
              ))}

            {Number(quote.technicianChargesSum) > 0 && (
              <TableRow>
                <TableCell> {isEmployee ? 'Additional Charges' : 'Reimbursement'} Total </TableCell>
                <TableCell />
                <TableCell align='right' className={classes.dollarAmount}>
                  <b>
                    <Dollars value={quote.technicianChargesSum} />
                  </b>
                </TableCell>
              </TableRow>
            )}

            {quote.carbodylabChargesSum > 0 && (
              <TableRow className={classes.highlightRow}>
                <TableCell colSpan={3}>
                  <b> Carbodylab Fees (No Commission) </b>
                </TableCell>
              </TableRow>
            )}

            {quote.carbodylabCharges.length > 0 &&
              quote.carbodylabCharges.map(labor => (
                <TableRow key={labor.id}>
                  <TableCell colSpan={2}>{labor.name}</TableCell>
                  <TableCell align='right' className={classes.dollarAmount}>
                    <Dollars value={labor.price} />
                  </TableCell>
                </TableRow>
              ))}

            {Number(quote.carbodylabChargesSum) > 0 && (
              <TableRow>
                <TableCell> Carbodylab Fees </TableCell>
                <TableCell />
                <TableCell align='right' className={classes.dollarAmount}>
                  <b>
                    <Dollars value={quote.carbodylabChargesSum} />
                  </b>
                </TableCell>
              </TableRow>
            )}

            {quote.postTaxGrandTotal > 0 && (
              <TableRow className={classes.highlightRow}>
                <TableCell colSpan={3}>
                  <b>Totals</b>
                </TableCell>
              </TableRow>
            )}

            {Number(quote.laborSumAfterAllDiscounts) > 0 && (
              <TableRow>
                <TableCell>Labor</TableCell>
                <TableCell />
                <TableCell align='right' className={classes.dollarAmount}>
                  <b>
                    <Dollars value={quote.laborSumAfterAllDiscounts} />
                  </b>
                </TableCell>
              </TableRow>
            )}

            {quote.parts.length > 0 && (
              <TableRow>
                <TableCell>Parts</TableCell>
                <TableCell />
                <TableCell align='right' className={classes.dollarAmount}>
                  <b>
                    <Dollars value={quote.partsSumotal} />
                  </b>
                </TableCell>
              </TableRow>
            )}

            {quote.carbodylabCharges > 0 && (
              <TableRow>
                <TableCell>Carbodylab Charges</TableCell>
                <TableCell />
                <TableCell align='right' className={classes.dollarAmount}>
                  <b>
                    <Dollars value={quote.carbodylabChargesSum} />
                  </b>
                </TableCell>
              </TableRow>
            )}

            {quote.technicianCharges.length > 0 && (
              <TableRow>
                <TableCell>{isEmployee ? 'Additional Charges' : 'Technician Reimbursment'}</TableCell>
                <TableCell />
                <TableCell align='right' className={classes.dollarAmount}>
                  <b>
                    <Dollars value={quote.technicianChargesSum} />
                  </b>
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell colSpan={2}>
                <>Sales Tax - </>
                <strong>{(quote.taxRate * 100).toFixed(2)}%</strong>
                <> of taxable amount of </>
                <strong>
                  <Dollars value={quote.taxableAmount} />
                </strong>
              </TableCell>
              <TableCell align='right' className={classes.dollarAmount}>
                <strong>
                  <Dollars value={quote.taxTotal} />
                </strong>
              </TableCell>
            </TableRow>

            <TableRow style={{ borderTop: '2px solid gray' }}>
              <TableCell>Grand total</TableCell>
              <TableCell />
              <TableCell align='right' className={classes.dollarAmount}>
                <b>
                  <Dollars value={quote.postTaxGrandTotal} />
                </b>
              </TableCell>
            </TableRow>
          </TableBody>
        </TinyTable>
      </TableContainer>
    </>
  )
}

export default Labor
