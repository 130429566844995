import React, { useState, useContext } from 'react'
import { DateTime } from 'luxon-business-days'
import { UserContext } from 'UserStore'
import {
  Drawer,
  Typography,
  Paper,
  Grid,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMore from '@mui/icons-material/ExpandMore'
import MapIcon from '@mui/icons-material/Map'
import PhoneIcon from '@mui/icons-material/Phone'

import Address from 'presentation/components/Address'
import Map from './Map'
import { Dollars, nowAsIfInUtc, formatFullDate, onlyUniqueFilter } from 'tools'

const ConfirmDialog = ({ isOpen, handleClose, handleConfirm }) => {
  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle id='alert-dialog-title'>
          <div style={{ color: 'red' }}>This part was removed from the quote & is no longer part of this job.</div>
          <div>Are you sure you want to pick it up?</div>
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus>
            Pickup Anyway
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const Part = ({ part, isOldJob, style, classes }) => {
  const [user] = useContext(UserContext)
  const [waiting, setWaiting] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const headers = () => ({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${user.token}`,
  })

  const processChangedPickedUp = ({ part, value }) => {
    setWaiting(true)

    // the value needs to be either 'picked_up' || 'not_picked_up'
    fetch(`https://${process.env.REACT_APP_API_HOST}/technician_mark_part_as_${value}`, {
      method: 'POST',
      headers: headers(),
      body: JSON.stringify({
        technician_id: user.id,
        part_id: part.id,
      }),
    })
      .then(res => res.ok || window.alert('Error'))
      .finally(() => {
        setWaiting(false)
      })
  }

  const handleChangePickedUp = ({ part, value }) => {
    if (waiting || part.pickupStatus === value) {
      return
    }

    const confirm = () => processChangedPickedUp({ part: part, value: value })

    if (part.removedFromQuoteAt && value === 'picked_up') {
      console.log('open confirm dialog...')
      setConfirmDialogOpen(true)

      return
    }

    confirm()
  }

  const handleInitiateReturn = ({ part, value }) => {
    if (waiting || part.returnCompletedAt) {
      return
    }

    setWaiting(true)

    fetch(`https://${process.env.REACT_APP_API_HOST}/toggle_part_return_initiated`, {
      method: 'POST',
      headers: headers(),
      body: JSON.stringify({
        user_id: user.id,
        part_id: part.id,
        return_initiated: value,
      }),
    })
      .then(res => res.ok || window.alert('Error'))
      .finally(() => {
        setWaiting(false)
      })
  }

  const handleInitiateCoreRefund = ({ part, value }) => {
    if (waiting || part.coreRefundInitiatedAt) {
      return
    }

    setWaiting(true)

    fetch(`https://${process.env.REACT_APP_API_HOST}/toggle_part_core_refund_initiated`, {
      method: 'POST',
      headers: headers(),
      body: JSON.stringify({
        user_id: user.id,
        part_id: part.id,
        core_refund_initiated: value,
      }),
    })
      .then(res => res.ok || window.alert('Error'))
      .finally(() => {
        setWaiting(false)
      })
  }

  const handleTogglePartReturned = ({ part, value }) => {
    if (waiting || !part.returnInitiatedAt) {
      return
    }

    setWaiting(true)

    fetch(`https://${process.env.REACT_APP_API_HOST}/toggle_part_returned_to_vendor`, {
      method: 'POST',
      headers: headers(),
      body: JSON.stringify({
        user_id: user.id,
        part_id: part.id,
        returned: value,
      }),
    })
      .then(res => res.ok || window.alert('Error'))
      .finally(() => {
        setWaiting(false)
      })
  }

  const handleToggleCoreReturned = ({ part, value }) => {
    if (waiting || !part.coreRefundInitiatedAt) {
      return
    }

    setWaiting(true)

    fetch(`https://${process.env.REACT_APP_API_HOST}/toggle_part_core_returned_to_vendor`, {
      method: 'POST',
      headers: headers(),
      body: JSON.stringify({
        user_id: user.id,
        part_id: part.id,
        returned: value,
      }),
    })
      .then(res => res.ok || window.alert('Error'))
      .finally(() => {
        setWaiting(false)
      })
  }

  const partStyle = part => {
    if (part.removedFromQuoteAt) {
      return classes.removed
    }
    if (part.returnCompletedAt || part.returnedToVendorAt || part.coreReturnedToVendorAt) {
      return classes.returned
    }
    if (
      (part.returnInitiatedAt || part.coreRefundInitiatedAt) &&
      !part.returnedToVendorAt &&
      !part.coreReturnedToVendorAt
    ) {
      return classes.needsToBeReturned
    }
    if (part.readyForPickupAt) {
      return classes.readyForPickup
    }
    return part.orderPlacedAt && !part.readyForPickupAt ? classes.waitingToArrive : classes.waitingToBeOrdered
  }

  const partExpectedArrivalDate = part => {
    if (!part.orderPlacedAt || part.etaBusinessDays === null || part.etaBusinessDays === undefined) {
      return 'unknown'
    }
    return DateTime.fromISO(part.orderPlacedAt).plusBusiness({ days: part.etaBusinessDays }).toFormat('MMM d, y')
  }

  const paymentTextColor = part.isPaid ? 'darkgreen' : 'red'

  return (
    <>
      <Grid key={part.id} container spacing={0} sx={{ mb: 2 }} className={partStyle(part)} style={style}>
        {waiting && (
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            style={{ position: 'absolute', top: '10px', right: '10px' }}
          >
            <CircularProgress size={20} />
          </Box>
        )}
        <Box sx={{ px: 1 }}>
          {part.removedFromQuoteAt &&
            !part.returnedToVendorAt && ( //! isOldJob &&
              <div>
                <b style={{ color: 'red' }}>Removed from Quote</b>
              </div>
            )}
          <Grid item xs={12}>
            <b>
              {part.name} - {part.number}
            </b>
          </Grid>
          {!part.readyForPickupAt && part.etaBusinessDays !== null && part.etaBusinessDays !== undefined && (
            <div>
              Expected arrival: <b>{partExpectedArrivalDate(part)}</b>
            </div>
          )}
          {part.returnInitiatedAt && (
            <div>
              Return initiated: <b>{formatFullDate(part.returnInitiatedAt)}</b>
            </div>
          )}
          {part.returnedToVendorAt && (
            <div>
              Part returned: <b>{formatFullDate(part.returnedToVendorAt)}</b>
            </div>
          )}
          {part.coreRefundInitiatedAt && (
            <div>
              Core return initiated: <b>{formatFullDate(part.coreRefundInitiatedAt)}</b>
            </div>
          )}
          {part.coreReturnedToVendorAt && (
            <div>
              Core returned: <b>{formatFullDate(part.coreReturnedToVendorAt)}</b>
            </div>
          )}
          {part.coreRefundExpectedAmount > 0 && !part.coreReturnedToVendorAt && (
            <div>
              <b>
                <span>
                  Core Charge: <Dollars value={part.coreRefundExpectedAmount} />
                </span>
              </b>
            </div>
          )}
          {part.readyForPickupAt && !isOldJob && (
            <div>
              <b>
                <span style={{ color: paymentTextColor }}>{part.isPaid ? 'PAID' : 'NOT PAID'}</span>
              </b>
              {!part.isPaid && (
                <span>
                  &nbsp;&nbsp;
                  <Dollars value={part.cost} />
                </span>
              )}
            </div>
          )}
        </Box>

        {!part.returnInitiatedAt && !part.coreRefundInitiatedAt && part.pickupStatus === 'picked_up' && (
          <Grid
            item
            xs={12}
            sx={{ mt: 1 }}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
          >
            {!part.returnInitiatedAt && (
              <Button
                cy-data='initiate-return-button'
                size='small'
                variant='contained'
                color='secondary'
                sx={{ py: 0, px: 1, mx: 1 }}
                onClick={() => {
                  handleInitiateReturn({ part: part, value: true })
                }}
              >
                Initiate Return
              </Button>
            )}
            {(part.hasCoreRefund || part.coreRefundExpectedAmount > 0) && (
              <Button
                size='small'
                variant='contained'
                color='secondary'
                sx={{ py: 0, px: 1, mx: 1 }}
                onClick={() => {
                  handleInitiateCoreRefund({ part: part, value: true })
                }}
              >
                Initiate Core Refund
              </Button>
            )}
          </Grid>
        )}

        {part.returnInitiatedAt && (
          <Grid
            item
            xs={12}
            sx={{ mt: 1 }}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
          >
            {!part.returnedToVendorAt && (
              <Button
                cy-data='mark-part-returned-button'
                size='small'
                variant='contained'
                color='primary'
                sx={{ py: 0, px: 1, mx: 1 }}
                onClick={() => {
                  handleTogglePartReturned({ part: part, value: true })
                }}
              >
                Mark Part Returned
              </Button>
            )}
            {part.returnedToVendorAt && !part.returnCompletedAt && (
              <Button
                size='small'
                variant='contained'
                style={{ backgroundColor: 'grey' }}
                sx={{ py: 0, px: 1, mx: 1 }}
                onClick={() => {
                  handleTogglePartReturned({ part: part, value: false })
                }}
              >
                Unmark Part Returned
              </Button>
            )}
          </Grid>
        )}

        {part.coreRefundInitiatedAt && (
          <Grid item xs={12} sx={{ mt: 1 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!part.coreReturnedToVendorAt && (
              <Button
                size='small'
                variant='contained'
                color='primary'
                sx={{ py: 0, px: 1, mx: 1 }}
                onClick={() => {
                  handleToggleCoreReturned({ part: part, value: true })
                }}
              >
                Mark Core Returned
              </Button>
            )}
            {part.coreReturnedToVendorAt && !part.coreRefundCompletedAt && (
              <Button
                size='small'
                variant='contained'
                style={{ backgroundColor: 'grey' }}
                sx={{ py: 0, px: 1, mx: 1 }}
                onClick={() => {
                  handleToggleCoreReturned({ part: part, value: false })
                }}
              >
                Unmark Core Returned
              </Button>
            )}
          </Grid>
        )}

        {((!isOldJob && part.readyForPickupAt) || part.pickupHistory.length === 0) && !part.returnedToVendorAt && (
          <Grid
            item
            xs={12}
            className={classes.flexCenter}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '.25rem 0rem',
              justifyContent: 'center',
            }}
          >
            Select one:
            <FormControl component='fieldset'>
              <RadioGroup
                row
                value={part.pickupStatus}
                onChange={event => handleChangePickedUp({ part: part, value: event.target.value })}
              >
                <FormControlLabel
                  value='picked_up'
                  name='part-picked-up-radio'
                  control={<Radio size='small' color='primary' style={{ padding: 0 }} />}
                  label='Picked up'
                  labelPlacement='bottom'
                  disabled={waiting || ["quoted", "eta_received"].includes(part?.status)}
                />
                <FormControlLabel
                  value='not_picked_up'
                  name='part-not-picked-up-radio'
                  control={<Radio size='small' color='primary' style={{ padding: 0 }} />}
                  label='Not picked up'
                  labelPlacement='bottom'
                  disabled={waiting || ["quoted", "eta_received"].includes(part?.status)}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <ConfirmDialog
        isOpen={confirmDialogOpen}
        handleClose={() => setConfirmDialogOpen(false)}
        handleConfirm={() => {
          processChangedPickedUp({ part: part, value: 'picked_up' })
          setConfirmDialogOpen(false)
        }}
      />
    </>
  )
}

const VendorCard = ({ data, job, classes }) => {
  const nowInUtc = nowAsIfInUtc()
  const isOldJob = DateTime.fromISO(job.startDatetime) < nowInUtc
  const [expanded, setExpanded] = useState(false)
  const [mapLoaded, setMapLoaded] = useState(false)

  const handleShowMap = () => {
    if (!expanded) {
      setMapLoaded(true)
    }
    setExpanded(!expanded)
  }

  const vendorContact = data.parts.reduce((acc, part) => {
    if (part.partVendorContact && part.partVendorContact !== '') {
      acc = part.partVendorContact
    }
    return acc
  }, '')

  const unpaidPartsTotal = data.parts.reduce((sum, part) => {
    if (!part.isPaid) {
      sum += part.cost
    }
    return sum
  }, 0)

  return (
    <Paper variant='outlined' style={{ width: '100%', marginBottom: '16px' }}>
      <Box sx={{ pt: 1, pb: 0, px: 1 }}>
        <Grid container>
          <Grid item xs={7}>
            <b>{data.vendor?.name || 'Waiting To Be Ordered'}</b>
            <Typography variant='caption'>
              {data.vendor && <Address addr={data.vendor} />}
              {vendorContact !== '' && (
                <div>
                  <b>Contact:</b> {vendorContact}
                </div>
              )}
              {/* {unpaidPartsTotal > 0 && data.vendor &&
                <div><b>Payment options:</b> {data.vendor.paymentNotes}</div>} */}
            </Typography>
          </Grid>
          <Grid item xs={5} style={{ display: 'flex', flexDirection: 'column' }}>
            <Box style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
              {data.vendor && (
                <a href={'tel:' + data.vendor.phone}>
                  <IconButton>
                    <PhoneIcon />
                  </IconButton>
                </a>
              )}
            </Box>
            <Box>
              <Typography variant='caption'>
                {data.vendor && (
                  <div style={{ marginTop: '-16px' }}>
                    <b>Invoice #:</b> <br />
                    {data.parts
                      .map(o => o.invoiceNumber)
                      .filter(onlyUniqueFilter)
                      .join(', ')}
                  </div>
                )}
                {!isOldJob && unpaidPartsTotal > 0 && data.vendor && (
                  <div>
                    <b>Payment options:</b> <br />
                    {data.vendor.paymentNotes} <br />
                    <span style={{ color: 'red' }}>
                      <b>Total due:</b> <Dollars value={unpaidPartsTotal} />
                    </span>
                  </div>
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {data.vendor && (
          <Accordion
            elevation={1}
            expanded={expanded}
            onChange={handleShowMap}
            style={{
              border: '1px solid',
              marginTop: '.5rem',
              boxShadow: 'none',
              borderRadius: '4px',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{ px: 1, py: 0, m: 0 }}
              style={{
                minHeight: '40px',
                height: '40px',
              }}
            >
              <MapIcon />
              <div style={{ marginLeft: '8px', fontWeight: '600' }}>show map</div>
            </AccordionSummary>
            <AccordionDetails
              style={{
                padding: '0',
                borderTop: '1px solid #eee',
                color: '#3a3a3a',
              }}
            >
              {(expanded || mapLoaded) && (
                <>
                  <Map showOpenInMapsLink address={data.vendor} />
                </>
              )}
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
      <Box sx={{ p: 0, m: 1 }}>
        <Grid container style={{ margin: '0', width: '100%' }} spacing={1}>
          {data.parts.map((part, index) => (
            <Part
              key={part.id}
              part={part}
              isOldJob={isOldJob}
              classes={classes}
              style={{
                position: 'relative',
                border: '1px solid',
                borderRadius: '4px',
                padding: '.5rem 0',
                marginBottom: index < data.parts.length - 1 ? '8px' : '0',
              }}
            />
          ))}
        </Grid>
      </Box>
    </Paper>
  )
}

const PartsDrawer = ({ drawerIsOpen, setDrawerIsOpen, parts, job, classes }) => {
  const data = {}
  parts.reduce((acc, part) => {
    if (!acc[part.partVendorId]) {
      data[part.partVendorId] = {
        vendor: part.vendor,
        parts: [part],
      }
    } else {
      acc[part.partVendorId].parts.push(part)
    }

    return acc
  }, data)

  const numPartsReadyForPickup = parts => {
    let count = 0
    parts.forEach(part => {
      if (part.readyForPickupAt) {
        count++
      }
    })
    return count
  }
  const sortedVendorGroups = Object.keys(data)
    .map(key => data[key])
    .sort((a, b) => {
      if (a.vendor && !b.vendor) {
        return -1
      } else if (a.vendor && b.vendor) {
        const aAllPartsReady = numPartsReadyForPickup(a.parts) === a.parts.length
        const bAllPartsReady = numPartsReadyForPickup(b.parts) === b.parts.length

        if (aAllPartsReady && bAllPartsReady) {
          return 0
        }

        if (aAllPartsReady && !bAllPartsReady) {
          return -1
        }

        return 1
      }
      return 1
    })

  return (
    <Drawer
      anchor='right'
      PaperProps={{
        style: { width: '100%' },
        onClick: e => {
          e.stopPropagation()
        },
      }}
      open={drawerIsOpen}
    >
      <Box
        className={classes.partsSectionHeading}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0.5rem 1rem',
          position: 'fixed',
          top: '0',
          width: '100%',
          zIndex: '1',
        }}
      >
        <Typography variant='h6'>Parts</Typography>
        <IconButton aria-label='Close' onClick={() => setDrawerIsOpen(false)}>
          <CloseIcon fontSize='medium' />
        </IconButton>
      </Box>
      <Grid
        container
        spacing={2}
        style={{
          padding: '.5rem',
          margin: '0',
          width: '100%',
          marginTop: '57px',
        }}
      >
        {sortedVendorGroups.map((data, index) => (
          <VendorCard key={index} data={data} job={job} classes={classes} />
        ))}
      </Grid>
    </Drawer>
  )
}

export default PartsDrawer
