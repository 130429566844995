import React from 'react'
import NumberFormat from 'react-number-format'

const Address = ({ addr }) => {
  return (
    <>
      <div>{addr.addressLineOne}</div>
      {addr.addressLineTwo ? <div>{addr.addressLineTwo}</div> : null}
      <div>{addr.city}, {addr.state} {addr.zip}</div>
      {addr.phone &&
        <div>
          <a href={'tel:' + addr.phone}>
            <NumberFormat value={addr.phone} displayType='text' format='(###) ###-####' />
          </a>
        </div>}
    </>
  )
}

export default Address
