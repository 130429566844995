import { TechnicianContext } from 'GlobalStore'
import { useEffect, useContext, useState } from 'react'
import {
  MenuItem,
  Button,
  Box,
  Collapse,
  Grid,
  Typography,
  Paper,
  Dialog,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  Fab,
  Drawer,
  InputAdornment,
  TextField,
} from '@mui/material'
import { Add, Lock, CheckCircle, KeyboardArrowRight, HardwareRounded, SearchRounded } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { DateTime } from 'luxon'
import { UserContext } from 'UserStore'
import { bearerTokenHeaders } from 'tools'

const INVOICES_QUERY = gql`
  query bToBInvoices($technicianId: ID!, $hasPayoutId: Boolean, $isDeleted: Boolean, $secondaryTechnicianId: ID) {
    bToBInvoices(
      technicianId: $technicianId
      hasPayoutId: $hasPayoutId
      isDeleted: $isDeleted
      secondaryTechnicianId: $secondaryTechnicianId
    ) {
      id
      createdAt
      businessName
      lineItems
      lineItemsSum
      grandTotal
      discountPercent
      discountPercentTotal
      preJobPhotos
      postJobPhotos
      year
      make
      model
      payoutData
      completedAt
      deletedAt
      invoiceVersion
      label
      vin
      dealer {
        id
        businessName
      }
      workOrder {
        id
      }
    }
  }
`

const InvoicesIndex = () => {
  const [technician] = useContext(TechnicianContext)
  const [user] = useContext(UserContext)
  const [showCreatingSpinner, setShowCreatingSpinner] = useState(false)
  const [selectDealerOpen, setSelectDealerOpen] = useState(false)
  const [selectedDealerId, setSelectedDealerId] = useState('')

  const { loading, error, data, refetch } = useQuery(INVOICES_QUERY, {
    variables: {
      technicianId: technician.id,
      hasPayoutId: false,
      isDeleted: false,
      secondaryTechnicianId: technician.id,
    },
  })

  const createInvoice = () => {
    setShowCreatingSpinner(true)
    fetch(`https://${process.env.REACT_APP_API_HOST}/create_v2_b_to_b_invoice`, {
      method: 'POST',
      headers: bearerTokenHeaders(user.token),
      body: JSON.stringify({
        technician_id: technician.id,
        b_to_b_dealer_id: selectedDealerId,
      }),
    }).then(res => res.ok || window.alert('Error'))
  }

  useEffect(() => {
    data && refetch && refetch()
  }, [])

  if (loading) return <> loading... </>
  if (error) return <> loading... </>

  const invoices = data.bToBInvoices

  const sortedInvoices = [...invoices].sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
  const completedInvoices = sortedInvoices.filter(invoice => invoice.completedAt)
  const notYetCompletedInvoices = sortedInvoices.filter(invoice => !invoice.completedAt)

  return (
    <Box>
      <Dialog
        onClose={() => setSelectDealerOpen(false)}
        open={selectDealerOpen}
        PaperProps={{ sx: { minWidth: '200px', padding: '1rem', textAlign: 'center' } }}
      >
        <b>Select a Dealer</b>
        <FormControl size='small' fullWidth sx={{ margin: '1rem auto' }}>
          <InputLabel>Selected Dealer</InputLabel>
          <Select
            label='Selected Dealer'
            fullWidth
            size='small'
            value={selectedDealerId}
            onChange={e => setSelectedDealerId(e.target.value)}
          >
            {technician.bToBDealers.map(item => (
              <MenuItem value={item.id}>{item.businessName}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          disabled={showCreatingSpinner || selectedDealerId === ''}
          variant='contained'
          size='small'
          onClick={createInvoice}
        >
          New Invoice
        </Button>
      </Dialog>
      <Grid container>
        <Fab
          color='primary'
          disabled={showCreatingSpinner}
          onClick={() => setSelectDealerOpen(true)}
          fullWidth
          variant='contained'
          sx={{
            position: 'fixed',
            bottom: '1rem',
            right: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {showCreatingSpinner ? (
            <>
              <CircularProgress sx={{ maxWidth: '30px', maxHeight: '30px' }} />
            </>
          ) : (
            <>
              <Add />
            </>
          )}
        </Fab>
      </Grid>

      <InvoiceSearchDrawer invoices={data.bToBInvoices} />

      <Typography
        sx={{
          textAlign: 'center',
          padding: '1rem',
          textTransform: 'uppercase',
          color: '#777',
          fontWeight: 600,
          fontSize: '.8rem',
        }}
      >
        In Progress
      </Typography>

      {notYetCompletedInvoices.map(invoice => (
        <InvoiceCard invoice={invoice} key={invoice.id} />
      ))}

      {data.bToBInvoices.find(invoice => invoice.completedAt) && (
        <>
          <Box
            sx={{
              height: '2px',
              width: '100%',
              background: '#ddd',
              mb: '1rem',
              mt: '2.5rem',
            }}
          />
          <Typography
            sx={{
              textAlign: 'center',
              padding: '1rem',
              textTransform: 'uppercase',
              color: '#777',
              fontWeight: 600,
              fontSize: '.8rem',
            }}
          >
            Recently Completed
          </Typography>
        </>
      )}

      {completedInvoices.map(invoice => (
        <InvoiceCard invoice={invoice} key={invoice.id} />
      ))}
    </Box>
  )
}

const InvoiceCard = ({ invoice }) => {
  const [technician] = useContext(TechnicianContext)
  const history = useHistory()
  const jasonId = '75267851-2fc0-4fed-8654-8709114e58fc'
  const andyId = '862025e4-9b37-4736-a4c1-ded102a5ec93'
  const shouldEnableLineItemPrices = technician.id === jasonId || technician.id === andyId

  return (
    <Grid item xs={12}>
      <Paper
        elevation={0}
        onClick={() => history.push(`/b-to-b-invoices/${invoice.id}`)}
        sx={{
          padding: '.5rem 1rem',
          marginBottom: '1rem',
          borderRadius: '8px',
          color: '#555',
          boxShadow: invoice?.completedAt ? '0px 1px 2px #3fb953' : '0px 2px 3px rgba(0, 0, 0, 0.15)',
          border: invoice?.completedAt ? '2px solid #3fb953' : '1px solid #ddd',
          '& p': {
            display: 'block',
            fontFamily: 'Open Sans',
            color: '#3E3745',
            fontWeight: 600,
            paddingBottom: '0px',
            margin: '0px',
          },
          '& span': {
            fontFamily: 'Open Sans',
            color: '#3E3745',
          },
          '&:hover': {
            background: '#f1f1f1',
            cursor: 'pointer',
          },
        }}
      >
        <Grid container>
          <Grid item xs={11}>
            {invoice.invoiceVersion === 2.0 && <b style={{ fontSize: '12px' }}>{invoice.dealer.businessName}</b>}

            <Typography variant='body1' sx={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
              {DateTime.fromISO(invoice.createdAt).toFormat('DD')}
              <span style={{ opacity: 0.7, textTransform: 'capitalize', fontSize: '12px' }}>
                &nbsp; ({invoice.year} &#8212; {invoice.make && invoice.make.replace('_', ' ')} &#8212;{' '}
                {invoice.model && invoice.model.replace('_', ' ')})
              </span>
            </Typography>
            {shouldEnableLineItemPrices && (
              <Typography variant='h6' sx={{ display: 'block', color: '#30A92E', fontSize: '22px' }}>
                ${invoice.grandTotal || invoice.lineItemsSum}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <KeyboardArrowRight sx={{ ml: 'auto', mt: 'auto', mb: 'auto' }} />
          </Grid>
          <Collapse sx={{ width: '100%' }} in={invoice.completedAt}>
            <Grid
              container
              sx={{
                background: '#3fb953bf',
                mb: '-.5rem',
                ml: '-1rem',
                borderRadius: '0px 0px 6px 6px',
                width: 'calc(100% + 2rem)',
                padding: '.25rem 1rem',
                borderTop: '2px solid #3fb953',
                mt: '.35rem',
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: 600,
                  color: '#222',
                }}
              >
                <CheckCircle sx={{ mr: '1rem', color: '#222', fontSize: '18px' }} />
                Completed
                {invoice.payoutData && (
                  <Box
                    sx={{
                      ml: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Lock
                      sx={{
                        mr: '.5rem',
                        fontSize: '18px',
                        color: '#222',
                      }}
                    />
                    Locked
                  </Box>
                )}
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Paper>
    </Grid>
  )
}

const InvoiceSearchDrawer = ({ invoices }) => {
  const [acceptedDrawerOpen, setAcceptedDrawerOpen] = useState(false)
  const [keywordSearch, setKeywordSearch] = useState('')

  const searchedInvoices = () => {
    const filteredInvoices = invoices.filter(invoice => {
      return (
        (invoice.businessName && invoice.businessName.toLowerCase().includes(keywordSearch.toLowerCase())) ||
        (invoice.vin && invoice.vin.toLowerCase().includes(keywordSearch.toLowerCase())) ||
        (invoice.make && invoice.make.toLowerCase().includes(keywordSearch.toLowerCase())) ||
        (invoice.model && invoice.model.toLowerCase().includes(keywordSearch.toLowerCase())) ||
        (invoice.label && invoice.label.toLowerCase().includes(keywordSearch.toLowerCase())) ||
        (invoice.year && invoice.year.toString().includes(keywordSearch))
      )
    })
    return filteredInvoices
  }

  return (
    <>
      <TextField
        size='small'
        fullWidth
        onClick={() => setAcceptedDrawerOpen(true)}
        InputProps={{
          type: 'price',
          startAdornment: (
            <InputAdornment position='start'>
              <SearchRounded fontSize='small' color='action' />
            </InputAdornment>
          ),
        }}
        placeholder='search VIN, business, make, model, year'
      />
      <Drawer
        PaperProps={{ sx: { maxHeight: '80%', minHeight: '80%' } }}
        anchor='bottom'
        open={acceptedDrawerOpen}
        onClose={() => setAcceptedDrawerOpen(false)}
      >
        <Box
          sx={{
            position: 'sticky',
            top: '0px',
            width: '100%',
            background: '#fff',
            padding: '1rem 1rem .5rem 1rem',
            zIndex: 1,
            borderBottom: '1px solid #ddd',
            mb: '1rem',
            boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.25)',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              pb: '1rem',
              textTransform: 'uppercase',
              color: '#777',
              fontWeight: 600,
              fontSize: '.8rem',
            }}
          >
            ALL INVOICES
          </Typography>
          <TextField
            value={keywordSearch}
            onChange={e => setKeywordSearch(e.target.value)}
            fullWidth
            InputProps={{
              type: 'price',
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchRounded fontSize='small' color='action' />
                </InputAdornment>
              ),
            }}
            sx={{ mb: '1rem' }}
            size='small'
            placeholder='search VIN, business, make, model, year'
          />
        </Box>
        <Box sx={{ padding: '0rem 1rem' }}>
          {searchedInvoices().map(invoice => (
            <AcceptedInvoiceCard invoice={invoice} key={invoice.id} />
          ))}
        </Box>
      </Drawer>
    </>
  )
}

export const AcceptedInvoiceCard = ({ invoice }) => {
  const [technician] = useContext(TechnicianContext)
  const history = useHistory()
  const jasonId = '75267851-2fc0-4fed-8654-8709114e58fc'
  const andyId = '862025e4-9b37-4736-a4c1-ded102a5ec93'
  const shouldEnableLineItemPrices = technician.id === jasonId || technician.id === andyId

  return (
    <Grid item xs={12}>
      <Paper
        elevation={0}
        onClick={() => history.push(`/b-to-b-invoices/${invoice.id}`)}
        sx={{
          padding: '.5rem 1rem',
          marginBottom: '1rem',
          borderRadius: '8px',
          color: '#555',
          boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.15)',
          border: '1px solid #ddd',
          '& p': {
            display: 'block',
            fontFamily: 'Open Sans',
            color: '#3E3745',
            fontWeight: 600,
            paddingBottom: '0px',
            margin: '0px',
          },
          '& span': {
            fontFamily: 'Open Sans',
            color: '#3E3745',
          },
          '&:hover': {
            background: '#f1f1f1',
            cursor: 'pointer',
          },
        }}
      >
        <Grid container>
          <Grid item xs={11}>
            <Box sx={{ display: 'flex', alignItems: 'center', '& svg': { color: '#3fb953bf' } }}>
              {invoice?.completedAt && <CheckCircle sx={{ mr: '.5rem', color: '#222', fontSize: '18px' }} />}
              <b style={{ fontSize: '12px' }}>{invoice.dealer.businessName}</b>
            </Box>
            <Typography variant='body1' sx={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
              {DateTime.fromISO(invoice.createdAt).toFormat('DD')}
            </Typography>
            <b
              style={{
                opacity: 0.7,
                textTransform: 'capitalize',
                fontSize: '12px',
                display: 'block',
                textAlign: 'left',
              }}
            >
              {invoice.year} &#8212; {invoice.make && invoice.make.replace('_', ' ')} &#8212;{' '}
              {invoice.model && invoice.model.replace('_', ' ')}
            </b>
            {shouldEnableLineItemPrices && (
              <Typography variant='h6' sx={{ display: 'block', color: '#30A92E', fontSize: '22px' }}>
                ${invoice.grandTotal || invoice.lineItemsSum}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <KeyboardArrowRight sx={{ ml: 'auto', mt: 'auto', mb: 'auto' }} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default InvoicesIndex
