import {
  FormControlLabel,
  Checkbox,
  Modal,
  Typography,
  Container,
  Grid,
  Grow,
  Collapse,
  Select,
  MenuItem,
  FormControl,
  Drawer,
  InputLabel,
  Button,
  Box,
} from '@mui/material'
import { KeyboardArrowDown, Cancel } from '@mui/icons-material'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import IconButton from '@mui/material/IconButton'
import { useQuery, gql } from '@apollo/client'
import { useEffect, useState, useContext, createContext } from 'react'
import { TechnicianContext } from 'GlobalStore'
import {
  bearerTokenHeaders,
  Dollars,
  getUploadParamsForDropzone,
  thumbnailUrlForPhoto,
  fullSizeUrlForPhoto,
} from 'tools'
import { DateTime } from 'luxon'
import Dropzone from 'react-dropzone-uploader'
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'
import { Chat } from './Chat'
import { useHistory } from 'react-router-dom'
import { UserContext } from 'UserStore'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '700px',
  maxHeight: '80%',
  width: '1100px',
  maxWidth: '100%',
  boxShadow: 24,
  border: '1px solid black',
}

const VEHICLE_YEARS = gql`
  query vehicleData {
    allVehicleYears
  }
`

const VEHICLE_MAKES = gql`
  query vehicleData($year: Int, $distinctBy: String) {
    vehicles(year: $year, distinctBy: $distinctBy) {
      makeShortName
      makePrettyName
    }
  }
`

const VEHICLE_MODELS = gql`
  query vehicleData($year: Int, $makeShortName: String, $distinctBy: String) {
    vehicles(year: $year, makeShortName: $makeShortName, distinctBy: $distinctBy) {
      modelShortName
      modelPrettyName
    }
  }
`

const Invoice = ({ invoice }) => {
  const history = useHistory()
  const [user] = useContext(UserContext)
  const [technician] = useContext(TechnicianContext)
  const [toggleCompleteCheckboxDisabled, setToggleCompleteCheckboxDisabled] = useState(false)
  const [lineItemOptions, setLineItemOptions] = useState([])

  const jasonId = '75267851-2fc0-4fed-8654-8709114e58fc'
  const shouldEnableLineItemPrices = technician.id === jasonId

  useEffect(() => {
    if (invoice.businessName === 'drivetime') {
      fetch(`https://driveway-production.s3-accelerate.amazonaws.com/json/${invoice.businessName}.json`)
        .then(res => res.json())
        .then(data => setLineItemOptions(data.sort((a, b) => (a.type > b.type ? 1 : -1))))
    } else {
      fetch(`https://driveway-production.s3-accelerate.amazonaws.com/json/drivetime.json`)
        .then(res => res.json())
        .then(data => setLineItemOptions(data.sort((a, b) => (a.type > b.type ? 1 : -1))))
    }
  }, [])

  if (lineItemOptions.length === 0) return <>loading...</>

  const filteredOptions = lineItem =>
    ((lineItem.type && lineItemOptions.find(item => item.type === lineItem.type)?.options) || []).sort((a, b) =>
      a.name > b.name ? 1 : -1
    )

  const lookupPrice = ({ type, option }) =>
    (type &&
      option &&
      lineItemOptions.find(item => item.type === type).options.find(item => item.name === option).price) ||
    null

  const availableSideOptionsForLineItem = lineItem =>
    (lineItem.type && lineItemOptions.find(item => item.type === lineItem.type)?.sides) || []

  const requiresSideSelection = lineItem => availableSideOptionsForLineItem(lineItem).length > 0

  const lineItemIsValid = lineItem =>
    (requiresSideSelection(lineItem) ? lineItem.side : true) && lineItem.type && lineItem.option && lineItem.price

  const toggleComplete = completedStatus => {
    setToggleCompleteCheckboxDisabled(true)

    fetch(`https://${process.env.REACT_APP_API_HOST}/toggle_b_to_b_invoice_completed`, {
      method: 'POST',
      headers: bearerTokenHeaders(user.token),
      body: JSON.stringify({
        bToBInvoiceId: invoice.id,
        completed: completedStatus,
        technicianId: technician.id,
      }),
    })
      .then(res => {
        if (completedStatus) {
          setTimeout(() => history.push('/b-to-b-invoices'), 750)
        } else {
          setTimeout(() => setToggleCompleteCheckboxDisabled(false), 750)
        }
      })
      .catch(() => window.alert('There was an error creating a new invoice.'))
  }

  if (!lineItemOptions) return <div>Loading...</div>

  const VehiclePicker = () => {
    const [selectsDisabled, setSelectsDisabled] = useState(!!invoice.completedAt)

    const years = Array(18)
      .fill(2005)
      .map((x, y) => x + y)

    const { loading: makesLoading, data: fetchedMakes } = useQuery(VEHICLE_MAKES, {
      variables: { year: invoice.year, distinctBy: 'makeShortName' },
    })

    const { loading: modelsLoading, data: fetchedModels } = useQuery(VEHICLE_MODELS, {
      variables: { year: invoice.year, makeShortName: invoice.make, distinctBy: 'modelShortName' },
    })

    const updateAttribute = attr => {
      setSelectsDisabled(true)

      fetch(`https://${process.env.REACT_APP_API_HOST}/update_b_to_b_invoice_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders(user.token),
        body: JSON.stringify({
          bToBInvoiceId: invoice.id,
          updatedAttributes: attr,
          actorType: 'technician',
          actorId: technician.id,
        }),
      })
        .catch(() => window.alert('Error'))
        .finally(() => setSelectsDisabled(false))
    }

    // if (makesLoading || modelsLoading) return <div>loading</div>

    const handleSelectedYear = year => updateAttribute({ year })
    const handleSelectedMake = make => updateAttribute({ make })
    const handleSelectedModel = model => updateAttribute({ model })

    const yearsForSelect = years.sort((a, b) => (a < b ? 1 : -1))

    const makesForSelect =
      fetchedMakes?.vehicles
        .slice()
        .sort((a, b) => (a.makePrettyName > b.makePrettyName ? 1 : -1))
        .map(vehicle => ({ prettyName: vehicle.makePrettyName, shortName: vehicle.makeShortName })) || []

    const modelsForSelect =
      fetchedModels?.vehicles
        .slice()
        .sort((a, b) => (a.modelPrettyName > b.modelPrettyName ? 1 : -1))
        .map(vehicle => ({ prettyName: vehicle.modelPrettyName, shortName: vehicle.modelShortName })) || []

    return (
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={4} sx={{ pr: '.5rem' }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Year</InputLabel>
              <Select
                disabled={selectsDisabled}
                value={invoice.year}
                label='year'
                onChange={e => handleSelectedYear(e.target.value)}
              >
                {yearsForSelect.map(year => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4} sx={{ pl: '.5rem', pr: '.5rem' }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Make</InputLabel>
              <Select
                value={invoice.make}
                label='make'
                disabled={makesLoading || selectsDisabled}
                onChange={e => handleSelectedMake(e.target.value)}
              >
                {makesForSelect.map(({ shortName, prettyName }) => (
                  <MenuItem key={shortName} value={shortName}>
                    {prettyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4} sx={{ pl: '.5rem' }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Model</InputLabel>
              <Select
                value={invoice.model}
                label='model'
                disabled={modelsLoading || selectsDisabled || !invoice.make}
                onChange={e => handleSelectedModel(e.target.value)}
              >
                {modelsForSelect.map(({ shortName, prettyName }) => (
                  <MenuItem key={shortName} value={shortName}>
                    {prettyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const VinPhoto = () => {
    const [showVinPhotoDrawer, setShowVinPhotoDrawer] = useState(false)
    const [currentFullScreenPhoto, setCurrentFullScreenPhoto] = useState(null)

    return (
      <Grid item xs={12}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              borderRadius: '6px 6px 0px 0px',
              fontWeight: 700,
              fontSize: '14px',
              padding: '.5rem 1rem',
              display: 'flex',
              alignItems: 'center',
              background: '#b5d9ff',
            }}
          >
            VIN Photo
            {invoice.vinPhoto && !invoice.payoutData && !invoice.completedAt && (
              <Button
                onClick={() => setShowVinPhotoDrawer(!showVinPhotoDrawer)}
                disableElevation
                size='small'
                sx={{
                  ml: 'auto',
                  color: '#222',
                  padding: '0rem .5rem',
                }}
              >
                [ REPLACE ]
              </Button>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              background: '#fff',
              border: '1px solid #ddd',
              borderBottom: '0px',
              borderTop: '0px',
              textAlign: 'center',
            }}
          >
            {(!!invoice.vinPhoto && (
              <div style={{ width: '100%', height: '200px' }}>
                <img
                  onClick={() => setCurrentFullScreenPhoto(invoice.vinPhoto)}
                  style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                  src={thumbnailUrlForPhoto(invoice.vinPhoto)}
                />
              </div>
            )) || <>No photo uploaded yet</>}
          </Grid>

          {!!invoice.vinPhoto || (
            <Grid
              item
              xs={12}
              sx={{
                border: '1px solid #ddd',
                fontSize: '14px',
                borderRadius: '0px 0px 6px 6px',
                display: 'flex',
                padding: '.25rem .5rem',
                background: '#f1f1f1',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                size='small'
                color='primary'
                variant='contained'
                onClick={() => setShowVinPhotoDrawer(!showVinPhotoDrawer)}
                disabled={!!invoice.completedAt}
                sx={{ padding: '.25rem 1rem' }}
              >
                Upload Photo
              </Button>
            </Grid>
          )}
        </Grid>

        <Drawer
          open={showVinPhotoDrawer}
          onClose={() => setShowVinPhotoDrawer(false)}
          anchor='bottom'
          PaperProps={{
            sx: {
              textAlign: 'center',
              pt: '1rem',
            },
          }}
        >
          <Grid container sx={{ padding: '0rem 1rem 1rem 1rem' }}>
            <Grid item xs={12} sx={{ pb: '1rem' }}>
              <Typography variant='h6'>VIN Photo</Typography>
            </Grid>
            <Grid item xs={12}>
              <Uploader
                multiple={false}
                closeDrawer={() => setShowVinPhotoDrawer(false)}
                url='set_b_to_b_vin_photo_url'
              />
            </Grid>
          </Grid>
        </Drawer>

        <Modal open={!!currentFullScreenPhoto} onClose={() => setCurrentFullScreenPhoto(null)}>
          <Box sx={modalStyle}>
            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
              <Cancel
                sx={{
                  zIndex: 999,
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  fontSize: '30px',
                  color: '#fff',
                  border: '2px solid #fff',
                  borderRadius: '20px',
                }}
                onClick={() => setCurrentFullScreenPhoto(null)}
              />
              <PinchZoomPan position='center' maxScale={2}>
                <img src={currentFullScreenPhoto && fullSizeUrlForPhoto(currentFullScreenPhoto)} />
              </PinchZoomPan>
            </div>
          </Box>
        </Modal>
      </Grid>
    )
  }

  const Uploader = ({ url, closeDrawer, multiple }) => {
    const handleSubmit = files =>
      fetch(`https://${process.env.REACT_APP_API_HOST}/${url}`, {
        method: 'POST',
        headers: bearerTokenHeaders(user.token),
        body: JSON.stringify({
          actor_id: technician.id,
          actor_type: 'technician',
          photo_urls: files.map(file => file.meta.fileUrl),
          photo_url: files.map(file => file.meta.fileUrl)[0],
          b_to_b_invoice_id: invoice.id,
        }),
      })
        .then(res => {
          if (res.ok) {
            files.forEach(file => file.remove())
            closeDrawer()
            return true
          } else {
            throw Error('error')
          }
        })
        .catch(e => window.alert(e.message))

    return (
      <Dropzone
        getUploadParams={getUploadParamsForDropzone}
        multiple={multiple}
        accept='image/jpg,image/jpeg,image/png'
        onSubmit={handleSubmit}
        submitButton
        inputContent='Click to Select Photos to Upload'
      />
    )
  }

  const PreJobPhotos = () => {
    const [openPreJobPhotosDrawer, setOpenPreJobPhotosDrawer] = useState(false)
    const [expandPhotoSection, setExpandPhotoSection] = useState(false)
    const [showDeleteIconsOnPhotos, setShowDeleteIconsOnPhotos] = useState(false)
    const [currentFullScreenPhoto, setCurrentFullScreenPhoto] = useState(null)

    const preJobPhotos = invoice.preJobPhotos || []

    const deletePhoto = (e, photo) => {
      e.stopPropagation()

      window.confirm('Are you sure you want to delete this photo?') &&
        fetch(`https://${process.env.REACT_APP_API_HOST}/remove_pre_job_photo_url_from_b_to_b_invoice`, {
          method: 'POST',
          headers: bearerTokenHeaders(user.token),
          body: JSON.stringify({
            actor_id: technician.id,
            actor_type: 'technician',
            photo_id: photo.id,
            b_to_b_invoice_id: invoice.id,
          }),
        })
          .then(res => {
            if (res.ok) {
              return true
            } else {
              throw Error('error')
            }
          })
          .catch(e => window.alert(e.message))
    }

    useEffect(() => {
      showDeleteIconsOnPhotos && setExpandPhotoSection(showDeleteIconsOnPhotos)
    }, [showDeleteIconsOnPhotos])

    return (
      <Grid item xs={12}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              borderRadius: '6px 6px 0px 0px',
              fontWeight: 700,
              fontSize: '14px',
              padding: '.5rem 1rem',
              background: '#bbc7d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            PRE-Job Photos
            {preJobPhotos.length > 0 && (
              <>
                &nbsp;({preJobPhotos.length})
                {!invoice.payoutData && !invoice.completedAt && (
                  <>
                    <Button
                      size='small'
                      color='secondary'
                      disabled={showDeleteIconsOnPhotos}
                      onClick={() => setOpenPreJobPhotosDrawer(!openPreJobPhotosDrawer)}
                      sx={{ ml: 'auto', padding: '0rem 1rem' }}
                    >
                      Add More
                    </Button>
                    <Button
                      onClick={() => setShowDeleteIconsOnPhotos(!showDeleteIconsOnPhotos)}
                      disableElevation
                      size='small'
                      sx={{
                        ml: '.25rem',
                        color: showDeleteIconsOnPhotos ? '#4765A4' : '#222',
                        padding: '0rem .5rem',
                      }}
                    >
                      [ EDIT{showDeleteIconsOnPhotos && 'ING'} ]
                    </Button>
                  </>
                )}
              </>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              borderRadius: preJobPhotos.length > 0 && preJobPhotos.length < 8 ? '0px 0px 6px 6px' : '0px',
              background: '#fff',
              padding: '1rem .5rem',
              border: '1px solid #ddd',
              borderBottom: '0px',
              borderTop: '0px',
              textAlign: 'center',
            }}
          >
            {preJobPhotos.length > 0 ? (
              <Grid container spacing={2}>
                {preJobPhotos.map(
                  (photo, index) =>
                    (expandPhotoSection || index < 8) && (
                      <Grid item xs={3} sm={3} md={2} key={photo.originalUrl}>
                        <Grid
                          onClick={() => setCurrentFullScreenPhoto(photo)}
                          sx={{
                            borderRadius: '3px',
                            minHeight: '75px',
                            background: `url(${thumbnailUrlForPhoto(photo)})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                          }}
                        >
                          <Grow in={showDeleteIconsOnPhotos} sx={{ width: '100%' }}>
                            <Grid container sx={{ display: 'flex' }}>
                              <Button
                                onClick={e => deletePhoto(e, photo)}
                                color='error'
                                sx={{ ml: 'auto', mt: '-.5rem', mr: '-.5rem' }}
                              >
                                <Cancel
                                  color='error'
                                  sx={{
                                    background: '#fff',
                                    ml: 'auto',
                                    mt: '-.5rem',
                                    mr: '-.5rem',
                                    borderRadius: '50px',
                                    border: '2px solid #E62937',
                                  }}
                                />
                              </Button>
                            </Grid>
                          </Grow>
                        </Grid>
                      </Grid>
                    )
                )}
              </Grid>
            ) : (
              <> No photos uploaded yet </>
            )}
          </Grid>

          {preJobPhotos?.length > 8 && (
            <Grid
              item
              xs={12}
              onClick={() => setExpandPhotoSection(!expandPhotoSection)}
              sx={{
                border: '1px solid #ddd',
                fontSize: '14px',
                borderRadius: '0px 0px 6px 6px',
                display: 'flex',
                padding: '.25rem .5rem',
                background: '#f1f1f1',
                justifyContent: 'center',
                alignItems: 'center',
                '&:active': {
                  background: '#eee',
                },
              }}
            >
              See
              {expandPhotoSection ? ' less' : ' all'}
              <KeyboardArrowDown
                sx={{
                  transform: expandPhotoSection ? 'rotate(180deg)' : '',
                  ml: '1rem',
                  transition: 'all .25s ease-in-out',
                }}
              />
            </Grid>
          )}

          {preJobPhotos?.length < 1 && (
            <Grid
              item
              xs={12}
              sx={{
                border: '1px solid #ddd',
                fontSize: '14px',
                borderRadius: '0px 0px 6px 6px',
                display: 'flex',
                padding: '.25rem .5rem',
                background: '#f1f1f1',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                size='small'
                variant='contained'
                disabled={!!invoice.completedAt}
                onClick={() => setOpenPreJobPhotosDrawer(!openPreJobPhotosDrawer)}
                sx={{
                  padding: '.25rem 1rem',
                }}
              >
                Upload Photos
              </Button>
            </Grid>
          )}
        </Grid>

        <Drawer
          open={openPreJobPhotosDrawer}
          onClose={() => setOpenPreJobPhotosDrawer(false)}
          anchor='bottom'
          PaperProps={{
            sx: {
              textAlign: 'center',
              pt: '1rem',
            },
          }}
        >
          <Grid container sx={{ padding: '0rem 1rem 1rem 1rem' }}>
            <Grid item xs={12} sx={{ pb: '1rem' }}>
              <Typography variant='h6'>Pre-Job Photos</Typography>
            </Grid>
            <Grid item xs={12}>
              <Uploader
                multiple
                closeDrawer={() => setOpenPreJobPhotosDrawer(false)}
                url='add_pre_job_photo_urls_to_b_to_b_invoice'
              />
            </Grid>
          </Grid>
        </Drawer>

        <Modal open={!!currentFullScreenPhoto} onClose={() => setCurrentFullScreenPhoto(null)}>
          <Box sx={modalStyle}>
            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
              <Cancel
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  fontSize: '30px',
                  color: '#fff',
                  border: '2px solid #fff',
                  borderRadius: '20px',
                }}
                onClick={() => setCurrentFullScreenPhoto(null)}
              />
              <PinchZoomPan position='center' maxScale={2}>
                <img src={currentFullScreenPhoto && currentFullScreenPhoto.originalUrl} />
              </PinchZoomPan>
            </div>
          </Box>
        </Modal>
      </Grid>
    )
  }

  const LineItemsSection = () => {
    const [editingLineItems, setEditingLineItems] = useState(false)
    const [waiting, setWaiting] = useState(false)

    const NewLineItem = () => {
      const [lineItem, setLineItem] = useState({})

      const resetLineItem = () => setLineItem({})

      const handleSelectedType = type => setLineItem(lineItem => ({ type }))

      const handleSelectedSide = side => setLineItem(lineItem => ({ ...lineItem, side }))

      const handleSelectedOption = option =>
        setLineItem(prev => ({
          ...prev,
          ...{
            option,
            price: lookupPrice({ type: lineItem.type, option }),
          },
        }))

      useEffect(() => {
        setLineItem(prev => ({ ...prev, price: lookupPrice({ type: lineItem.type, option: lineItem.option }) }))
      }, [lineItem.type, lineItem.option])

      const availableSideOptions = availableSideOptionsForLineItem(lineItem)

      const requiresSideSelection = availableSideOptions.length > 0

      const lineItemIsSavable =
        (requiresSideSelection ? lineItem.side : true) && lineItem.type && lineItem.option && lineItem.price

      const handleClickAdd = () => {
        setWaiting(true)
        fetch(`https://${process.env.REACT_APP_API_HOST}/create_b_to_b_invoice_line_item`, {
          method: 'POST',
          headers: bearerTokenHeaders(user.token),
          body: JSON.stringify({
            bToBInvoiceId: invoice.id,
            lineItem,
            actorType: 'technician',
            actorId: technician.id,
          }),
        })
          .catch(() => window.alert('There was an error creating a new invoice.'))
          .finally(() => setWaiting(false))
      }

      return (
        <Grid container spacing={0} sx={{ mb: '1.25rem' }}>
          <Grid item xs={12} sx={{ mb: '.25rem' }}>
            Add
          </Grid>

          <Grid item xs={12} sx={{ pr: '.25rem' }}>
            <Grid container>
              <Grid item xs={availableSideOptions.length === 0 ? 8 : 5} sx={{ pr: '.25rem' }}>
                <FormControl fullWidth size='small'>
                  <InputLabel>Type</InputLabel>
                  <Select
                    label='type'
                    size='small'
                    value={lineItem.type || ''}
                    onChange={e => handleSelectedType(e.target.value)}
                  >
                    {lineItemOptions.map(item => (
                      <MenuItem key={item.id} value={item.type}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {availableSideOptions.length > 0 && (
                <Grid item xs={3}>
                  <FormControl fullWidth size='small'>
                    <InputLabel>side</InputLabel>
                    <Select label='side' value={lineItem.side || ''} onChange={e => handleSelectedSide(e.target.value)}>
                      {availableSideOptions.map(item => (
                        <MenuItem key={item.name} value={item.name}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={4} sx={{ pr: '.5rem', pl: '.5rem' }}>
                <FormControl fullWidth size='small'>
                  <InputLabel>Option</InputLabel>
                  <Select
                    label='option'
                    value={lineItem.option || ''}
                    onChange={e => handleSelectedOption(e.target.value)}
                  >
                    {filteredOptions(lineItem).map(item => (
                      <MenuItem key={item.id} value={item.name}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Collapse in={lineItemIsSavable} sx={{ width: '100%' }}>
            <Grid container>
              <Grid item xs={12} sx={{ display: 'flex', mt: '.5rem' }}>
                <Button onClick={resetLineItem} color='error'>
                  Cancel
                </Button>
                <Button
                  disabled={waiting}
                  onClick={handleClickAdd}
                  disableElevation
                  variant='contained'
                  sx={{ ml: 'auto' }}
                >
                  Add {shouldEnableLineItemPrices && <>({`$${lineItem.price}`}) </>}
                </Button>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      )
    }

    const EditLineItem = ({ lineItem }) => {
      const [editableLineItem, setEditableLineItem] = useState({ ...lineItem })

      const resetEditableLineItem = () => setEditableLineItem({ ...lineItem })

      const handleSelectType = type =>
        setEditableLineItem(prev => ({
          ...prev,
          ...{ type, side: null, option: null, price: null },
        }))

      const handleSelectSide = side =>
        setEditableLineItem(prev => ({
          ...prev,
          ...{ side },
        }))

      const handleSelectOption = option =>
        setEditableLineItem(prev => ({
          ...prev,
          ...{
            option,
            price: lookupPrice({ type: prev.type, option }),
          },
        }))

      const handleClickSave = () =>
        fetch(`https://${process.env.REACT_APP_API_HOST}/update_b_to_b_invoice_line_item`, {
          method: 'POST',
          headers: bearerTokenHeaders(user.token),
          body: JSON.stringify({
            bToBInvoiceId: invoice.id,
            lineItem: editableLineItem,
            actorType: 'technician',
            actorId: technician.id,
          }),
        }).catch(() => window.alert('Error'))

      const handleClickDelete = () =>
        window.confirm('Are you sure you want to delete this line item?') &&
        fetch(`https://${process.env.REACT_APP_API_HOST}/delete_b_to_b_invoice_line_item`, {
          method: 'POST',
          headers: bearerTokenHeaders(user.token),
          body: JSON.stringify({
            bToBInvoiceId: invoice.id,
            lineItemId: lineItem.id,
            actorType: 'technician',
            actorId: technician.id,
          }),
        }).catch(() => window.alert('There was an error creating a new invoice.'))

      const editableHasDiff =
        editableLineItem.type !== lineItem.type ||
        editableLineItem.side !== lineItem.side ||
        editableLineItem.option !== lineItem.option ||
        editableLineItem.price !== lineItem.price

      const allowSave = editableLineItem && lineItemIsValid(editableLineItem)

      return (
        <Grid
          container
          sx={{
            borderRadius: '6px',
            border: '1px solid #e8ddd9',
            background: '#fffdf8',
            mb: '0.5em',
          }}
        >
          <Grid container xs={12} sx={{ margin: '4px 0' }}>
            <Grid item xs={6}>
              <FormControl fullWidth size='small' sx={{ background: '#fff' }}>
                <InputLabel>Type</InputLabel>
                <Select label='type' value={editableLineItem.type} onChange={e => handleSelectType(e.target.value)}>
                  {lineItemOptions.map(item => (
                    <MenuItem key={item.type} value={item.type}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {requiresSideSelection(editableLineItem) && (
              <Grid item xs={6}>
                <FormControl fullWidth size='small' sx={{ background: '#fff' }}>
                  <InputLabel>Side</InputLabel>
                  <Select label='side' value={editableLineItem.side} onChange={e => handleSelectSide(e.target.value)}>
                    {availableSideOptionsForLineItem(editableLineItem).map(item => (
                      <MenuItem key={item.name} value={item.name}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>

          <Grid container xs={12} sx={{ mb: '4px' }}>
            <Grid item xs={6}>
              <FormControl fullWidth size='small' sx={{ background: '#fff' }}>
                <InputLabel>Option</InputLabel>
                <Select
                  label='option'
                  value={editableLineItem.option}
                  onChange={e => handleSelectOption(e.target.value)}
                >
                  {filteredOptions(editableLineItem).map(item => (
                    <MenuItem key={item.name} value={item.name}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={6}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {shouldEnableLineItemPrices ? `$${editableLineItem.price || 0}` : ''}&nbsp;
              <Button disabled={editableHasDiff} color='error' onClick={handleClickDelete}>
                [delete]
              </Button>
            </Grid>
          </Grid>

          <Collapse in={editableHasDiff} sx={{ width: '100%' }}>
            <Grid container>
              <Grid item xs={12} sx={{ p: '5px', display: 'flex' }}>
                <Button onClick={resetEditableLineItem} color='warning' size='small'>
                  cancel/reset
                </Button>
                <Button
                  disabled={!allowSave}
                  onClick={handleClickSave}
                  disableElevation
                  variant='contained'
                  size='small'
                  sx={{ ml: 'auto' }}
                >
                  Save changes
                </Button>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      )
    }

    const ShowLineItem = ({ lineItem }) => {
      return (
        <Grid
          container
          style={{
            padding: '4px',
            borderBottom: '1px dashed lightsteelblue',
          }}
        >
          <Grid item xs={5} sx={{ p: '4px' }}>
            {lineItemOptions.find(option => option.type === lineItem.type).title}

            {lineItem.side && (
              <>
                <br />({availableSideOptionsForLineItem(lineItem).find(option => option.name === lineItem.side).title})
              </>
            )}
          </Grid>

          <Grid item xs={5} sx={{ p: '4px' }}>
            {filteredOptions(lineItem).find(option => option.name === lineItem.option).title}
          </Grid>

          <Grid item xs={2} sx={{ textAlign: 'right', p: '4px' }}>
            {shouldEnableLineItemPrices ? `$${lineItem.price}` : ''}
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid item xs={12}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              borderRadius: '6px 6px 0px 0px',
              fontWeight: 700,
              fontSize: '14px',
              padding: '.5rem 1rem',
              background: '#fff',
              border: '1px solid #ddd',
            }}
          >
            Line Items
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              background: '#fff',
              padding: '.5rem .5rem 1rem .5rem',
              borderRadius: '0px 0px 6px 6px',
              border: '1px solid #ddd',
              borderTop: '0px',
            }}
          >
            {!invoice.completedAt && <NewLineItem />}

            <Grid
              container
              sx={{
                border: '1px solid #d8dBdd',
                mt: '.25rem',
                background: '#F8FBFF',
                borderRadius: '8px',
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '.5rem .5rem',
                  fontWeight: 600,
                  fontSize: '14px',
                }}
              >
                Current line items
                <Grow in={!invoice.payoutData && !invoice.completedAt && invoice.lineItems.length > 0}>
                  <Button
                    color='primary'
                    onClick={() => setEditingLineItems(!editingLineItems)}
                    sx={{ ml: 'auto', padding: '.125rem' }}
                  >
                    [ Edit{editingLineItems && 'ing'} ]
                  </Button>
                </Grow>
              </Grid>

              <Grid item xs={12}>
                {invoice.lineItems.map(item =>
                  editingLineItems ? (
                    <EditLineItem key={item.id} lineItem={item} />
                  ) : (
                    <ShowLineItem key={item.id} lineItem={item} />
                  )
                )}
              </Grid>

              {shouldEnableLineItemPrices && (
                <>
                  {!!invoice.discountPercent && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          padding: '0.75rem 0.5rem 0rem 0rem',
                          textAlign: 'right',
                          ml: 'auto',
                        }}
                      >
                        Subtotal: ${invoice.lineItemsSum.toFixed(2)}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          padding: '0.25rem 0.5rem 0rem 0rem',
                          textAlign: 'right',
                          ml: 'auto',
                        }}
                      >
                        Discount (-{invoice.discountPercent}%): -${invoice.discountPercentTotal.toFixed(2)}
                      </Grid>
                    </>
                  )}

                  <Grid
                    item
                    xs={12}
                    sx={{
                      fontWeight: 600,
                      padding: '0.75rem .5rem',
                      textAlign: 'right',
                      ml: 'auto',
                    }}
                  >
                    Grand total: <Dollars value={invoice.grandTotal || invoice.lineItemsSum} />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const PostJobPhotos = () => {
    const [showPostJobPhotosDrawer, setShowPostJobPhotosDrawer] = useState(false)
    const { postJobPhotos } = invoice
    const [expandPhotoSection, setExpandPhotoSection] = useState(false)
    const [showDeleteIconsOnPhotos, setShowDeleteIconsOnPhotos] = useState(false)
    const [currentFullScreenPhoto, setCurrentFullScreenPhoto] = useState(null)

    const deletePhoto = (e, photo) => {
      e.stopPropagation()

      if (window.confirm('Are you sure you want to delete this photo?')) {
        fetch(`https://${process.env.REACT_APP_API_HOST}/remove_post_job_photo_url_from_b_to_b_invoice`, {
          method: 'POST',
          headers: bearerTokenHeaders(user.token),
          body: JSON.stringify({
            actor_id: technician.id,
            actor_type: 'technician',
            photo_id: photo.id,
            b_to_b_invoice_id: invoice.id,
          }),
        })
          .then(response => {
            if (response.ok) {
              return true
            } else {
              throw Error('error')
            }
          })
          .catch(e => {
            alert(e.message)
          })
      }
    }

    useEffect(() => {
      showDeleteIconsOnPhotos && setExpandPhotoSection(showDeleteIconsOnPhotos)
    }, [showDeleteIconsOnPhotos])

    return (
      <Grid item xs={12}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              borderRadius: '6px 6px 0px 0px',
              fontWeight: 700,
              fontSize: '14px',
              padding: '.5rem 1rem',
              background: '#bbc7d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            POST-Job Photos
            {postJobPhotos?.length > 0 && (
              <>
                &nbsp;({postJobPhotos.length})
                {!invoice.payoutData && !invoice.completedAt && (
                  <>
                    <Button
                      size='small'
                      color='secondary'
                      disabled={showDeleteIconsOnPhotos}
                      onClick={() => setShowPostJobPhotosDrawer(!showPostJobPhotosDrawer)}
                      sx={{ ml: 'auto', padding: '0rem 1rem' }}
                    >
                      Add More
                    </Button>
                    <Button
                      onClick={() => setShowDeleteIconsOnPhotos(!showDeleteIconsOnPhotos)}
                      disableElevation
                      size='small'
                      sx={{
                        ml: '.25rem',
                        color: showDeleteIconsOnPhotos ? '#4765A4' : '#222',
                        padding: '0rem .5rem',
                      }}
                    >
                      [ EDIT{showDeleteIconsOnPhotos && 'ING'} ]
                    </Button>
                  </>
                )}
              </>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              borderRadius: postJobPhotos.length > 0 && postJobPhotos.length < 8 ? '0px 0px 6px 6px' : '0px',
              background: '#fff',
              padding: '1rem .5rem',
              border: '1px solid #ddd',
              borderBottom: '0px',
              borderTop: '0px',
              textAlign: 'center',
            }}
          >
            {postJobPhotos?.length > 0 ? (
              <Grid container spacing={2}>
                {postJobPhotos.map(
                  (photo, index) =>
                    (expandPhotoSection || index < 8) && (
                      <Grid item xs={3} sm={3} md={2} key={photo.id}>
                        <Grid
                          onClick={() => setCurrentFullScreenPhoto(photo)}
                          sx={{
                            borderRadius: '3px',
                            minHeight: '75px',
                            background: `url(${thumbnailUrlForPhoto(photo)})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                          }}
                        >
                          <Grow in={showDeleteIconsOnPhotos} sx={{ width: '100%' }}>
                            <Grid container sx={{ display: 'flex' }}>
                              <Button
                                onClick={e => deletePhoto(e, photo)}
                                color='error'
                                sx={{ ml: 'auto', mt: '-.5rem', mr: '-.5rem' }}
                              >
                                <Cancel
                                  color='error'
                                  sx={{
                                    background: '#fff',
                                    ml: 'auto',
                                    mt: '-.5rem',
                                    mr: '-.5rem',
                                    borderRadius: '50px',
                                    border: '2px solid #E62937',
                                  }}
                                />
                              </Button>
                            </Grid>
                          </Grow>
                        </Grid>
                      </Grid>
                    )
                )}
              </Grid>
            ) : (
              <> No photos uploaded yet </>
            )}
          </Grid>

          {postJobPhotos?.length > 0 ? (
            postJobPhotos.length > 8 ? (
              <Grid
                item
                xs={12}
                onClick={() => setExpandPhotoSection(!expandPhotoSection)}
                sx={{
                  border: '1px solid #ddd',
                  fontSize: '14px',
                  borderRadius: '0px 0px 6px 6px',
                  display: 'flex',
                  padding: '.25rem .5rem',
                  background: '#f1f1f1',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '&:active': { background: '#eee' },
                }}
              >
                See
                {expandPhotoSection ? ' less' : ' all'}
                <KeyboardArrowDown
                  sx={{
                    transform: expandPhotoSection ? 'rotate(180deg)' : '',
                    ml: '1rem',
                    transition: 'all .25s ease-in-out',
                  }}
                />
              </Grid>
            ) : (
              ''
            )
          ) : (
            <Grid
              item
              xs={12}
              sx={{
                border: '1px solid #ddd',
                fontSize: '14px',
                borderRadius: '0px 0px 6px 6px',
                display: 'flex',
                padding: '.25rem .5rem',
                background: '#f1f1f1',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                size='small'
                variant='contained'
                onClick={() => setShowPostJobPhotosDrawer(!showPostJobPhotosDrawer)}
                disabled={!!invoice.completedAt}
                sx={{ padding: '.25rem 1rem' }}
              >
                Upload Photos
              </Button>
            </Grid>
          )}
        </Grid>

        <Drawer
          open={showPostJobPhotosDrawer}
          onClose={() => setShowPostJobPhotosDrawer(false)}
          anchor='bottom'
          PaperProps={{
            sx: {
              textAlign: 'center',
              pt: '1rem',
            },
          }}
        >
          <Grid container sx={{ padding: '0rem 1rem 1rem 1rem' }}>
            <Grid item xs={12} sx={{ pb: '1rem' }}>
              <Typography variant='h6'>Post-Job Photos</Typography>
            </Grid>
            <Grid item xs={12}>
              <Uploader
                multiple
                closeDrawer={() => setShowPostJobPhotosDrawer(false)}
                url='add_post_job_photo_urls_to_b_to_b_invoice'
              />
            </Grid>
          </Grid>
        </Drawer>

        <Modal open={!!currentFullScreenPhoto} onClose={() => setCurrentFullScreenPhoto(null)}>
          <Box sx={modalStyle}>
            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
              <Cancel
                sx={{
                  zIndex: 999,
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  fontSize: '30px',
                  color: '#fff',
                  border: '2px solid #fff',
                  borderRadius: '20px',
                }}
                onClick={() => setCurrentFullScreenPhoto(null)}
              />
              <PinchZoomPan position='center' maxScale={2}>
                <img src={currentFullScreenPhoto && currentFullScreenPhoto.originalUrl} />
              </PinchZoomPan>
            </div>
          </Box>
        </Modal>
      </Grid>
    )
  }

  return (
    <Container disableGutters maxWidth='sm'>
      <Grid container spacing={2} style={{ position: 'relative' }}>
        <IconButton
          aria-label='back'
          style={{ position: 'absolute', left: '16px', top: '12px' }}
          onClick={() => {
            history.push('/b-to-b-invoices')
          }}
        >
          <ArrowBackIosNewOutlinedIcon />
        </IconButton>

        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography variant='h6' sx={{ textTransform: 'capitalize' }}>
            B2B Invoice <br />
            <span style={{ opacity: 0.75, fontSize: '14px' }}>
              Created: {DateTime.fromISO(invoice.createdAt).toLocaleString()}
            </span>
          </Typography>
        </Grid>

        {invoice.completedAt && (
          <Grid item xs={12}>
            <b
              style={{
                display: 'block',
                padding: '.25rem .5rem',
                textAlign: 'center',
                color: '#111',
                backgroundColor: 'palegreen',
              }}
            >
              COMPLETED {DateTime.fromISO(invoice.completedAt).toFormat('D hh:mm')}
            </b>
          </Grid>
        )}

        <VehiclePicker />

        <VinPhoto />

        <PreJobPhotos />

        <LineItemsSection />

        <PostJobPhotos />

        <Chat contextId={invoice.id} contextType='BToBInvoice' />

        <Collapse in sx={{ width: '100%' }}>
          <Grid container>
            <Grid
              item
              sx={{
                padding: '1rem 1rem 1rem 0rem',
                ml: 'auto',
                width: 'auto',
              }}
            >
              <FormControlLabel
                label='Completed'
                fullWidth
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  background: '#f7f7f7',
                  borderRadius: '8px',
                  pl: '.75rem',
                  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
                  border: '1px solid #ddd',
                  '&:active': {
                    background: '#eee',
                  },
                }}
                labelPlacement='start'
                componentsProps={{
                  typography: {
                    sx: { fontSize: '18px', ml: 'auto' },
                  },
                }}
                control={
                  <Checkbox
                    disabled={invoice.squareInvoiceId || invoice.payoutData || toggleCompleteCheckboxDisabled}
                    size='large'
                    checked={invoice.completedAt}
                    onChange={() => toggleComplete(!invoice.completedAt)}
                    sx={{ mr: '0px', ml: '1rem' }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Container>
  )
}

export default Invoice
