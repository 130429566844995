import { Switch, Route } from 'react-router-dom'
import { PartsPage } from './Parts'

const PartsRouter = () => {
  return (
    <Switch>
      <Route path='/parts'>
        <PartsPage />
      </Route>
    </Switch>
  )
}

export default PartsRouter
