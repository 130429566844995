import { useStyles } from 'presentation/pages/Payout/styles'
import {
  useTheme,
  Container,
  Grid,
  TableBody,
  TableCell,
  TableRow,
  Table,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { Dollars, formatFullDate, underscoreToTitleCase } from '../../../../tools'

export const ManualCompensations = ({ sortedManualCompensations }) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <>
      {sortedManualCompensations.length > 0 && (
        <>
          <br />
          <Grid item xs={12} className={classes.sectionHeader}>
            <div style={{ color: theme.mode === 'dark' ? '#fff' : '#373845', fontSize: '1.125rem' }}>
              Manual Compensations
            </div>
          </Grid>

          {sortedManualCompensations.map((comp, index) => {
            const lead = comp.quote.lead || {}

            return (
              <Accordion className={classes.workOrderCard} elevation={1} key={index}>
                <AccordionSummary expandIcon={<ExpandMore />} style={{ padding: '0px', width: '100%' }}>
                  <Grid container>
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ fontWeight: 600, color: '#999', fontSize: '1.75rem' }}>
                        <Dollars value={comp.amount} />
                      </span>
                      <span
                        style={{
                          color: '#545454',
                          fontSize: '1rem',
                          marginLeft: 'auto',
                          marginRight: '1rem',
                          display: 'block',
                          textAlign: 'right',
                        }}
                      >
                        <div>{formatFullDate(comp.createdAt)}</div>
                        <div style={{ color: '#aaa', fontSize: '.8rem' }}>
                          {lead.year} {underscoreToTitleCase(lead.makeShortName)}{' '}
                          {underscoreToTitleCase(lead.modelShortName)}{' '}
                        </div>
                      </span>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '0 0 .75rem 0', borderTop: '1px solid #eee', color: '#3a3a3a' }}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell colspan={2}>{comp.note}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </>
      )}
    </>
  )
}
