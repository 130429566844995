import React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'

const TinyTable = styled((props) => {
  const { expand, ...other } = props
  return <Table size='small' {...other} />
})(({ theme, expand }) => ({
  '& .MuiTableCell-head': {
    padding: '2px',
    fontSize: '12px'
  },
  '& .MuiTableCell-body': {
    padding: '2px',
    fontSize: '12px'
  }
}))

export default TinyTable
