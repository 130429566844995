import 'react-dropzone-uploader/dist/styles.css'
import { useContext } from 'react'
import Dropzone from 'react-dropzone-uploader'
import { getUploadParamsForDropzone } from 'tools'
import { TechnicianContext } from '../../../../GlobalStore'
import { bearerTokenHeaders } from 'tools'

export const PhotoUploader = ({ contextType, contextId }) => {
  const [technician] = useContext(TechnicianContext)

  const handleSubmit = files =>
    fetch(`https://${process.env.REACT_APP_API_HOST}/create_chat_message`, {
      method: 'POST',
      headers: bearerTokenHeaders(technician.token),
      body: JSON.stringify({
        authorId: technician.id,
        authorType: 'tech',
        mediaUrls: files.map(file => file.meta.fileUrl),
        contextType,
        contextId,
      }),
    })
      .then(response => {
        if (response.ok) {
          files.forEach(file => file.remove())
          return true
        } else {
          throw Error('error')
        }
      })
      .catch(e => {
        alert(e.message)
      })

  const handleChangeStatus = () => {
    window.scrollTo(0, 100000)
    return true
  }

  return (
    <Dropzone
      getUploadParams={getUploadParamsForDropzone}
      onChangeStatus={handleChangeStatus}
      multiple
      accept='image/jpg,image/jpeg,image/png'
      onSubmit={handleSubmit}
      submitButton
      inputContent='Select Photos to Upload'
    />
  )
}

export default PhotoUploader
