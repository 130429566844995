import {
  Grid,
  TableBody,
  TableCell,
  TableRow,
  Table,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  Chip,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { DateTime } from 'luxon'
import { Dollars, underscoreToTitleCase } from '../../../../tools'
import { useStyles } from 'presentation/pages/Payout/styles'

export const ChargeBacks = ({ chargeBacks }) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  return (
    <>
      {chargeBacks.length > 0 && (
        <>
          <Grid item xs={12} className={classes.sectionHeader}>
            <div style={{ color: theme.mode === 'dark' ? '#fff' : '#373845', fontSize: '1.125rem' }}>Charge Backs</div>
          </Grid>

          {chargeBacks.map((chargeBack, index) => {
            const workLog = chargeBack.worklog
            const quote = chargeBack.quote
            const job = workLog ? workLog.job : quote.jobs[quote.jobs.length - 1]
            const lead = quote.lead

            return (
              <Accordion className={classes.workOrderCard} elevation={1} key={index}>
                <AccordionSummary expandIcon={<ExpandMore />} style={{ padding: '0px', width: '100%' }}>
                  <Grid container>
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ fontWeight: 600, color: '#999', fontSize: '1.75rem' }}>
                        <Dollars value={chargeBack.amount} />
                      </span>
                      <span
                        style={{
                          color: '#aaa',
                          fontSize: '1rem',
                          marginLeft: 'auto',
                          marginRight: '1rem',
                          display: 'block',
                          textAlign: 'right',
                        }}
                      >
                        <div style={{ color: '#555', fontSize: '1rem' }}>
                          {lead.year} {underscoreToTitleCase(lead.makeShortName)}{' '}
                          {underscoreToTitleCase(lead.modelShortName)}{' '}
                        </div>
                      </span>
                    </Grid>
                  </Grid>
                </AccordionSummary>

                <AccordionDetails style={{ padding: '0 0 .75rem 0', borderTop: '1px solid #eee', color: '#3a3a3a' }}>
                  <Table>
                    <TableBody>
                      <TableRow style={{ background: theme.palette.background.highlight }}>
                        <TableCell style={{ borderBottom: 'none', padding: '0 0 0 .5rem' }}>Job</TableCell>
                        <TableCell align='right' style={{ borderBottom: 'none' }}>
                          {DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toFormat('MM/dd')} -{' '}
                          {DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toFormat('t')} -{' '}
                          {DateTime.fromISO(job.endDatetime, { zone: 'utc' }).toFormat('t')}
                          {!workLog && <Chip size='small' label='last job' />}
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ background: theme.palette.background.highlight }}>
                        <TableCell style={{ borderBottom: 'none', padding: '0 0 0 .5rem' }}>Note</TableCell>
                        <TableCell align='right' style={{ borderBottom: 'none' }}>
                          {chargeBack.note}
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ background: theme.palette.background.highlight }}>
                        <TableCell style={{ borderBottom: 'none', padding: '0 0 0 .5rem', fontWeight: 'bold' }}>
                          Total deduction
                        </TableCell>
                        <TableCell align='right' style={{ borderBottom: 'none', fontWeight: 'bold' }}>
                          <Dollars color={theme.mode === 'dark' ? '#fff' : '#373845'} value={chargeBack.amount} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </>
      )}
    </>
  )
}
