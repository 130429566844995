// import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import { getUploadParamsForDropzone } from 'tools'

const Uploader = () => {
  return (
    <Dropzone
      getUploadParams={getUploadParamsForDropzone}
      multiple
      accept='image/jpg,image/jpeg,image/png'
      onSubmit={() => {}}
      submitButton
      inputContent='Select Photos to Upload'
    />
  )
}

export default Uploader
