import { gql } from '@apollo/client'

export const PartsForDate = gql`
  query PartsForDate($technicianId: ID!, $date: ISO8601Date!) {
    partsForDate(technicianId: $technicianId, date: $date) {
      id
      name
      number
      cost
      price
      status
      quoteId
      pickupStatus
      pickupHistory
      job {
        id
        startDatetime
        endDatetime
        lead {
          id
          name
        }
      }
      readyForPickupAt
      orderRequestedAt
      orderPlacedAt
      readyForPickupAt
      canceledAt
      returnInitiatedAt
      returnedToVendorAt
      returnCompletedAt
      refundInitiatedAt
      refundCompletedAt
      refundedAt
      etaBusinessDays
      isPaid
      invoiceNumber
      partVendorId
      partVendorContact
      hasCoreRefund
      coreRefundExpectedAmount
      coreRefundInitiatedAt
      coreReturnedToVendorAt
      coreRefundCompletedAt
      removedFromQuoteAt
      vendor {
        id
        name
        addressLineOne
        addressLineTwo
        city
        state
        zip
        phone
        website
        email
        paymentNotes
        lat
        lng
      }
    }
  }
`
