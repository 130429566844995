import React, { useEffect, useState, useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { UserContext } from 'UserStore'
import { GlobalContext } from 'GlobalStore'
import Typography from '@material-ui/core/Typography'
import { MentionsInput, Mention } from 'react-mentions'
import mentionsClassNames from 'presentation/styles/mentions.module.css'
import darkModeMentionsClassNames from 'presentation/styles/mentions_dark.module.css'
import Linkify from 'linkifyjs/react'
import { DateTime } from 'luxon'
import { Paper, Grid, Button, useTheme } from '@mui/material'
import LoadingIndicator from 'presentation/components/LoadingIndicator'

const TECH_NOTES_QUERY = gql`
  query techNotes($leadId: ID, $quoteId: ID, $jobId: ID, $partId: ID) {
    techNotes(leadId: $leadId, quoteId: $quoteId, jobId: $jobId, partId: $partId) {
      id
      userType
      userId
      parentType
      parentId
      createdAt
      body
      replyToId
    }
  }
`

const NotesStyles = makeStyles(theme => ({
  notes: {
    margin: '1em',
  },
  new_note_input: {
    padding: '0.75em',
  },
  notes_section_heading: {
    position: 'sticky',
    zIndex: 2,
    top: 0,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    backgroundColor: '#92f692',
    padding: '0.5rem',
    borderRadius: '3px 3px 0 0',
  },
}))

// const mentionableUsers = users => users.map(user => ({id: user.id, display: `${user.firstName} ${user.lastName.charAt(0)}.`}))

const Notes = ({ parentType, parentId, leadId, quoteId, jobId, partId }) => {
  const classes = NotesStyles()
  const theme = useTheme()
  const [user] = useContext(UserContext)
  const [newNote, setNewNote] = useState('')
  const [global] = useContext(GlobalContext)

  const { loading, error, data, refetch } = useQuery(TECH_NOTES_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      leadId,
      quoteId,
      jobId,
      partId,
    },
  })

  useEffect(() => {
    if (data && data.techNotes.length > 0) {
      setTimeout(() => {
        if (document.getElementById('scroll-container')) {
          document.getElementById('scroll-container').scrollTop =
            document.getElementById('scroll-container').scrollHeight
        }
      }, 1000)
    }
  }, [data])

  if (loading) return <LoadingIndicator message='Loading Notes Interface' />
  if (error) return <div>Error</div>

  const sortedTechNotes = [...data.techNotes].sort((a, b) =>
    Date.parse(a.createdAt) > Date.parse(b.createdAt) ? 1 : -1
  )

  const handleClickSubmitNote = () => {
    axios({
      method: 'post',
      url: `https://${process.env.REACT_APP_API_HOST}/api/create_tech_note`,
      data: {
        user_id: user.id,
        user_type: 'tech',
        parent_type: parentType,
        parent_id: parentId,
        body: newNote,
      },
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD,
      },
    })
      .then(res => {
        setNewNote('')
        setTimeout(() => {
          refetch()
        }, 300)
      })
      .catch(error => {
        window.alert('error')
      })
  }

  const handleNewNoteChanged = evt => {
    setNewNote(evt.target.value)
  }

  const disabled = newNote.length < 2

  return (
    <Paper style={{ maxHeight: 'calc(100vh - 100px)', overflowY: 'auto' }} id='scroll-container'>
      <Typography className={classes.notes_section_heading}>Notes</Typography>

      <div className={classes.notes}>
        {sortedTechNotes.map(note => (
          <Grid container key={note.id}>
            <CurrentNote note={note} />
          </Grid>
        ))}
      </div>

      <Grid container style={{ padding: '0 1rem 1rem 1rem' }}>
        <Grid item xs={12}>
          <MentionsInput
            className='mentions'
            cy-data='note-input'
            classNames={theme.palette.mode === 'dark' ? darkModeMentionsClassNames : mentionsClassNames}
            value={newNote}
            onChange={handleNewNoteChanged}
            placeholder='add note'
          >
            <Mention
              displayTransform={(id, display) => `@${display}`}
              style={{ backgroundColor: 'rgb(215 255 170)', color: '#fff' }}
            />
          </MentionsInput>
          <Button
            cy-data='note-input-submit'
            disabled={disabled}
            onClick={handleClickSubmitNote}
            variant='contained'
            color='primary'
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

const CurrentNote = ({ note }) => {
  const [clickedReply, setClickedReply] = useState(false)
  const [clickedReplyAll, setClickedReplyAll] = useState(false)
  const [user] = useContext(UserContext)
  const [global] = useContext(GlobalContext)
  const theme = useTheme()

  const CurrentNoteStyles = makeStyles(theme => ({
    user_and_note: {
      backgroundColor: '#ddfeff',
      flexGrow: 1,
      width: '100%',
      marginBottom: '1em',
      padding: '.75rem',
      borderRadius: '20px 20px 20px 0px',
      boxShadow: '2px 2px 4px rgba(0, 0, 0, .1)',
      '& hr': {
        border: '0px',
        height: '2px',
        opacity: 0.5,
      },
    },
    technician_and_note: {
      backgroundColor: '#d7ffd7',
      textAlign: 'right',
      marginBottom: '1em',
      padding: '.5rem',
      borderRadius: '15px 15px 0px 15px',
      boxShadow: '-2px 2px 4px rgba(0, 0, 0, .1)',
      '& hr': {
        border: '0px',
        height: '1px',
        opacity: 0.2,
      },
    },
  }))

  const classes = CurrentNoteStyles()

  const handleClickCancel = () => {
    setClickedReply(false)
    setClickedReplyAll(false)
  }

  const fromUser = (user.id === note.userId && { firstName: 'me', lastName: '' }) ||
    global.csrs.find(user => user.id === note.userId) || { firstName: 'unknown', lastName: '' }

  return (
    <Grid
      item
      xs={12}
      style={{ marginBottom: '0.6em' }}
      className={user.id === note.userId ? classes.technician_and_note : classes.user_and_note}
    >
      {user.id === note.userId ? (
        <>
          <b> {`${fromUser.firstName} ${fromUser.lastName}`} </b>
          <small>{DateTime.fromISO(note.createdAt).toFormat('yyyy-LL-dd h:mm a ZZZZ')}</small>
        </>
      ) : (
        <>
          <b> {`${fromUser.firstName} ${fromUser.lastName}`} </b>
          <small>{DateTime.fromISO(note.createdAt).toFormat('yyyy-LL-dd h:mm a ZZZZ')}</small>
        </>
      )}

      <div style={{ whiteSpace: 'pre-wrap' }}>
        <Linkify> {note.body} </Linkify>
      </div>
    </Grid>
  )
}

export default Notes
