import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { IconButton, Grid, Box, Stack, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'
import { KeyboardArrowRight } from '@mui/icons-material'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'

import { TechnicianContext } from 'GlobalStore'

const QUERY = gql`
  query suppliesOrders($userId: String!) {
    suppliesOrders(userId: $userId) {
      id
      itemIds
      createdAt
    }
  }
`

const mapItemsByItemId = items => {
  const map = {}
  items.forEach(item => {
    map[item.id] = item
  })
  return map
}

const Title = styled('span')(({ theme }) => ({
  margin: '.25rem 0',
  padding: '.5rem',
  fontFamily: 'Open Sans',
  color: theme.mode === 'dark' ? '#fff' : '#373845',
  fontWeight: 600,
}))

const Open = ({ items }) => {
  const history = useHistory()
  const [technician] = useContext(TechnicianContext)

  const { loading, error, data } = useQuery(QUERY, {
    variables: { userId: technician.id },
  })

  if (error) return <div>Error!</div>
  if (loading) return <div>Loading...</div>

  const orders = data.suppliesOrders || []
  const itemsMap = mapItemsByItemId(items)

  return (
    <Stack>
      <Box sx={{ position: 'relative', textAlign: 'center' }}>
        <Grid container>
          <Grid item xs={1}>
            <IconButton
              aria-label='back'
              sx={{ float: 'left' }}
              onClick={() => {
                history.goBack()
              }}
            >
              <ArrowBackIosNewOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10}>
            <Title>Open Orders</Title>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Box>
      {orders.map(order => (
        <Paper
          key={order.id}
          sx={{ padding: 1, margin: 1 }}
          onClick={() => {
            history.push(`/supplies-orders/${order.id}`)
          }}
        >
          <Grid container>
            <Grid item xs={11}>
              {order.itemIds.map((itemId, i) => (
                <span key={itemId}>
                  {i > 0 && <span>, </span>}
                  <span>{itemsMap[itemId].id} </span>
                  <span>{itemsMap[itemId].type} </span>
                  <span>{itemsMap[itemId].description}</span>
                </span>
              ))}
            </Grid>
            <Grid item xs={1} sx={{ textAlign: 'right' }}>
              <KeyboardArrowRight style={StyleSheet.rightArrow} />
            </Grid>
          </Grid>
        </Paper>
      ))}
    </Stack>
  )
}

export default Open
