import { useQuery } from '@apollo/client'
import { Typography } from '@material-ui/core'
import { Box, useTheme } from '@mui/material'
import { TechnicianContext } from 'GlobalStore'
import { PartsForDate } from 'data/queries/Parts/Parts'
import { DateTime } from 'luxon'
import { useContext, useEffect, useState } from 'react'
import Parts from 'presentation/pages/Job/components/Parts'
import { JOB } from 'data/queries/Job/Job'
import { useHistory } from 'react-router-dom'

export const PartsPage = () => {
  const [technician] = useContext(TechnicianContext)
  const [selectedDate, setSelectedDate] = useState(DateTime.now().toISODate())

  const { data, loading, error, refetch } = useQuery(PartsForDate, {
    fetchPolicy: 'network-only',
    variables: {
      technicianId: technician.id,
      date: selectedDate,
    },
    skip: !technician.id || !selectedDate,
  })

  useEffect(() => {
    selectedDate && refetch()
  }, [selectedDate])

  if (loading) return <>loading...</>
  if (error) return <>error...</>

  const parts = data?.partsForDate || []
  const partsGroupedByJob = parts.reduce(
    (acc, part) =>
      acc[part.job.id] ? { ...acc, [part.job.id]: [...acc[part.job.id], part] } : { ...acc, [part.job.id]: [part] },
    {}
  )

  return (
    <Box>
      <Typography variant='p' style={{ fontSize: '2rem' }}>
        Parts
      </Typography>
      <Typography
        variant='p'
        style={{ display: 'block', marginTop: '.5rem', fontSize: '1rem', opacity: 0.75, fontWeight: 400 }}
      >
        Select a date to see parts
      </Typography>
      <input type='date' value={selectedDate} onChange={e => setSelectedDate(e.target.value)} />

      {Object.entries(partsGroupedByJob).map(([key, value]) => (
        <Box sx={{ mt: '1rem' }}>
          {value[0] && <JobInfo job={value[0].job} />}
          <Parts job={value[0].job} parts={value} refetch={refetch} />
        </Box>
      ))}
      {Object.entries(partsGroupedByJob).length === 0 && (
        <Box sx={{ padding: '4rem 1rem', display: 'flex', justifyContent: 'center' }}>
          There are no parts to show for this day
        </Box>
      )}
    </Box>
  )
}

export const JobInfo = ({ job }) => {
  const history = useHistory()
  return (
    <Box>
      <span style={{ color: 'blue' }} onClick={() => history.push(`/jobs/${job.id}`)}> {job.lead.name} </span> --&nbsp;
      <span style={{ fontSize: '.9rem' }}>
        {DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toFormat('t')} -{' '}
        {DateTime.fromISO(job.endDatetime, { zone: 'utc' }).toFormat('t')}
      </span>
    </Box>
  )
}
