import { Box, Collapse, Typography } from '@mui/material'
import { AnnouncementRounded, CloseRounded, KeyboardArrowDownRounded, ChevronRightRounded } from '@mui/icons-material'
import { useLocalStorage } from 'util/hooks/useLocalStorage'
import { useHistory } from 'react-router-dom'

export default function ({ name, title, initialValue = true, makeContent }) {
  const [open, setOpen] = useLocalStorage(name, initialValue || false, true)
  const history = useHistory()

  function toggleOpen() {
    setOpen(!open)
  }

  return (
    <Collapse in={open} sx={{ width: '100%' }}>
      <Box sx={{ transition: 'all .25s ease-in-out', padding: open ? '1rem' : '.5rem 1rem' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            transition: 'all .25s ease-in-out',
            alignItems: 'center',
            pb: open ? '1rem' : '0rem',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', '& svg': { fontSize: '18px', mr: '1rem' } }}>
            <AnnouncementRounded /> <b>{title}</b>
          </Box>
          {open ? <CloseRounded onClick={toggleOpen} /> : <KeyboardArrowDownRounded onClick={toggleOpen} />}
        </Box>

        <Typography sx={{ background: '#fff', mt: '.5rem', borderRadius: '6px', color: '#222', padding: '1rem' }}>
          {makeContent(setOpen, history)}
        </Typography>
      </Box>
    </Collapse>
  )
}
