import { useTheme, Container, Grid } from '@mui/material'
import Button from '@mui/material/Button'
import { useQuery, gql } from '@apollo/client'
import { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Loading from 'presentation/components/Loading'
import { DateTime } from 'luxon'
import { Dollars, formatFullDate, keysToCamel } from '../../../tools'
import { PDFDownloadLink } from '@react-pdf/renderer'
import PayoutPDF from './components/PayoutPDF'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import IconButton from '@mui/material/IconButton'
import { TimeCards } from '../Payouts/components/TimeCards'
import { useStyles } from './styles'
import { ChargeBacks } from '../Payouts/components/ChargeBacks'
import { Summary } from '../Payouts/components/Summary'
import { ManualCompensations } from '../Payouts/components/ManualCompensations'
import { BToBInvoices } from '../Payouts/components/BToBInvoice'
import { WorkOrders } from '../Payouts/components/WorkOrders'
import { LoanInfo } from '../Payouts/components/LoanInfo'
import { TechnicianContext } from 'GlobalStore'
import { Reimbursements } from '../Payouts/components/Reimbursements'

const PAYOUT_QUERY = gql`
  query payout($id: ID!) {
    payout(id: $id) {
      id
      firstName
      lastName
      businessName
      contractorIsBusiness
      technicianId
      quotePayouts
      quotePayoutsSum
      totalHourlyPay
      totalUnpaidTimeOffHours
      totalSickTimeOffHours
      totalPaidTimeOffHours
      minimumHourlyPayAdjustment
      overtimePayAdjustment
      overtimePay
      reimbursements {
        id
        createdByType
        createdById
        createdAt
        amount
        photoUrls
        payoutId
        note
      }
      reimbursementsSum
      loanBalanceBeforeThisPayout
      loanBalanceAfterThisPayout
      loanDeductionAmount
      otherLoanBalanceBeforeThisPayout
      otherLoanBalanceAfterThisPayout
      otherLoanDeductionAmount
      createdAt
      einLastFour
      ssnLastFour
      finalCheckAmount
      note
      manualCompensationsSum
      commissionChargeBacksSum
      commissionChargeBacks {
        id
        amount
        note
        quote {
          id
          lead {
            make
            makeShortName
            model
            modelShortName
            year
          }
          jobs {
            id
            startDatetime
            endDatetime
          }
        }
        worklog {
          id
          startedAt
          completedAt
          job {
            id
            startDatetime
            endDatetime
          }
        }
      }
      timeCardsSum
      timeCards {
        id
        timeCardDate
        createdAt
        workHours
        paidTravelHours
        unpaidTravelHours
        jobCount
        payoutData
        timeLogs {
          id
          createdAt
          startedAt
          completedAt
          type
          isPaid
          jobId
          travelDistance
          totalHours
          payoutData
          selectedForCommissionPayment
          job {
            id
            lead {
              name
              year
              make
              model
            }
          }
        }
      }
      manualCompensations {
        quoteId
        amount
        note
        createdAt
        quote {
          id
          lead {
            year
            makeShortName
            modelShortName
          }
        }
      }
      quotes {
        id
        payoutIds
        lead {
          year
          makeShortName
          modelShortName
        }
        jobs {
          id
          isActive
          startDatetime
        }
      }
      bToBInvoicesSum
      bToBInvoices {
        id
        year
        make
        model
        vin
        repairOrder
        payoutData
      }
    }
  }
`

const Payout = () => {
  const history = useHistory()
  const theme = useTheme()
  const { payoutId } = useParams()
  const classes = useStyles(theme)
  const [technician] = useContext(TechnicianContext)

  const { loading, error, data, refetch } = useQuery(PAYOUT_QUERY, {
    variables: {
      id: payoutId,
    },
  })

  if (loading) return <Loading />
  if (error) return <div>Error!</div>

  const payout = keysToCamel(data.payout)
  const invoiceFilename = DateTime.fromJSDate(new Date(payout.createdAt)).toFormat('yyyy-LL-dd') + '-payout.pdf'
  const quotes = payout.quotes
    ? payout.quotes.map(quote => {
        return { id: quote.id, jobs: quote.jobs, lead: quote.lead }
      })
    : [{ id: null, jobs: [] }]

  const timeCards = payout?.timeCards || []
  const chargeBacks = payout?.commissionChargeBacks || []

  const sortedWorkOrders = payout.quotes
    ? payout.quotePayouts
        .reduce((acc, q) => {
          const quote = quotes.filter(quote => quote.id === q.quoteId)[0]
          return [
            ...acc,
            keysToCamel({
              ...q,
              id: q.quoteId,
              jobs: quote.jobs,
              mostRecentJob: quote.jobs[quote.jobs.length - 1],
              lead: quote.lead,
            }),
          ]
        }, [])
        .sort((a, b) => (a.mostRecentJob.startDatetime < b.mostRecentJob.startDatetime ? 1 : -1))
    : []

  const sortedManualCompensations = [...payout.manualCompensations.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))]
  const sortedReimbursements = [...payout.reimbursements.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))]

  const showLoanInfo =
    payout.loanBalanceBeforeThisPayout !== 0 ||
    payout.loanDeductionAmount !== 0 ||
    payout.otherLoanDeductionAmount !== 0

  return (
    <div style={{ marginBottom: '70px' }}>
      <Container className={classes.fixedBottomContainer} style={{ display: 'flex' }}>
        <div style={{ marginLeft: '8px' }}>
          <Button variant='outlined'>
            <PDFDownloadLink
              style={{ color: 'unset', textDecoration: 'none' }}
              document={<PayoutPDF payout={{ ...payout, technician, sortedWorkOrders, sortedManualCompensations }} />}
              fileName={invoiceFilename}
            >
              {({ blob, url, loading, error }) => {
                if (error) {
                  return <div>Error!</div>
                }
                return loading ? 'Generating PDF...' : 'Download as PDF'
              }}
            </PDFDownloadLink>
          </Button>
        </div>
      </Container>
      <Grid container>
        <IconButton
          aria-label='back'
          style={{ position: 'absolute' }}
          onClick={() => {
            history.goBack()
          }}
        >
          <ArrowBackIosNewOutlinedIcon />
        </IconButton>
        <Grid item xs={12}>
          <Grid container style={{ padding: '1rem 1rem 1.5rem 1rem', textAlign: 'center' }}>
            <Grid item xs={12}>
              <span style={{ color: theme.palette.text.secondary, fontSize: '.9rem', display: 'block' }}>
                {' '}
                Sum of All Transactions Below{' '}
              </span>
            </Grid>
            <Grid item xs={12}>
              <span style={{ fontWeight: 600, color: '#30A92E', fontSize: '3rem' }}>
                <Dollars
                  color={theme.palette.mode === 'dark' ? '#98fb98' : '#30A92E'}
                  value={payout.finalCheckAmount}
                />
              </span>
            </Grid>
            <Grid item xs={12}>
              <div style={{ margin: '.25rem 0' }}>Payout Date: {formatFullDate(payout.createdAt)}</div>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.sectionHeader}>
            <div style={{ color: theme.mode === 'dark' ? '#fff' : '#373845', fontSize: '1.125rem' }}> Summary </div>
          </Grid>

          <Summary payout={payout} />

          {showLoanInfo && <LoanInfo payout={payout} />}
          <TimeCards timeCards={timeCards} />

          {timeCards?.length === 0 && <WorkOrders sortedWorkOrders={sortedWorkOrders} />}

          <BToBInvoices payout={payout} />

          <ManualCompensations sortedManualCompensations={sortedManualCompensations} />
          <Reimbursements sortedReimbursements={sortedReimbursements} />

          <ChargeBacks chargeBacks={chargeBacks} />
        </Grid>
      </Grid>
    </div>
  )
}

export default Payout
