import React, { useContext, useState } from 'react'
import { useChannel } from '@ericlathrop/phoenix-js-react-hooks'
import { UserContext } from './UserStore'

export const TechnicianChannelContext = React.createContext()

const TechnicianChannelProvider = ({children}) => {
  const [user] = useContext(UserContext)

  const channel = useChannel(`technician:${user.id}`, undefined, () => {})

  return (
    <TechnicianChannelContext.Provider value={channel}>
      {children}
    </TechnicianChannelContext.Provider>
  )
}

export default TechnicianChannelProvider
