import React, { useState, useContext, useEffect } from 'react'
import { UserContext } from './UserStore'
import { useQuery, gql } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import { DateTime } from 'luxon'
import { styled } from '@mui/material/styles'
import { Paper, Grid, ButtonGroup, Button, useTheme } from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight, Info } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import Loading from 'presentation/components/Loading'
import { nowAsIfInUtc, pluralizeText } from 'tools'
import { TechnicianContext } from 'GlobalStore'
import { useEventHandler } from '@ericlathrop/phoenix-js-react-hooks'
import { TechnicianChannelContext } from 'TechnicianChannelStore'

const JOBS_QUERY = gql`
  query activeJobs($technicianId: ID!, $startDatetime: ISO8601DateTime!, $endDatetime: ISO8601DateTime!) {
    activeJobs(technicianId: $technicianId, startDatetime: $startDatetime, endDatetime: $endDatetime) {
      id
      startDatetime
      endDatetime
      isActive
      parts {
        id
        isActive
        returnInitiatedAt
        returnedToVendorAt
        coreRefundInitiatedAt
        coreReturnedToVendorAt
        coreRefundExpectedAmount
        orderPlacedAt
        partVendorId
        removedFromQuoteAt
        pickupStatus
        status
      }
      lead {
        id
        name
      }
    }
  }
`

const BTOB_JOBS_QUERY = gql`
  query BToBJobs($technicianId: String, $startDatetime: String, $endDatetime: String) {
    bToBJobs(technicianId: $technicianId, startDatetime: $startDatetime, endDatetime: $endDatetime) {
      id
      createdAt
      startDatetime
      endDatetime
      updatedAt
      schedulerId
      dealer {
        id
        businessName
      }
      technician {
        id
        firstName
        lastName
      }
      createdBy {
        id
        firstName
        lastName
      }
      updatedBy {
        id
        firstName
        lastName
      }
    }
  }
`

const JOBS_SCHEDULERS_QUERY = gql`
  query BToBJobSchedulers($technicianId: String, $recurrenceStartsAt: String!, $recurrenceEndsAt: String!) {
    bToBJobSchedulers(
      technicianId: $technicianId
      recurrenceStartsAt: $recurrenceStartsAt
      recurrenceEndsAt: $recurrenceEndsAt
    ) {
      id
      createdAt
      startDatetime
      endDatetime
      recurrencePeriod
      recurrencePattern
      recurrenceStartsAt
      recurrenceEndsAt
      excludedDays
      dealer {
        id
        businessName
      }
      technician {
        id
        firstName
        lastName
      }
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  currentTimerange: {
    padding: '0 0 .75rem .5rem',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Open Sans',
    color: theme.mode === 'dark' ? '#fff' : '#373845',
    fontWeight: 600,
  },
  notificationCard: {
    border: '2px solid #467BC5bf',
    padding: '.5rem',
    marginBottom: '1rem',
    color: '#555',
    '& span': {
      fontFamily: 'Roboto',
      color: theme.mode === 'dark' ? '#fff' : '#373845',
      padding: '.75rem .25rem .25rem .25rem',
      display: 'block',
      fontWeight: 400,
    },
  },
}))

const AppointmentCard = styled(props => {
  const { expand, ...other } = props
  return <Paper {...other} />
})(({ theme, expand }) => ({
  '&.MuiPaper-root': {
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
    border: theme.mode === 'dark' ? '#fff' : '#373845',
    padding: '1rem',
    marginBottom: '1rem',
    color: '#555',
    '& p': {
      display: 'block',
      fontFamily: 'Open Sans',
      color: theme.mode === 'dark' ? '#fff' : '#373845',
      fontWeight: 600,
      paddingBottom: '0px',
      margin: '0px',
    },
    '& span': {
      fontFamily: 'Open Sans',
      color: theme.mode === 'dark' ? '#fff' : '#373845',
    },
  },
  '&.appointmentCardPast': {
    backgroundColor: 'rgb(187, 187, 187)',
    opacity: '0.35',
  },
  '&.appointmentCardHasPartsToReturn': {
    backgroundColor: '#ff00ff4f',
  },
}))

const Home = () => {
  const [user] = useContext(UserContext)
  const nowInUtc = nowAsIfInUtc()
  const [technician] = useContext(TechnicianContext)
  const channel = useContext(TechnicianChannelContext)

  const [startDatetime, setStartDatetime] = useState(() => {
    const memoizedISO = localStorage.getItem('memoizedStartDatetime')
    const clickedAwayAt = localStorage.getItem('clickedAwayAt')

    return (
      (memoizedISO &&
        clickedAwayAt &&
        DateTime.fromISO(clickedAwayAt) > DateTime.local().minus({ seconds: 30 }) &&
        DateTime.fromISO(memoizedISO, { zone: 'utc' })) ||
      nowInUtc.startOf('week')
    )
  })

  localStorage.setItem('memoizedStartDatetime', startDatetime.toISO())

  const theme = useTheme()
  const classes = useStyles(theme)
  const textColor =
    theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.mode === 'dark' ? '#fff' : '#373845'

  const goBackWeek = () => setStartDatetime(prev => prev.minus({ days: 7 }))
  const goForwardWeek = () => setStartDatetime(prev => prev.plus({ days: 7 }))
  const handleClickToday = () => setStartDatetime(nowInUtc.startOf('week'))

  const { loading, error, data, refetch } = useQuery(JOBS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      technicianId: user.id,
      startDatetime: startDatetime.toISO(),
      endDatetime: startDatetime.endOf('week').toISO(),
    },
  })

  const {
    error: b2bJobsError,
    loading: loadingB2BJobs,
    data: b2bJobsData,
  } = useQuery(BTOB_JOBS_QUERY, {
    variables: {
      technicianId: technician.id,
      startDatetime: startDatetime,
      endDatetime: startDatetime.endOf('week').toISO(),
    },
  })

  const {
    error: b2bJobSchedulersError,
    loading: loadingB2BJobSchedulers,
    data: b2bJobSchedulersData,
  } = useQuery(JOBS_SCHEDULERS_QUERY, {
    variables: {
      technicianId: technician.id,
      recurrenceEndsAt: DateTime.now().endOf('day'),
      recurrenceStartsAt: DateTime.now().endOf('month').endOf('week').endOf('day'),
    },
  })

  useEventHandler(channel, 'job_created', refetch)
  useEventHandler(channel, 'job_updated', refetch)

  if (loading || loadingB2BJobs || loadingB2BJobSchedulers) return <Loading />
  if (error || b2bJobSchedulersError || b2bJobsError) return <div>Error</div>

  const jobs = data.activeJobs
  const bToBJobs = b2bJobsData?.bToBJobs || []
  const bToBJobSchedulers = b2bJobSchedulersData?.bToBJobSchedulers || []

  const getSchedulersForDay = date => {
    const weeklySchedulersForDay = bToBJobSchedulers.filter(
      scheduler =>
        scheduler.recurrencePeriod === 'week' &&
        scheduler.recurrencePattern.includes(date.toFormat('cccc').toLowerCase()) &&
        DateTime.fromISO(scheduler.recurrenceStartsAt) <= date.endOf('day') &&
        !scheduler.excludedDays.includes(date.toFormat('MM-dd-yy')) &&
        (scheduler.recurrenceEndsAt && DateTime.fromISO(scheduler.recurrenceEndsAt) <= date ? false : true) &&
        !bToBJobs.find(
          job =>
            job.schedulerId === scheduler.id &&
            DateTime.fromISO(job.startDatetime).toFormat('MM-dd-yy') === date.toFormat('MM-dd-yy')
        )
    )

    const monthlySchedulersForDay = bToBJobSchedulers.filter(
      scheduler =>
        scheduler.recurrencePeriod === 'month' &&
        scheduler.recurrencePattern.includes(date.toFormat('dd')) &&
        !scheduler.excludedDays.includes(date.toFormat('MM-dd-yy')) &&
        (scheduler.recurrenceStartsAt && DateTime.fromISO(scheduler.recurrenceStartsAt) >= date.endOf('month')
          ? false
          : true) &&
        (scheduler.recurrenceEndsAt && DateTime.fromISO(scheduler.recurrenceEndsAt) <= date ? false : true) &&
        !bToBJobs.find(
          job =>
            job.schedulerId === scheduler.id &&
            DateTime.fromISO(job.startDatetime).toFormat('MM-dd-yy') === date.toFormat('MM-dd-yy')
        )
    )

    const yearlySchedulersForDay = bToBJobSchedulers.filter(
      scheduler =>
        scheduler.recurrencePeriod === 'year' &&
        scheduler.recurrencePattern.includes(date.toFormat('MM-dd')) &&
        !scheduler.excludedDays.includes(date.toFormat('MM-dd-yy')) &&
        (scheduler.recurrenceStartsAt && DateTime.fromISO(scheduler.recurrenceStartsAt) >= date.endOf('month')
          ? false
          : true) &&
        (scheduler.recurrenceEndsAt && DateTime.fromISO(scheduler.recurrenceEndsAt) <= date ? false : true) &&
        !bToBJobs.find(
          job =>
            job.schedulerId === scheduler.id &&
            DateTime.fromISO(job.startDatetime).toFormat('MM-dd-yy') === date.toFormat('MM-dd-yy')
        )
    )

    return weeklySchedulersForDay.concat(monthlySchedulersForDay).concat(yearlySchedulersForDay)
  }

  const visitNumberForJob = ({ job, jobs }) => {
    const jobsOfLeadId = jobs
      .filter(j => j.lead.id === job.lead.id)
      .filter(j => j.isActive)
      .sort((a, b) => (DateTime.fromISO(a.startDatetime) > DateTime.fromISO(b.startDatetime) ? 1 : -1))

    return jobsOfLeadId.indexOf(job) + 1
  }

  const calendarEvents = jobs
    .map(job => {
      const visitNumberForThisJob = visitNumberForJob({ job, jobs })
      const activePartCountByVendorId = job.parts.reduce((acc, part) => {
        if (['canceled', 'refunded', 'removed', 'returned'].includes(part.status)) {
          return acc
        }
        acc[part.partVendorId] = acc[part.partVendorId] || 0
        acc[part.partVendorId]++
        return acc
      }, {})

      const techHasInterestInPart = part => !part.removedFromQuoteAt || part.pickupStatus === 'picked_up'
      const countOfActiveParts = job.parts.filter(
        part => !['canceled', 'refunded'].includes(part.status) && techHasInterestInPart(part)
      ).length

      const title = `${job.lead.name}${visitNumberForThisJob > 1 ? `\nvisit: #${visitNumberForThisJob}` : ''}${
        countOfActiveParts > 0 ? `\nparts: ${countOfActiveParts}` : ''
      }`

      const partsThatNeedToBeReturnedCount = job.parts.reduce((sum, part) => {
        if (
          (part.returnInitiatedAt && !part.returnedToVendorAt) ||
          (part.coreRefundInitiatedAt && !part.coreReturnedToVendorAt)
        ) {
          sum++
        }
        return sum
      }, 0)
      const partsWithCoreRefund = job.parts.reduce(
        (sum, part) => sum + (part.coreRefundExpectedAmount && !part.coreReturnedToVendorAt ? 1 : 0),
        0
      )

      return {
        id: job.id,
        title,
        start: job.startDatetime,
        end: job.endDatetime,
        partsThatNeedToBeReturnedCount: partsThatNeedToBeReturnedCount,
        partsWithCoreRefund: partsWithCoreRefund,
        url: `/jobs/${job.id}`,
        type: 'job',
      }
    })
    .sort((a, b) => (a.start > b.start ? 1 : -1))

  const fullJobsList = [...Array(7).keys()].map(dayIndex => {
    const thisDateTime = startDatetime.startOf('week').plus({ days: dayIndex })

    const jobsOnThisDay = calendarEvents.filter(job =>
      DateTime.fromISO(job.start, { setZone: true }).hasSame(thisDateTime, 'day')
    )

    const bToBJobsOnThisDay =
      bToBJobs.filter(job => DateTime.fromISO(job.startDatetime, { zone: 'utc' }).hasSame(thisDateTime, 'day')) || []

    const bToBJobSchedulerssOnThisDay = getSchedulersForDay(thisDateTime)

    return {
      dateTime: thisDateTime,
      jobs: jobsOnThisDay,
      bToBJobs: bToBJobsOnThisDay.map(item => ({
        id: item.id,
        title: item.dealer.businessName,
        start: item.startDatetime,
        end: item.endDatetime,
        partsThatNeedToBeReturnedCount: 0,
        partsWithCoreRefund: 0,
        url: `/b-to-b-invoices`,
      })),
      bToBJobSchedulers: bToBJobSchedulerssOnThisDay.map(item => ({
        id: item.id,
        title: item.dealer.businessName,
        start: item.startDatetime,
        end: item.endDatetime,
        partsThatNeedToBeReturnedCount: 0,
        partsWithCoreRefund: 0,
        url: `/b-to-b-invoices`,
      })),
    }
  })

  const thereAreNoJobs = calendarEvents.length === 0 && bToBJobSchedulers.length === 0 && bToBJobs.length === 0

  return (
    <>
      <Grid
        container
        style={{
          position: 'fixed',
          top: '55px',
          margin: '0 -8px',
          padding: '16px 8px 0',
          backgroundColor: '#F8FBFF',
          zIndex: '1',
        }}
      >
        <Grid item xs={6} className={classes.currentTimerange}>
          {startDatetime.toFormat('MMM d')} &#8211;&nbsp;
          {startDatetime.endOf('week').toFormat('MMM d, y')}
        </Grid>

        <Grid
          item
          xs={6}
          style={{ padding: '0rem 0 1rem 0', alignItems: 'center', display: 'flex', justifyContent: 'flex-end' }}
          align='right'
        >
          <Button onClick={handleClickToday} style={{ marginRight: '.5rem' }} variant='contained'>
            THIS WEEK
          </Button>
          <ButtonGroup variant='contained'>
            <Button onClick={goBackWeek}>
              {' '}
              <KeyboardArrowLeft />{' '}
            </Button>
            <Button onClick={goForwardWeek}>
              {' '}
              <KeyboardArrowRight />{' '}
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          marginTop: '46px',
        }}
      >
        <Grid item xs={12}>
          {technician.notifications?.map(item => (
            <Paper key={item} className={classes.notificationCard}>
              <Grid container>
                <Grid item xs={1} sx={{ maxWidth: '50px' }}>
                  <Info sx={{ mt: '.25rem', color: '#467BC5', mb: '.25rem' }} />
                </Grid>
                <Grid item xs={11} sx={{ pl: '.5rem', mt: '.25rem', fontWeight: 600, color: '#467BC5' }}>
                  Important Message
                </Grid>
                <Grid item xs={12} sx={{ borderTop: '2px solid #467BC580' }}>
                  <span>{item}</span>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Grid>

        <Grid item xs={12} style={{ padding: '1rem 0 0 0' }}>
          {!thereAreNoJobs ? (
            fullJobsList.map((event, index) => {
              const isOldJob = DateTime.fromISO(event.start) < nowInUtc.startOf('day')

              return (
                <>
                  {event.jobs.map(item => (
                    <JobCard date={event.dateTime} isOldJob={isOldJob} key={item.id} event={item} />
                  ))}
                  {event.bToBJobs.map(item => (
                    <JobCard date={event.dateTime} isOldJob={isOldJob} key={item.id} event={item} />
                  ))}
                  {event.bToBJobSchedulers.map(item => (
                    <JobCard date={event.dateTime} isOldJob={isOldJob} key={item.id} event={item} />
                  ))}
                </>
              )
            })
          ) : (
            <div style={{ minHeight: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              No jobs scheduled
            </div>
          )}
        </Grid>
      </Grid>
    </>
  )
}

const JobCard = ({ event, isOldJob, date }) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const textColor =
    theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.mode === 'dark' ? '#fff' : '#373845'
  const history = useHistory()

  return (
    <AppointmentCard
      onClick={() => {
        localStorage.setItem('clickedAwayAt', DateTime.local().toISO())
        history.push(event.url)
      }}
      className={`${
        isOldJob && !event.partsThatNeedToBeReturnedCount && !event.partsWithCoreRefund && 'appointmentCardPast'
      } ${(event.partsThatNeedToBeReturnedCount || event.partsWithCoreRefund) && 'appointmentCardHasPartsToReturn'}`}
      elevation={isOldJob ? 0 : 1}
    >
      <Grid container>
        <Grid item xs={10}>
          <span
            style={{
              paddingBottom: '.5rem',
              fontWeight: 600,
              color: theme.mode === 'dark' ? '#fff' : '#373845',
            }}
          >
            {' '}
            {DateTime.fromISO(date).plus({ days: 1 }).toFormat('DDDD')}
          </span>
        </Grid>
        <Grid item xs={2} align='right' onClick={() => history.push(event.url)}>
          <KeyboardArrowRight
            style={{
              color: textColor,
              opacity: theme.palette.mode === 'dark' ? 0.75 : 0.25,
            }}
          />
        </Grid>
      </Grid>

      <span style={{ color: textColor, fontSize: '.9rem' }}>
        {DateTime.fromISO(event.start, { zone: 'utc' }).toFormat('t')} -{' '}
        {DateTime.fromISO(event.end, { zone: 'utc' }).toFormat('t')}
      </span>
      <hr
        style={{
          margin: '1rem 0',
          border: '0px',
          backgroundColor: textColor,
          opacity: theme.palette.mode === 'dark' ? 0.75 : 0.25,
          height: '2px',
        }}
      />
      <p> {event.title} </p>
      {event.partsThatNeedToBeReturnedCount > 0 && (
        <p>
          {event.partsThatNeedToBeReturnedCount} {pluralizeText('part', event.partsThatNeedToBeReturnedCount)} to return
        </p>
      )}
      {event.partsThatNeedToBeReturnedCount === 0 && event.partsWithCoreRefund > 0 && (
        <p>
          {event.partsWithCoreRefund} {pluralizeText('part', event.partsWithCoreRefund)} with core charge
        </p>
      )}
    </AppointmentCard>
  )
}

export default Home
