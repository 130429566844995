import { useHistory } from 'react-router-dom'
import { Stack, Grid, Paper, useTheme } from '@mui/material'
import { KeyboardArrowRight } from '@mui/icons-material'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  card: {
    border: '1px solid #dddddd64',
    margin: '.25rem 0',
    padding: '.5rem',
    fontFamily: 'Open Sans',
    color: theme.mode === 'dark' ? '#fff' : '#373845',
    fontWeight: 600,
  },
  rightArrow: {
    color: theme.mode === 'dark' ? '#fff' : '#373845',
    opacity: 0.5,
  },
}))

const Index = ({ items }) => {
  const history = useHistory()
  const classes = useStyles(useTheme())

  return (
    <Stack>
      <Paper className={classes.card} onClick={() => history.push('/supplies-orders/new')}>
        <Grid container>
          <Grid item xs={10}>
            New Order
          </Grid>
          <Grid item xs={2} align='right'>
            <KeyboardArrowRight className={classes.rightArrow} />
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.card} onClick={() => history.push('/supplies-orders/open')}>
        <Grid container>
          <Grid item xs={10}>
            Open Orders
          </Grid>
          <Grid item xs={2} align='right'>
            <KeyboardArrowRight className={classes.rightArrow} />
          </Grid>
        </Grid>
      </Paper>
    </Stack>
  )
}

export default Index
