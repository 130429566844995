import { createTheme } from '@mui/material/styles'


export const theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#F8FBFF',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(18px)',
          background: 'linear-gradient(88.85deg, #4295E0 0.2%, rgba(13, 51, 147, 0.75) 100%)',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: '#F8FBFF',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: '#4295E0',
          padding: '.5rem',
        },
      },
    },
  },
})

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#191a27',
      paper: '#222430',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(18px)',
          background: 'linear-gradient(88.85deg, #4295E0 0.2%, rgba(13, 51, 147, 0.75) 100%)',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: '#191a27',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {},
        // @ts-ignore
        '& textarea': {
          color: '#ffffff !important',
          backgroundColor: 'red',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // backgroundColor: '#4295E0',
          padding: '.5rem',
        },
      },
    },
  },
})
