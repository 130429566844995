import { useQuery, gql } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import {
  Grid,
  Paper,
  Box,
  IconButton,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'

const QUERY = gql`
  query suppliesOrder($id: String!) {
    suppliesOrder(id: $id) {
      id
      createdAt
      itemIds
      placedAt
    }
  }
`

const Title = styled('span')(({ theme }) => ({
  margin: '.25rem 0',
  padding: '.5rem',
  fontFamily: 'Open Sans',
  color: theme.mode === 'dark' ? '#fff' : '#373845',
  fontWeight: 600,
}))

const Show = ({ items, suppliesOrderId }) => {
  const history = useHistory()

  const { loading, error, data } = useQuery(QUERY, {
    variables: { id: suppliesOrderId },
  })

  if (error) return <div>Error!</div>
  if (loading || !items.length) return <div>Loading...</div>

  const order = data.suppliesOrder
  const itemsMap = {}
  items.forEach(suppliesOrderItem => {
    itemsMap[suppliesOrderItem.id] = suppliesOrderItem
  })

  return (
    <>
      <Box sx={{ position: 'relative', textAlign: 'center' }}>
        <Grid container>
          <Grid item xs={1}>
            <IconButton
              aria-label='back'
              sx={{ float: 'left' }}
              onClick={() => {
                history.goBack()
              }}
            >
              <ArrowBackIosNewOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10}>
            <Title>Items</Title>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell align='right'>type</TableCell>
              <TableCell align='right'>description</TableCell>
              <TableCell align='right'>size</TableCell>
              <TableCell align='right'>quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.itemIds.map(itemId => (
              <TableRow key={itemId}>
                <TableCell component='th'>{itemId}</TableCell>
                <TableCell align='right'>{itemsMap[itemId].type}</TableCell>
                <TableCell align='right'>{itemsMap[itemId].description}</TableCell>
                <TableCell align='right'>{itemsMap[itemId].size}</TableCell>
                <TableCell align='right'>{itemsMap[itemId].quantity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default Show
