import { useState } from 'react'
import { Container, Button, ButtonGroup, Tabs, Tab } from '@mui/material'
import InvoicesIndex from '../BToBInvoice/Index'
import WorkOrdersIndex from '../WorkOrder/Index'
import { useLocalStorage } from 'util/hooks/useLocalStorage'

const Index = () => {
  const [currentTab, setCurrentTab] = useLocalStorage('b2btab', 'invoices')

  return (
    <Container maxWidth='sm' disableGutters>
      {/* <Tabs value={currentTab} onChange={(e, v) => setCurrentTab(v)} variant='fullWidth'>
        <Tab fullWidth value='invoices' label='Invoices' />
        <Tab fullWidth value='workOrders' label='Part Orders' />
      </Tabs> */}
      <ButtonGroup fullWidth sx={{ mb: '1rem' }}>
        <Button
          onClick={() => setCurrentTab('invoices')}
          variant={currentTab === 'invoices' ? 'contained' : 'outlined'}
        >
          Invoices
        </Button>
        <Button
          onClick={() => setCurrentTab('workOrders')}
          variant={currentTab === 'workOrders' ? 'contained' : 'outlined'}
        >
          Work Orders
        </Button>
      </ButtonGroup>

      {currentTab === 'invoices' && <InvoicesIndex />}
      {currentTab === 'workOrders' && <WorkOrdersIndex />}
    </Container>
  )
}

export default Index
