import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Skeleton from '@mui/material/Skeleton'

const LoadingIndicator = ({ numberOfCards, message, height }) => {
  return (
    <>
      <p style={{ fontSize: '1.5rem', marginBottom: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
        <CircularProgress style={{ marginBottom: '1rem' }} />
        {message}
      </p>
      {((numberOfCards ? [1, 2, 3, 4] : [0]).map((e, i) => {
        return <Skeleton key={i} width='100%' height={height || '350px'} style={{ transform: 'scale(1)', visibility: 'visible' }} />
      }))}
    </>
  )
}

export default LoadingIndicator
