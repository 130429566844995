import React from 'react'
import { useQuery, gql } from '@apollo/client'

const VEHICLE_QUERY = gql`
  query vehicle($year: Int!, $makeShortName: String!, $modelShortName: String!) {
    vehicle(year: $year, makeShortName: $makeShortName, modelShortName: $modelShortName) {
      makePrettyName
      modelPrettyName
    }
  }
`

const MakeModelYear = ({ year, makeShortName, modelShortName }) => {
  const { loading, error, data } = useQuery(VEHICLE_QUERY, {
    variables: { year, makeShortName, modelShortName }
  })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const vehicle = data.vehicle

  return <b>{year} - {vehicle ? vehicle.makePrettyName : makeShortName} - {vehicle ? vehicle.modelPrettyName : modelShortName}</b>
}

export default MakeModelYear
