import { useEffect, useState } from 'react'
import { Table, TableCell, TableHead, TableRow } from '@mui/material'
import InlinePartEditorGrid from './InlinePartEditorGrid'

const PartsTable = ({ parts, workOrder, editingDisabled, refetch }) => {
  const [currentEditingPart, setCurrentEditingPart] = useState('')

  const sortedParts = workOrder?.parts
    ?.reduce((acc, item) => {
      const found = parts.find(part => part.id === item.id)
      if (found) {
        acc.push(found)
      }
      return acc
    }, [])
    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    .map(o => parts.find(part => part.id === o.id))

  return (
    <Table size='small' sx={{ borderTop: '1px solid #ddd' }}>
      {sortedParts.length > 0 && (
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
      )}
      {sortedParts.map(part => (
        <InlinePartEditorGrid
          key={part.id}
          workOrder={workOrder}
          part={part}
          currentEditingPart={currentEditingPart}
          setCurrentEditingPart={setCurrentEditingPart}
          editingDisabled={editingDisabled}
          refetch={refetch}
        />
      ))}
    </Table>
  )
}

export default PartsTable
