import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Grid, IconButton, Input, Stack, Paper, Popover, Collapse } from '@mui/material'
import { styled } from '@mui/material/styles'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'

import { UserContext } from 'UserStore'

import { bearerTokenHeaders } from 'tools'

const classes = {
  smallButton: {
    fontSize: '1.25rem',
    height: '1rem',
    width: '1rem',
    marginTop: '.5rem',
    padding: '.75rem',
    borderRadius: '1rem',
    minWidth: 'initial',
    textTransform: 'initial',
  },
}

const styles = {
  textAlignLeft: { textAlign: 'left' },
  textAlignRight: { textAlign: 'right' },
}

const Badge = styled('span')(({ theme }) => ({
  backgroundColor: theme.components.MuiButton.styleOverrides.containedPrimary.backgroundColor,
  color: '#fff',
  marginLeft: '0.5rem',
  padding: '0.25rem 0.5rem',
  borderRadius: '2rem',
}))

const AddButton = styled(props => (
  <Button cy-data='add-supplies-order-button' color='success' variant='contained' {...props}>
    +
  </Button>
))(({ theme }) => classes.smallButton)

const RemoveButton = styled(props => (
  <Button color='error' variant='contained' {...props}>
    &times;
  </Button>
))(({ theme }) => classes.smallButton)

const StickyTopPaper = styled(Paper)(({ theme }) => ({
  position: 'sticky',
  top: '60px',
  padding: '0.5rem',
  zIndex: 1,
  borderBottom: '1px solid ' + theme.palette.mode === 'dark' ? '#000' : '#bbb',
}))

const Cart = styled(Paper)(({ theme }) => ({
  minHeight: '21rem',
  minWidth: '21rem',
  display: 'flex',
  flexDirection: 'column',
  fontSize: '0.8125rem',
}))
const CartContainer = styled('div')(({ theme }) => ({
  flex: 1,
  maxHeight: '27rem',
  overflowY: 'auto',
}))
const CartRow = styled(Grid)(({ theme }) => ({
  borderBottom: '1px solid grey',
  textAlign: 'right',
  padding: '0.5rem',
}))

const filterOrderItems = (items, search) => {
  const regex = RegExp(search, 'i')
  const filtered = []
  items.forEach(item => {
    if (item.id.match(regex) || item.type.match(regex) || item.description.match(regex)) {
      filtered.push(item)
    }
  })
  return filtered
}

const New = ({ items }) => {
  const [user] = useContext(UserContext)
  const history = useHistory()
  const [order, setOrder] = useState({})
  const [search, setSearch] = useState('')
  const [popover, setPopover] = useState(null)

  const handleToggleCart = event => setPopover(popover ? null : event.currentTarget)
  const handleCloseCart = () => setPopover(null)
  const handleSearch = event => setSearch(event.target.value)

  const handleAddItemListener = itemId => () => {
    const buff = { ...order }
    buff[itemId] = true
    setOrder(buff)
  }
  const handleRemoveItemListener = itemId => () => {
    const buff = { ...order }
    delete buff[itemId]
    if (Object.keys(buff).length < 1) setPopover(null)
    setOrder(buff)
  }

  const handleClickSubmit = () => {
    if (!window.confirm('send order to manager now?')) return false

    fetch(`https://${process.env.REACT_APP_API_HOST}/create_supplies_order`, {
      method: 'POST',
      headers: bearerTokenHeaders(user.token),
      body: JSON.stringify({
        technicianId: user.id,
        itemIds: Object.keys(order),
      }),
    })
      .then(response => {
        if (response.ok) {
          history.push('/supplies-orders')
        } else {
          throw Error('error')
        }
      })
      .catch(e => {
        window.alert(e.message)
      })
  }

  const itemsSorted = [...items].sort((a, b) => (a.type > b.type ? 1 : a.type < b.type ? -1 : 0))
  const itemsFiltered = filterOrderItems(itemsSorted, search)

  const cartPopoverId = popover ? 'cart-popover' : undefined
  const orderLength = Object.keys(order).length

  return (
    <div style={{ fontSize: '0.8125rem' }}>
      <StickyTopPaper>
        <Grid container>
          <Grid item xs={2}>
            <IconButton
              aria-label='back'
              onClick={() => {
                history.goBack()
              }}
            >
              <ArrowBackIosNewOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <Input value={search} placeholder='Search' onChange={handleSearch} />
          </Grid>
          <Grid item xs={4} sx={styles.textAlignRight}>
            <Button
              cy-data='supplies-order-cart-button'
              variant='outlined'
              disabled={!orderLength}
              aria-describedby={cartPopoverId}
              onClick={handleToggleCart}
            >
              <ShoppingCartIcon />
              <Collapse in={orderLength} orientation='horizontal' unmountOnExit>
                <Badge>{orderLength}</Badge>
              </Collapse>
            </Button>
            <Popover
              id={cartPopoverId}
              open={!!popover}
              anchorEl={popover}
              onClose={handleCloseCart}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Cart>
                <CartContainer>
                  {items.map(item => (
                    <Collapse key={item.id} in={order[item.id]} unmountOnExit>
                      <CartRow container>
                        <Grid item xs={4}>
                          {item.id}
                          <br />
                          {item.size} x{item.quantity}
                        </Grid>
                        <Grid item xs={7} sx={styles.textAlignRight}>
                          {item.type}
                          <br />
                          {item.description}
                        </Grid>
                        <Grid item xs={1} sx={styles.textAlignRight}>
                          <RemoveButton onClick={handleRemoveItemListener(item.id)} />
                        </Grid>
                      </CartRow>
                    </Collapse>
                  ))}
                </CartContainer>
                <div style={{ fontSize: '1rem', position: 'relative' }}>
                  <Button cy-data="submit-supplies-order-button" variant='contained' onClick={handleClickSubmit} sx={{ float: 'right' }}>
                    submit
                  </Button>
                  <span style={{ padding: '0.5em', position: 'absolute', bottom: 0 }}>{orderLength} item(s)</span>
                </div>
              </Cart>
            </Popover>
          </Grid>
        </Grid>
      </StickyTopPaper>

      <Stack sx={{ 'text-align': 'center', width: '100%' }}>
        <Grid container>
          <Grid item xs={6} sx={styles.textAlignLeft}>
            id, size, quantity
          </Grid>
          <Grid item xs={5} sx={styles.textAlignRight}>
            type, description
          </Grid>
          <Grid item xs={1} sx={styles.textAlignRight}>
            tap
          </Grid>
        </Grid>
        {itemsFiltered.map(item => (
          <Collapse key={item.id} in={!order[item.id]} unmountOnExit>
            <Paper sx={{ margin: '1px', padding: 1 }}>
              <Grid container>
                <Grid item xs={3}>
                  {item.id}
                  <br />
                  {item.size} x{item.quantity}
                </Grid>
                <Grid item xs={8} sx={styles.textAlignRight}>
                  {item.type}
                  <br />
                  {item.description}
                </Grid>
                <Grid item xs={1} sx={styles.textAlignRight}>
                  <AddButton onClick={handleAddItemListener(item.id)} />
                </Grid>
              </Grid>
            </Paper>
          </Collapse>
        ))}
      </Stack>
    </div>
  )
}

export default New
