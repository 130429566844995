import { Button, FormControlLabel, Checkbox, Typography, Container, Grid, Collapse, Snackbar } from '@mui/material'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import IconButton from '@mui/material/IconButton'
import { useState, useContext } from 'react'
import { TechnicianContext } from 'GlobalStore'
import { bearerTokenHeaders } from 'tools'
import { DateTime } from 'luxon'
import { Chat } from './Chat'
import { useHistory } from 'react-router-dom'
import { UserContext } from 'UserStore'
import { VehiclePicker } from './VehiclePicker'
import { VinPhoto } from './VinPhoto'
import { PostJobPhotos } from './PostJobPhotos'
import { PreJobPhotos } from './PreJobPhotos'
import { LineItemsSection } from './LineItems'
import PartsTable from 'presentation/pages/WorkOrder/components/PartsTable'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { CopyAllRounded } from '@mui/icons-material'
import ReferenceNumbers from './ReferenceNumbers'

const Invoice = ({ invoice, refetch }) => {
  const history = useHistory()
  const [user] = useContext(UserContext)
  const [technician] = useContext(TechnicianContext)
  const [toggleCompleteCheckboxDisabled, setToggleCompleteCheckboxDisabled] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const workOrder = invoice.workOrder

  const toggleComplete = completedStatus => {
    setToggleCompleteCheckboxDisabled(true)

    fetch(`https://${process.env.REACT_APP_API_HOST}/toggle_b_to_b_invoice_completed`, {
      method: 'POST',
      headers: bearerTokenHeaders(user.token),
      body: JSON.stringify({
        bToBInvoiceId: invoice.id,
        completed: completedStatus,
        technicianId: technician.id,
      }),
    })
      .then(res => {
        if (completedStatus) {
          setTimeout(() => history.push('/b-to-b-invoices'), 750)
        } else {
          setTimeout(() => setToggleCompleteCheckboxDisabled(false), 750)
        }
      })
      .catch(() => window.alert('There was an error creating a new invoice.'))
  }

  return (
    <Container disableGutters maxWidth='sm'>
      <Grid container spacing={2} style={{ position: 'relative' }}>
        <IconButton
          aria-label='back'
          style={{ position: 'absolute', left: '16px', top: '12px' }}
          onClick={() => {
            history.push('/b-to-b-invoices')
          }}
        >
          <ArrowBackIosNewOutlinedIcon />
        </IconButton>

        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography variant='h6' sx={{ textTransform: 'capitalize' }}>
            {invoice.businessName} Invoice <br />
            <span style={{ opacity: 0.75, fontSize: '14px' }}>
              Created: {DateTime.fromISO(invoice.createdAt).toLocaleString()}
            </span>
          </Typography>
        </Grid>

        {invoice.completedAt && (
          <Grid item xs={12}>
            <b
              style={{
                display: 'block',
                padding: '.25rem .5rem',
                textAlign: 'center',
                color: '#111',
                backgroundColor: 'palegreen',
              }}
            >
              COMPLETED {DateTime.fromISO(invoice.completedAt).toFormat('D hh:mm')}
            </b>
          </Grid>
        )}

        <VehiclePicker invoice={invoice} />
        <ReferenceNumbers invoice={invoice} refetch={refetch} />
        <VinPhoto invoice={invoice} />
        <PreJobPhotos invoice={invoice} />
        <LineItemsSection invoice={invoice} />

        {workOrder && (
          <Grid item xs={12}>
            <Grid container sx={{ border: '1px solid #ddd', borderRadius: '6px', background: '#fff' }}>
              <Grid
                item
                xs={12}
                sx={{
                  borderRadius: '6px 6px 0px 0px',
                  fontWeight: 700,
                  fontSize: '14px',
                  padding: '.5rem 1rem',
                  display: 'flex',
                  alignItems: 'center',
                  background: '#b5d9ff',
                }}
              >
                Parts
              </Grid>
              <Grid item xs={12}>
                <PartsTable editingDisabled={true} refetch={refetch} workOrder={workOrder} parts={workOrder.parts} />
              </Grid>
            </Grid>
          </Grid>
        )}

        <PostJobPhotos invoice={invoice} />
        <Chat contextId={invoice.id} contextType='BToBInvoice' />

        <Grid item xs={12} sx={{ mb: '1rem' }}>
          <CopyToClipboard style={{ cursor: 'pointer' }} text={`https://www.carbodylab.com/invoices/${invoice.id}`}>
            <Button fullWidth startIcon={<CopyAllRounded />} onClick={() => setSnackbarOpen(true)} variant='contained'>
              Copy Public Link
            </Button>
          </CopyToClipboard>
        </Grid>

        <Snackbar
          severity='success'
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={e => setSnackbarOpen(false)}
          message='Link Copied!'
        />
        <Collapse in sx={{ width: '100%' }}>
          <Grid container>
            <Grid
              item
              sx={{
                padding: '1rem 1rem 1rem 0rem',
                ml: 'auto',
                width: 'auto',
              }}
            >
              <FormControlLabel
                label='Completed'
                fullWidth
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  background: '#f7f7f7',
                  borderRadius: '8px',
                  pl: '.75rem',
                  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
                  border: '1px solid #ddd',
                  '&:active': {
                    background: '#eee',
                  },
                }}
                labelPlacement='start'
                componentsProps={{
                  typography: {
                    sx: { fontSize: '18px', ml: 'auto' },
                  },
                }}
                control={
                  <Checkbox
                    disabled={invoice.squareInvoiceId || invoice.payoutData || toggleCompleteCheckboxDisabled}
                    size='large'
                    checked={invoice.completedAt}
                    onChange={() => toggleComplete(!invoice.completedAt)}
                    sx={{ mr: '0px', ml: '1rem' }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Container>
  )
}

export default Invoice
