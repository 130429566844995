import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

const Loading = () =>
  <Box
    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    style={{ position: 'absolute', top: '0', left: '0', width: '100vw', height: '100vh', marginTop: '-10%' }}
  >
    <CircularProgress />
  </Box>

export default Loading
