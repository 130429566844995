import { Grid, Box, Typography, Paper, useTheme } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import { useQuery, gql } from '@apollo/client'
import { TechnicianContext } from 'GlobalStore'
import { useContext } from 'react'
import { KeyboardArrowRight } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import { Dollars } from '../../../tools'
import Loading from 'presentation/components/Loading'

// Do not show payouts before this date
const FIRST_PAYOUT_DATE = '2022-03-14'

const PAYOUTS_QUERY = gql`
  query payouts($technicianId: ID!, $startDatetime: ISO8601DateTime, $endDatetime: ISO8601DateTime, $filter: JSON) {
    payouts(technicianId: $technicianId, startDatetime: $startDatetime, endDatetime: $endDatetime, filter: $filter) {
      id
      firstName
      lastName
      businessName
      contractorIsBusiness
      technicianId
      quotePayouts
      quotePayoutsSum
      reimbursementsSum
      loanDeductionAmount
      createdAt
      einLastFour
      ssnLastFour
      finalCheckAmount
      note
      payroll {
        createdAt
      }
    }
  }
`

const Payouts = () => {
  const [technician] = useContext(TechnicianContext)
  const history = useHistory()
  const theme = useTheme()
  const startDatetime = DateTime.fromISO(FIRST_PAYOUT_DATE).toUTC().toISO()

  const useStyles = makeStyles(theme => ({
    noResults: {
      minHeight: '200px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#444',
    },
    payoutCard: {
      padding: '.125rem 1rem .5rem 1rem',
      marginBottom: '1rem',
      color: '#555',
      '& p': {
        display: 'block',
        fontFamily: 'Open Sans',
        color: '#3E3745',
        fontWeight: 600,
        paddingBottom: '0px',
        margin: '0px',
      },
      '& span': {
        fontFamily: 'Open Sans',
        color: '#3E3745',
      },
    },
    pageHeading: {
      padding: '0 1rem 2rem 1rem',
      fontFamily: 'Open Sans',
      color: theme.mode === 'dark' ? '#fff' : '#373845',
      fontWeight: 700,
      display: 'block',
    },
  }))
  const classes = useStyles(theme)

  const { loading, error, data, refetch } = useQuery(PAYOUTS_QUERY, {
    variables: {
      technicianId: technician.id,
      startDatetime: startDatetime,
    },
  })

  if (loading) return <Loading />
  if (error) return <div>Error!</div>

  const payouts =
    data?.payouts?.filter(payout =>
      technician.isEmployee
        ? DateTime.fromISO(payout.createdAt) > DateTime.fromFormat('2023-06-25', 'yyyy-MM-dd')
        : payout
    ) || []

  return (
    <Grid container>
      <Grid item xs={12} className={classes.pageHeading}>
        <Typography variant='p' style={{ fontSize: '2rem' }}>
          Payouts
        </Typography>
        <Typography
          variant='p'
          style={{ display: 'block', marginTop: '.5rem', fontSize: '1rem', opacity: 0.75, fontWeight: 400 }}
        >
          Tap on a payout to see all of the associated work orders.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {payouts.length === 0 ? (
          <>
            <Box className={classes.noResults}>There are no payouts to display... yet.</Box>
            {technician.isEmployee && (
              <Typography
                sx={{
                  color: '#777',
                  textAlign: 'center',
                  fontSize: '12px',
                  maxWidth: '75%',
                  margin: '-75px auto 0rem auto',
                }}
              >
                The first employee payroll will be available on Monday, June 26, 2023.
              </Typography>
            )}
          </>
        ) : (
          payouts
            .map((payout, index) => {
              return payout.finalCheckAmount > 0 ? (
                <Paper
                  onClick={() => history.push(`/payouts/${payout.id}`)}
                  className={classes.payoutCard}
                  elevation={1}
                  key={index}
                >
                  <Grid container>
                    <Grid item xs={10}>
                      <span style={{ fontWeight: 600, color: '#30A92E', fontSize: '2rem', marginBottom: '.25rem' }}>
                        <Dollars
                          color={theme.palette.mode === 'dark' ? '#98fb98' : '#30A92E'}
                          value={payout.finalCheckAmount}
                        />
                      </span>
                    </Grid>
                    <Grid item xs={2} align='right' onClick={() => history.push(`/payouts/${payout.id}`)}>
                      <KeyboardArrowRight
                        style={{
                          color:
                            theme.palette.mode === 'dark'
                              ? theme.palette.text.secondary
                              : theme.mode === 'dark'
                              ? '#fff'
                              : '#373845',
                          opacity: theme.palette.mode === 'dark' ? 0.75 : 0.25,
                          marginTop: '1rem',
                        }}
                      />
                    </Grid>
                  </Grid>
                  <span
                    style={{
                      color: '#aaa',
                      fontSize: '.8rem',
                      fontFamily: 'Open Sans',
                      marginTop: '-.25rem',
                      display: 'block',
                    }}
                  >
                    {payout.payroll && DateTime.fromJSDate(new Date(payout.payroll.createdAt)).toFormat('DDDD')}
                  </span>
                </Paper>
              ) : (
                <></>
              )
            })
            .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
        )}
      </Grid>
    </Grid>
  )
}

export default Payouts
