import React, { useEffect, useState, useContext } from 'react'
import ReactDOM from 'react-dom'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import { UserContext } from './UserStore'
import { ApolloProvider, createHttpLink, InMemoryCache, ApolloClient } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import UserProvider from './UserStore'
import GlobalStore from './GlobalStore'
import Login from './Login'
import AuthGateway from './AuthGateway'
import { SocketProvider } from '@ericlathrop/phoenix-js-react-hooks'
import TechnicianChannelProvider from './TechnicianChannelStore'
import { osName, osVersion, getUA } from 'react-device-detect'
import { v4 as uuid } from 'uuid'
import localForage from 'localforage'

// import RefetchRegistryProvider from './RefetchRegistry'
// import UpdateRefetchRegistryProvider from './UpdateRefetchRegistry'

const UserConsumer = () => {
  const [user] = useContext(UserContext)
  const [apolloClient, setApolloClient] = useState(null)
  const [deviceId, setDeviceId] = useState(null)

  const socketUrl = `wss://${process.env.REACT_APP_NEO_HOST}/socket`
  const socketOptions = { params: { token: user.token } }

  useEffect(() => {
    let userIsLoggedIn = user && user.id && user.token

    if (!userIsLoggedIn) return null

    const httpLink = createHttpLink({
      uri: process.env.REACT_APP_GQL_URL,
    })

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${user.token}`,
        },
      }
    })

    setApolloClient(
      new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
      })
    )
  }, [user])

  useEffect(async () => {
    let val = await localForage.getItem('deviceId')

    if (!val) {
      val = uuid()
      await localForage.setItem('deviceId', val)
    }
    setDeviceId(val)
  }, [])

  useEffect(() => {
    if (!user?.token || !deviceId) return

    fetch(`https://${process.env.REACT_APP_API_HOST}/device_info`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        technicianId: user.id,
        deviceId: deviceId,
        osName: osName,
        osVersion: osVersion,
        userAgent: getUA,
      }),
    })
  }, [user, deviceId])

  return (
    <BrowserRouter>
      {(apolloClient && (
        <ApolloProvider client={apolloClient}>
          <GlobalStore>
            <SocketProvider url={socketUrl} options={socketOptions}>
              <TechnicianChannelProvider>
                {/* <RefetchRegistryProvider> */}
                {/* <UpdateRefetchRegistryProvider> */}
                <App apolloClient={apolloClient} />
                {/* </UpdateRefetchRegistryProvider> */}
                {/* </RefetchRegistryProvider> */}
              </TechnicianChannelProvider>
            </SocketProvider>
          </GlobalStore>
        </ApolloProvider>
      )) || <AuthGateway />}
    </BrowserRouter>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <UserConsumer />
    </UserProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
