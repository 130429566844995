import {
  Grid,
  TableBody,
  TableCell,
  TableRow,
  Table,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { Dollars, underscoreToTitleCase } from '../../../../tools'

import { useStyles } from 'presentation/pages/Payout/styles'

export const BToBInvoices = ({ payout }) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <>
      {payout.bToBInvoices.length > 0 && (
        <>
          <Grid item xs={12} className={classes.sectionHeader}>
            <div style={{ color: theme.mode === 'dark' ? '#fff' : '#373845', fontSize: '1.125rem' }}>
              {' '}
              Business Invoices{' '}
            </div>
          </Grid>

          <Accordion className={classes.workOrderCard} elevation={1} key='equipLoan'>
            <AccordionSummary expandIcon={<ExpandMore />} style={{ padding: '0px', width: '100%' }}>
              <Grid container>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ fontWeight: 600 }}>Total</span>
                  <span style={{ color: '#aaa', marginLeft: 'auto', marginRight: '1rem', display: 'block' }}>
                    <Dollars value={payout.bToBInvoicesSum} />
                  </span>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0 0 .75rem 0', borderTop: '1px solid #eee', color: '#3a3a3a' }}>
              <Table>
                <TableBody>
                  {payout.bToBInvoices &&
                    payout.bToBInvoices.map(invoice => (
                      <TableRow key={invoice.id}>
                        <TableCell
                          style={{ padding: '0 0 0 .5rem', borderBottomStyle: 'dotted', borderBottomWidth: 2 }}
                        >
                          <div>
                            {invoice.year}&nbsp;{underscoreToTitleCase(invoice.make)}&nbsp;
                            {underscoreToTitleCase(invoice.model)}
                          </div>
                          <div style={{ color: theme.palette.text.secondary }}>
                            <small>RO: {invoice.repairOrder}</small>
                            {/* <small>VIN: {invoice.vin}</small> */}
                          </div>
                        </TableCell>
                        <TableCell align='right' style={{ borderBottomStyle: 'dotted', borderBottomWidth: 2 }}>
                          <Dollars
                            color={theme.mode === 'dark' ? '#fff' : '#373845'}
                            value={invoice?.payoutData?.totalPayout}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
          <br />
        </>
      )}
    </>
  )
}
