import { useContext } from 'react'
import { AnnouncementRounded, CloseRounded, KeyboardArrowDownRounded, ChevronRightRounded } from '@mui/icons-material'
import { TechnicianContext } from 'GlobalStore'
import Disclaimer, { Props as DisclaimerProps } from './Disclaimer'
import { Box, Collapse, Typography } from '@mui/material'

const data = [
  {
    name: 'disclaimerOpen',
    title: 'ATTENTION EMPLOYEES',
    test: technician => technician.isEmployee,
    makeContent: (setOpen, history) => (
      <>
        You can now access the breakdown of your weekly pay details with your hours, commission and tips under the
        Payouts Section in the App. <br /> <br /> Please note that your commission for a successfully completed repair
        is paid to you upon payment receipt from the client. If a job is incomplete and the client pays for the repair
        before a follow up visit is scheduled, your commission will be automatically rolled into your weekly payroll
        despite the fact the work is not done yet. However, if the repair is not completely successful after the follow
        up visit, a chargeback for that amount will appear in your Payout Section the following week.
        <br />
        <br />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: '#0d3393bf',
            textDecoration: 'underline',
            justifyContent: 'flex-end',
          }}
          onClick={() => {
            history.push('/payouts')
            setOpen(false)
          }}
        >
          Go to payouts <ChevronRightRounded />
        </Box>
      </>
    ),
  },
  {
    name: 'disclaimer.issueSystem',
    title: 'ATTENTION TECHNICIANS',
    initialValue: Date.now() < Date.parse('2023-09-15'),
    makeContent: (setOpen, history) => (
      <>
        You can now submit issue tickets to our support team directly through the tech app. <br /> <br />
        If you have an issue with your vehicle, a problem with a customer, or even a problem with an unhelpful sales
        team member, you can now submit a ticket that will directly bring this issue to the CarBodyLab management team.
        The internal CarBodyLab management team will review all submitted issues on a daily basis, and will take action
        based on each submitted issue.
      </>
    ),
  },
  {
    name: 'disclaimer.openEnrollment',
    title: 'ATTENTION CALIFORNIA EMPLOYEES',
    test: technician => technician.isEmployee && technician.state == 'CA' && Date.now() < Date.parse('2023-11-08'),
    makeContent: (setOpen, history) => (
      <>
        Open Enrollment has started now and is running from October 18th through November 7th. As a reminder, open
        enrollment is the one time per year where you can change any of your benefit plan elections for any reason at
        all. <br /> <br />
        Please login and review your plan options and make your new elections for the upcoming plan year. All new
        coverage elections will go into effect on November 1, 2023.
        <br />
        <br />
        <a href='https://carbodylab.ease.com'>https://carbodylab.ease.com</a>
        <br />
        <br />
        After open enrollment, the only way that you can join the plan or make changes is if you experience a qualifying
        life event. Some examples: marriage, birth of a child, adoption, loss or other coverage, etc..
        <br />
        <br />
        For help in making the proper medical, dental, or vision plan choice; please reach out to our broker partner
        (Newfront) at <a href='mailto:patrick.hennon@newfront.com'>patrick.hennon@newfront.com</a>
        <> or </>
        <a href='tel:415-336-2890'>(415) 336-2890</a>. <br /> <br /> Thank you.
        <br />
        <br />
        Car Body Lab Team
      </>
    ),
  },
]

export default function () {
  const [technician] = useContext(TechnicianContext)

  return (
    <>
      {data.map(item =>
        item.test && !item.test(technician) ? (
          <></>
        ) : (
          <Disclaimer
            name={item.name}
            title={item.title}
            initialValue={item.initialValue}
            makeContent={item.makeContent}
          />
        )
      )}
    </>
  )
}
