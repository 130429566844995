import React from 'react'

import { DateTime } from 'luxon'

import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

import TinyTable from 'presentation/components/TinyTable'
import { Dollars } from 'tools'

const Payments = ({ quote }) => (
  <>
    <Typography>
      Payments
    </Typography>

    <TableContainer component={Paper}>
      <TinyTable>
        <TableHead>
          <TableRow style={{ fontSize: '8px' }}>
            <TableCell align='right'>amount before tip</TableCell>
            <TableCell align='right'>tip amount</TableCell>
            <TableCell align='right'>total</TableCell>
            <TableCell align='right'>time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {quote.groupedPayments.map(payment =>
            <TableRow key={payment.id}>
              <TableCell align='right'>
                <Dollars value={payment.amountBeforeTip} />
              </TableCell>
              <TableCell align='right'>
                <Dollars value={payment.tipAmount} />
              </TableCell>
              <TableCell align='right'>
                <Dollars value={payment.totalAmount} />
              </TableCell>
              <TableCell align='right'>
                {DateTime.fromISO(payment.createdAt).toLocaleString(DateTime.DATETIME_MED)}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TinyTable>
    </TableContainer>
  </>
)

export default Payments
