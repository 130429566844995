import { useTheme, TableBody, TableCell, TableRow, Table, Paper } from '@mui/material'
import { TechnicianContext } from 'GlobalStore'
import { useStyles } from 'presentation/pages/Payout/styles'
import { useContext } from 'react'
import { Dollars, keysToCamel } from '../../../../tools'

export const Summary = ({ payout }) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [technician] = useContext(TechnicianContext)

  const quotes = payout.quotes
    ? payout.quotes.map(quote => {
        return { id: quote.id, jobs: quote.jobs, lead: quote.lead }
      })
    : [{ id: null, jobs: [] }]

  const showLoanInfo =
    payout.loanBalanceBeforeThisPayout !== 0 ||
    payout.loanDeductionAmount !== 0 ||
    payout.otherLoanDeductionAmount !== 0

  const sortedWorkOrders = payout.quotes
    ? payout.quotePayouts
        .reduce((acc, q) => {
          const quote = quotes.filter(quote => quote.id === q.quoteId)[0]
          return [
            ...acc,
            keysToCamel({
              ...q,
              id: q.quoteId,
              jobs: quote.jobs,
              mostRecentJob: quote.jobs[quote.jobs.length - 1],
              lead: quote.lead,
            }),
          ]
        }, [])
        .sort((a, b) => (a.mostRecentJob.startDatetime < b.mostRecentJob.startDatetime ? 1 : -1))
    : []

  const sortedManualCompensations = [...payout.manualCompensations.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))]

  const timeCards = payout?.timeCards || []

  const totalHoursWorked = timeCards
    ?.reduce((acc, item) => acc + parseFloat(item.payoutData?.totalPaidHours || '0'), 0)
    .toFixed(2)

  const totalHourlyPay = timeCards?.reduce((acc, item) => acc + parseFloat(item.payoutData?.totalHourlyPay || '0'), 0)
  const overtimeHours = payout?.overtimePay > 0 && payout?.overtimePay / (technician.hourlyRate * 1.5) || 0

  return (
    <Paper className={classes.summaryCard} elevation={1}>
      <Table>
        <TableBody>
          {totalHoursWorked > 0 && (
            <TableRow style={{ background: theme.palette.background.highlight }}>
              <TableCell style={{ borderBottom: 'none', padding: '0 0 0 .5rem' }}>Regular Hours</TableCell>
              <TableCell align='right' style={{ borderBottom: 'none' }}>
                {payout?.overtimePay > 0 ? <>{totalHoursWorked - overtimeHours}</> : <>{totalHoursWorked}</>}
              </TableCell>
            </TableRow>
          )}

          {(payout?.overtimePay > 0 && (
            <TableRow style={{ background: theme.palette.background.highlight }}>
              <TableCell style={{ borderBottom: 'none', padding: '0 0 0 .5rem' }}>Overtime Hours</TableCell>
              <TableCell align='right' style={{ borderBottom: 'none' }}>
                {overtimeHours}
              </TableCell>
            </TableRow>
          )) ||
            ''}

          {(payout?.minimumHourlyPayAdjustment > 0 && (
            <TableRow style={{ background: theme.palette.background.highlight }}>
              <TableCell style={{ padding: '0 0 0 .5rem', borderBottom: '0px' }}>Bonus Hours</TableCell>
              <TableCell align='right' style={{ borderBottom: '0px' }}>
                {(payout?.minimumHourlyPayAdjustment / technician.hourlyRate).toFixed(2)}
              </TableCell>
            </TableRow>
          )) ||
            ''}

          {(payout.totalSickTimeOffHours && payout.totalSickTimeOffHours > 0 && (
            <TableRow style={{ background: '#f9f9f9' }}>
              <TableCell style={{ borderBottom: '0px' }}>Sick Time</TableCell>
              <TableCell align='right' style={{ borderBottom: 'none' }}>
                {payout.totalSickTimeOffHours} hr(s)
              </TableCell>
            </TableRow>
          )) ||
            ''}

          {/* {(payout.totalUnpaidTimeOffHours && payout.totalUnpaidTimeOffHours > 0 && (
            <TableRow style={{ background: '#f9f9f9' }}>
              <TableCell style={{ borderBottom: 'none', padding: '0 0 0 .5rem' }}>Unpaid Time Off</TableCell>
              <TableCell align='right' style={{ borderBottom: 'none' }}>
                {payout.totalUnpaidTimeOffHours} hr(s)
              </TableCell>
            </TableRow>
          )) ||
            ''} */}

          {(payout.totalPaidTimeOffHours && payout.totalPaidTimeOffHours > 0 && (
            <TableRow style={{ background: '#f9f9f9' }}>
              <TableCell style={{ borderBottom: 'none', padding: '0 0 0 .5rem' }}>Paid Time Off</TableCell>
              <TableCell align='right' style={{ borderBottom: 'none' }}>
                {payout.totalPaidTimeOffHours} hr(s)
              </TableCell>
            </TableRow>
          )) ||
            ''}

          {(payout?.commissionChargeBacksSum > 0 && (
            <TableRow style={{ background: '#f9f9f9' }}>
              <TableCell style={{ borderBottom: 'none', padding: '0 0 0 .5rem' }}>Charge Backs</TableCell>
              <TableCell align='right' style={{ borderBottom: 'none' }}>
                <Dollars value={-payout?.commissionChargeBacksSum} />
              </TableCell>
            </TableRow>
          )) ||
            ''}

          {payout.bToBInvoicesSum > 0 && (
            <TableRow>
              <TableCell style={{ padding: '0 0 0 .5rem', borderBottomStyle: 'dotted', borderBottomWidth: 2 }}>
                Business Invoices
              </TableCell>
              <TableCell align='right' style={{ borderBottomStyle: 'dotted', borderBottomWidth: 2 }}>
                <Dollars color={theme.mode === 'dark' ? '#fff' : '#373845'} value={payout.bToBInvoicesSum} />
              </TableCell>
            </TableRow>
          )}
          {!technician.isEmployee && sortedWorkOrders.length > 0 && (
            <TableRow>
              <TableCell style={{ padding: '0 0 0 .5rem', borderBottomStyle: 'dotted', borderBottomWidth: 2 }}>
                Work Orders
              </TableCell>
              <TableCell align='right' style={{ borderBottomStyle: 'dotted', borderBottomWidth: 2 }}>
                <Dollars color={theme.mode === 'dark' ? '#fff' : '#373845'} value={payout.quotePayoutsSum} />
              </TableCell>
            </TableRow>
          )}

          {sortedManualCompensations.length > 0 && (
            <TableRow>
              <TableCell style={{ padding: '0 0 0 .5rem', borderBottomStyle: 'dotted', borderBottomWidth: 2 }}>
                Manual Compensations
              </TableCell>
              <TableCell align='right' style={{ borderBottomStyle: 'dotted', borderBottomWidth: 2 }}>
                <Dollars color={theme.mode === 'dark' ? '#fff' : '#373845'} value={payout.manualCompensationsSum} />
              </TableCell>
            </TableRow>
          )}
          {showLoanInfo && (
            <TableRow>
              <TableCell style={{ padding: '0 0 0 .5rem', borderBottomStyle: 'dotted', borderBottomWidth: 2 }}>
                Loan Deductions
              </TableCell>
              <TableCell align='right' style={{ borderBottomStyle: 'dotted', borderBottomWidth: 2 }}>
                (
                <Dollars
                  color={theme.mode === 'dark' ? '#fff' : '#373845'}
                  value={payout.loanDeductionAmount + payout.otherLoanDeductionAmount}
                />
                )
              </TableCell>
            </TableRow>
          )}

          {totalHourlyPay > 0 && (
            <TableRow style={{ background: theme.palette.background.highlight }}>
              <TableCell style={{ borderBottom: 'none', padding: '0 0 0 .5rem' }}>
                <b>Total Hourly Pay</b>
              </TableCell>
              <TableCell align='right' style={{ borderBottom: 'none' }}>
                <b>
                  <Dollars value={payout?.totalHourlyPay} />
                </b>
              </TableCell>
            </TableRow>
          )}

          {sortedWorkOrders.length > 0 && (
            <TableRow>
              <TableCell style={{ padding: '0 0 0 .5rem', borderBottom: '0px' }}>
                <b>Total Commission + Tips</b>
              </TableCell>
              <TableCell align='right' sx={{ borderBottom: '0px' }}>
                <b>
                  <Dollars color={theme.mode === 'dark' ? '#fff' : '#373845'} value={payout.quotePayoutsSum} />
                </b>
              </TableCell>
            </TableRow>
          )}

          {payout.reimbursementsSum > 0 && (
            <TableRow>
              <TableCell style={{ padding: '0 0 0 .5rem', borderBottom: '0px' }}>
                <b>Reimbursements</b>
              </TableCell>
              <TableCell align='right' sx={{ borderBottom: '0px' }}>
                <b>
                  <Dollars color={theme.mode === 'dark' ? '#fff' : '#373845'} value={payout.reimbursementsSum} />
                </b>
              </TableCell>
            </TableRow>
          )}

          <TableRow style={{ background: theme.palette.background.highlight }}>
            <TableCell style={{ borderBottom: 'none', padding: '0 0 0 .5rem', fontWeight: 'bold' }}>
              Total Payout
            </TableCell>
            <TableCell align='right' style={{ borderBottom: 'none', fontWeight: 'bold' }}>
              <Dollars color={theme.mode === 'dark' ? '#fff' : '#222'} value={payout.finalCheckAmount} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  )
}
