import { FormControlLabel, Checkbox, Typography, Container, Grid, Collapse, Button } from '@mui/material'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import IconButton from '@mui/material/IconButton'
import { useState, useContext } from 'react'
import { TechnicianContext } from 'GlobalStore'
import { bearerTokenHeaders } from 'tools'
import { DateTime } from 'luxon'
import { useHistory } from 'react-router-dom'
import { UserContext } from 'UserStore'
import { VehiclePicker } from './components/VehiclePicker'
import { VinPhoto } from './components/VinPhoto'
import { PreJobPhotos } from './components/PreJobPhotos'
import { LineItemsSection } from './components/LineItems'
import ReferenceNumbers from './components/ReferenceNumbers'

const WorkOrder = ({ workOrder, refetch }) => {
  const history = useHistory()
  const [user] = useContext(UserContext)
  const [technician] = useContext(TechnicianContext)
  const [toggleCompleteCheckboxDisabled, setToggleCompleteCheckboxDisabled] = useState(false)

  const toggleComplete = completedStatus => {
    setToggleCompleteCheckboxDisabled(true)

    fetch(`https://${process.env.REACT_APP_API_HOST}/toggle_b_to_b_work_order_completed`, {
      method: 'POST',
      headers: bearerTokenHeaders(user.token),
      body: JSON.stringify({
        bToBWorkOrderId: workOrder.id,
        completed: completedStatus,
        technicianId: technician.id,
      }),
    })
      .then(res => {
        if (completedStatus) {
          setTimeout(() => history.push('/b-to-b-work-orders'), 750)
        } else {
          setTimeout(() => setToggleCompleteCheckboxDisabled(false), 750)
        }
      })
      .then(() => refetch())
      .catch(() => window.alert('There was an error creating a new workOrder.'))
  }

  return (
    <Container disableGutters maxWidth='sm'>
      <Grid container spacing={2} style={{ position: 'relative' }}>
        <IconButton
          aria-label='back'
          style={{ position: 'absolute', left: '16px', top: '12px' }}
          onClick={() => {
            history.push('/b-to-b-work-orders')
          }}
        >
          <ArrowBackIosNewOutlinedIcon />
        </IconButton>

        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography variant='h6' sx={{ textTransform: 'capitalize' }}>
            {workOrder.businessName} <br />
            <span style={{ opacity: 0.75, fontSize: '14px' }}>
              Created: {DateTime.fromISO(workOrder.createdAt).toLocaleString()}
            </span>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <b
            style={{
              display: 'block',
              padding: '.25rem .5rem',
              textAlign: 'center',
              color: '#111',
              marginBOttom: '.5rem',
              borderTop: '2px dashed orange',
              borderBottom: '2px dashed orange',
              background: '#ffa50040',
            }}
          >
            WORK ORDER
          </b>
        </Grid>

        {workOrder.completedAt && (
          <Grid item xs={12}>
            <b
              style={{
                display: 'block',
                padding: '.25rem .5rem',
                textAlign: 'center',
                color: '#111',
                backgroundColor: 'palegreen',
              }}
            >
              COMPLETED {DateTime.fromISO(workOrder.completedAt).toFormat('D hh:mm')}
            </b>
          </Grid>
        )}

        <VehiclePicker refetch={refetch} workOrder={workOrder} />
        <ReferenceNumbers workOrder={workOrder} refetch={refetch} />
        <VinPhoto workOrder={workOrder} refetch={refetch} />
        <PreJobPhotos workOrder={workOrder} refetch={refetch} />
        <LineItemsSection workOrder={workOrder} refetch={refetch} />

        {/* <Chat contextId={workOrder.id} contextType='BToBWorkOrder' /> */}

        <Collapse in sx={{ width: '100%' }}>
          <Grid container>
            <Grid
              item
              sx={{
                padding: '1rem 1rem 1rem 0rem',
                ml: 'auto',
                width: 'auto',
              }}
            >
              <FormControlLabel
                label='Completed'
                fullWidth
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  background: '#f7f7f7',
                  borderRadius: '8px',
                  pl: '.75rem',
                  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
                  border: '1px solid #ddd',
                  '&:active': {
                    background: '#eee',
                  },
                }}
                labelPlacement='start'
                componentsProps={{
                  typography: {
                    sx: { fontSize: '18px', ml: 'auto' },
                  },
                }}
                control={
                  <Checkbox
                    disabled={workOrder.acceptedAt || toggleCompleteCheckboxDisabled}
                    size='large'
                    checked={workOrder.completedAt}
                    onChange={() => toggleComplete(!workOrder.completedAt)}
                    sx={{ mr: '0px', ml: '1rem' }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Container>
  )
}

export default WorkOrder
