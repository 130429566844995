import { Modal, Typography, Grid, Drawer, Button, Box } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { useState } from 'react'
import { thumbnailUrlForPhoto, fullSizeUrlForPhoto } from 'tools'
// @ts-ignore
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'
import { Uploader } from './Uploader'

export const VinPhoto = ({ workOrder, refetch }: { workOrder: any; refetch: any }) => {
  const [showVinPhotoDrawer, setShowVinPhotoDrawer] = useState(false)
  const [currentFullScreenPhoto, setCurrentFullScreenPhoto] = useState(null)

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            borderRadius: '6px 6px 0px 0px',
            fontWeight: 700,
            fontSize: '14px',
            padding: '.5rem 1rem',
            display: 'flex',
            alignItems: 'center',
            background: '#b5d9ff',
          }}
        >
          VIN Photo
          {workOrder.vinPhoto && !workOrder.payoutData && !workOrder.completedAt && (
            <Button
              onClick={() => setShowVinPhotoDrawer(!showVinPhotoDrawer)}
              disableElevation
              size='small'
              sx={{
                ml: 'auto',
                color: '#222',
                padding: '0rem .5rem',
              }}
            >
              [ REPLACE ]
            </Button>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            background: '#fff',
            border: '1px solid #ddd',
            borderBottom: '0px',
            borderTop: '0px',
            textAlign: 'center',
          }}
        >
          {(!!workOrder.vinPhoto && (
            <div style={{ width: '100%', height: '200px' }}>
              <img
                onClick={() => setCurrentFullScreenPhoto(workOrder.vinPhoto)}
                style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                src={thumbnailUrlForPhoto(workOrder.vinPhoto)}
              />
            </div>
          )) || <>No photo uploaded yet</>}
        </Grid>

        {!!workOrder.vinPhoto || (
          <Grid
            item
            xs={12}
            sx={{
              border: '1px solid #ddd',
              fontSize: '14px',
              borderRadius: '0px 0px 6px 6px',
              display: 'flex',
              padding: '.25rem .5rem',
              background: '#f1f1f1',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              size='small'
              color='primary'
              variant='contained'
              onClick={() => setShowVinPhotoDrawer(!showVinPhotoDrawer)}
              disabled={!!workOrder.completedAt}
              sx={{ padding: '.25rem 1rem' }}
            >
              Upload Photo
            </Button>
          </Grid>
        )}
      </Grid>

      <Drawer
        open={showVinPhotoDrawer}
        onClose={() => setShowVinPhotoDrawer(false)}
        anchor='bottom'
        PaperProps={{
          sx: {
            textAlign: 'center',
            pt: '1rem',
          },
        }}
      >
        <Grid container sx={{ padding: '0rem 1rem 1rem 1rem' }}>
          <Grid item xs={12} sx={{ pb: '1rem' }}>
            <Typography variant='h6'>VIN Photo</Typography>
          </Grid>
          <Grid item xs={12}>
            <Uploader
              // @ts-ignore
              multiple={false}
              closeDrawer={() => setShowVinPhotoDrawer(false)}
              url='set_b_to_b_work_order_vin_photo_url'
              workOrder={workOrder}
              refetch={refetch}
            />
          </Grid>
        </Grid>
      </Drawer>

      <Modal open={!!currentFullScreenPhoto} onClose={() => setCurrentFullScreenPhoto(null)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            height: '700px',
            maxHeight: '80%',
            width: '1100px',
            maxWidth: '100%',
            boxShadow: 24,
            border: '1px solid black',
          }}
        >
          <div style={{ position: 'relative', height: '100%', width: '100%' }}>
            <Cancel
              sx={{
                zIndex: 999,
                position: 'absolute',
                top: '5px',
                right: '5px',
                fontSize: '30px',
                color: '#fff',
                border: '2px solid #fff',
                borderRadius: '20px',
              }}
              onClick={() => setCurrentFullScreenPhoto(null)}
            />
            <PinchZoomPan position='center' maxScale={2}>
              <img src={(currentFullScreenPhoto && fullSizeUrlForPhoto(currentFullScreenPhoto)) || ''} />
            </PinchZoomPan>
          </div>
        </Box>
      </Modal>
    </Grid>
  )
}
