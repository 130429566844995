import { List, ListItemIcon, Drawer, Typography, Grid, ListItem, ListItemText, useTheme } from '@mui/material'
import TechnicianIssueDialog from '../TechnicianIssueDialog'
import { useContext, useState } from 'react'
import {
  CalendarToday,
  ChatBubbleOutlineOutlined,
  HandymanOutlined,
  HardwareOutlined,
  KeyboardArrowLeft,
  Receipt,
  ReceiptOutlined,
  SettingsOutlined,
  ShoppingCart,
} from '@mui/icons-material'
import { TechnicianContext } from 'GlobalStore'

export const LeftDrawer = ({ setMenuOpen, menuOpen, handleClickMenuPath, classes }) => {
  const [technicianIssueDialogOpen, setTechnicianIssueDialogOpen] = useState(false)
  const theme = useTheme()
  const [technician] = useContext(TechnicianContext)

  const labelClassGenerator = pathname =>
    [classes.menuButton, location.pathname === pathname ? classes.activeLabel : classes.label].join(' ')

  return (
    <Drawer anchor='left' open={menuOpen} onClose={() => setMenuOpen(false)}>
      <Grid container className={classes.sideBarHeader}>
        <Grid
          item
          xs={12}
          sx={{
            alignItems: 'center',
            display: 'flex',
            '& svg': { marginRight: 'auto', marginLeft: '0px', height: '35px', width: '35px', color: '#fff' },
          }}
        >
          <KeyboardArrowLeft onClick={() => setMenuOpen(false)} />
        </Grid>
        <Grid item xs={12} sx={{ padding: '1.5rem 1rem 0rem 1rem' }}>
          <Typography variant='h6' style={{ fontFamily: 'Open Sans', fontWeight: 700, fontSize: '1.65rem' }}>
            {technician.firstName} {technician.lastName}
          </Typography>
          {Number(technician.suppliesCostPercentage) > 0 && (
            <Typography sx={{ fontWeight: 600, mb: '1rem' }}>
              {' '}
              Supplies Usage: {technician.suppliesCostPercentage}%
            </Typography>
          )}
          <Typography variant='body1'>{technician && technician.market}</Typography>
        </Grid>
      </Grid>

      <List style={{ minWidth: '250px', color: '#555', padding: '1rem 0' }}>
        <ListItem button onClick={() => handleClickMenuPath('/schedule')} className={labelClassGenerator('/schedule')}>
          <ListItemIcon className={classes.buttonIcon}>
            <CalendarToday
              sx={{
                color: location.pathname === '/schedule' ? '#fff' : theme.palette.text.primary,
                opacity: location.pathname === '/schedule' ? 1 : 0.5,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ style: { fontSize: '1.125rem' } }}
            sx={{
              color: location.pathname === '/schedule' ? '#fff' : theme.palette.text.primary,
              opacity: location.pathname === '/schedule' ? 1 : 0.5,
            }}
          >
            Schedule
          </ListItemText>
        </ListItem>

        {(technician.isEmployee || technician.id === '862025e4-9b37-4736-a4c1-ded102a5ec93') && (
          <ListItem
            button
            onClick={() => handleClickMenuPath('/supplies-orders')}
            className={labelClassGenerator('/supplies-orders')}
          >
            <ListItemIcon className={classes.buttonIcon}>
              <ShoppingCart
                sx={{
                  color: location.pathname === '/supplies-orders' ? '#fff' : theme.palette.text.primary,
                  opacity: location.pathname === '/supplies-orders' ? 1 : 0.5,
                }}
              />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ style: { fontSize: '1.125rem' } }}
              sx={{
                color: location.pathname === '/supplies-orders' ? '#fff' : theme.palette.text.primary,
                opacity: location.pathname === '/supplies-orders' ? 1 : 0.5,
              }}
            >
              Supplies Orders
            </ListItemText>
          </ListItem>
        )}

        {technician.isBToB && (
          <ListItem
            button
            onClick={() => handleClickMenuPath('/b-to-b-chat')}
            className={labelClassGenerator('/b-to-b-chat')}
          >
            <ListItemIcon className={classes.buttonIcon}>
              <ChatBubbleOutlineOutlined
                sx={{
                  color: location.pathname === '/b-to-b-chat' ? '#fff' : theme.palette.text.primary,
                  opacity: location.pathname === '/b-to-b-chat' ? 1 : 0.5,
                }}
              />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ style: { fontSize: '1.125rem' } }}
              sx={{
                color: location.pathname === '/b-to-b-chat' ? '#fff' : theme.palette.text.primary,
                opacity: location.pathname === '/b-to-b-chat' ? 1 : 0.5,
              }}
            >
              B-to-B Chat
            </ListItemText>
          </ListItem>
        )}

        {technician.bToBBusinessName && (
          <ListItem
            button
            onClick={() => handleClickMenuPath('/b-to-b-invoices')}
            className={location.pathname === '/b-to-b-invoices' ? classes.activeLabel : classes.label}
          >
            <ListItemIcon className={classes.buttonIcon}>
              <Receipt
                sx={{
                  color: location.pathname === '/b-to-b-invoices' ? '#fff' : theme.palette.text.primary,
                  opacity: location.pathname === '/b-to-b-invoices' ? 1 : 0.5,
                }}
              />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ style: { fontSize: '1.125rem' } }}
              sx={{
                color: location.pathname === '/b-to-b-invoices' ? '#fff' : theme.palette.text.primary,
                opacity: location.pathname === '/b-to-b-invoices' ? 1 : 0.5,
              }}
            >
              B-to-B Invoices
            </ListItemText>
          </ListItem>
        )}

        <ListItem button onClick={() => handleClickMenuPath('/parts')} className={labelClassGenerator('/parts')}>
          <ListItemIcon className={classes.buttonIcon}>
            <HandymanOutlined
              sx={{
                color: location.pathname === '/parts' ? '#fff' : theme.palette.text.primary,
                opacity: location.pathname === '/parts' ? 1 : 0.5,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ style: { fontSize: '1.125rem' } }}
            sx={{
              color: location.pathname === '/parts' ? '#fff' : theme.palette.text.primary,
              opacity: location.pathname === '/parts' ? 1 : 0.5,
            }}
          >
            Parts
          </ListItemText>
        </ListItem>

        <ListItem button onClick={() => handleClickMenuPath('/payouts')} className={labelClassGenerator('/payouts')}>
          <ListItemIcon className={classes.buttonIcon}>
            <ReceiptOutlined
              sx={{
                color: location.pathname === '/payouts' ? '#fff' : theme.palette.text.primary,
                opacity: location.pathname === '/payouts' ? 1 : 0.5,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ style: { fontSize: '1.125rem' } }}
            sx={{
              color: location.pathname === '/payouts' ? '#fff' : theme.palette.text.primary,
              opacity: location.pathname === '/payouts' ? 1 : 0.5,
            }}
          >
            Payouts
          </ListItemText>
        </ListItem>

        <hr
          style={{
            margin: '1rem auto 1rem 5%',
            border: '0px',
            height: '2px',
            width: '90%',
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : '#373845',
            opacity: theme.palette.mode === 'dark' ? 0.5 : 0.25,
          }}
        />

        <ListItem
          button
          onClick={() => {
            setTechnicianIssueDialogOpen(true)
          }}
        >
          <ListItemText
            primaryTypographyProps={{
              style: { fontSize: '1.125rem', textAlign: 'center', color: theme.palette.text.primary, opacity: 0.5 },
            }}
          >
            Submit Issue
          </ListItemText>
        </ListItem>

        <TechnicianIssueDialog showDialog={technicianIssueDialogOpen} setShowDialog={setTechnicianIssueDialogOpen} />
        {/*
        <ListItem button onClick={() => setTechnicianIssueDialogOpen(true)}>
          <ListItemText
            primaryTypographyProps={{ style: { fontSize: '1rem' } }}
            style={{ color: '#373845', opacity: 0.5 }}
          >
            Contact Support
          </ListItemText>
        </ListItem> */}

        {/* <ListItem
          button
          onClick={() => handleClickMenuPath('/settings')}
          className={location.pathname === '/settings' ? classes.activeLabel : classes.label}
        >
          <ListItemIcon>
            <SettingsOutlined
              sx={{
                color: location.pathname === '/settings' ? '#fff' : theme.palette.text.primary,
                opacity: location.pathname === '/settings' ? 1 : 0.5,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ style: { fontSize: '1.125rem' } }}
            sx={{
              color: location.pathname === '/settings' ? '#fff' : theme.palette.text.primary,
              opacity: location.pathname === '/settings' ? 1 : 0.5,
            }}
          >
            Settings
          </ListItemText>
        </ListItem> */}

        <ListItem button onClick={() => handleClickMenuPath('/settings')}>
          <ListItemText
            primaryTypographyProps={{ style: { fontSize: '1rem' } }}
            sx={{ color: theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.text.primary }}
          />
        </ListItem>
      </List>
    </Drawer>
  )
}
