import { useContext } from "react"
import { UserContext } from 'UserStore';
import { TechnicianContext } from "GlobalStore"
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Box, Button, Dialog, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, Radio, RadioGroup, Select, MenuItem, Stack, TextField } from "@mui/material"
import { bearerTokenHeaders } from "tools"

const validationSchema = Yup.object().shape({
  technician: Yup.string().required('This field is required.'),
  issueName: Yup.string().required('This field is required.'),
  issueType: Yup.string().required('This field is required.'),
  priority: Yup.string().required('This field is required.'),
  description: Yup.string().required('This field is required.'),
})

const issueTypes = [
  'Vehicle', 'Tools & Supplies', 'Job & Customer', 'Parts', 'Weather', 'Other'
].map((type, index) => ({
  id: index,
  name: type,
  value: type,
}))

const issuePriorities = ['Low', 'Medium', 'High'].map((priority, index) => ({
  id: index,
  name: priority,
  value: priority,
}))

const TechnicianIssueDialog = ({ showDialog, setShowDialog }) => {
  const [user] = useContext(UserContext)
  const [technician] = useContext(TechnicianContext)

  const initialValues = {
  technician: technician.id,
  submitter: (technician?.firstName) ? `${technician.firstName} ${technician.lastName}` : '',
  jobLink: '',
  issueName: '',
  issueType: '',
  priority: '',
  description: '',
}

  const handleReset = () => {
    formik.resetForm({
      values: initialValues,
    })

    setShowDialog(false)
  }

  const handleSubmit = ({technician, submitter, issueName, issueType, jobLink, priority, description}) => {
    fetch(`https://${process.env.REACT_APP_API_HOST}/submit_issue`, {
      method: 'POST',
      headers: bearerTokenHeaders(user.token),
      body: JSON.stringify({
        technicianId: technician,
        submitter,
        issueName,
        issueType,
        description,
        jobLink,
        priority,
      }),
    })
      .then(res => {
        if(!res.ok) throw new Error("Error creating Notion Page")
      })
      .catch(err => {
        console.error(err)
        window.alert('Error')
      })
      .finally(() => {
        handleReset()
      })
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
      <Box>
        <Grid container spacing={3} sx={{ padding: '1rem' }}>
          <Grid
            item
            xs={12}
            sx={{ color: 'primary.main', fontWeight: 'bold' }}
          >
            Submit an issue
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                size='small'
                name="issueName"
                label="Enter an Issue Name"
                value={formik.values.issueName}
                onChange={formik.handleChange}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth size='small'>
              <InputLabel>Select Priority</InputLabel>
              <Select
                name="priority"
                label="Select Priority"
                value={formik.values.priority}
                onChange={formik.handleChange}
              >
                {issuePriorities.map(type => ( <MenuItem key={type.id} value={type.value}>{type.name}</MenuItem> ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth size='small'>
              <TextField
                multiline
                name="description"
                label="Enter a description"
                minRows={4}
                value={formik.values.description}
                onChange={formik.handleChange}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth size='small'>
              <FormLabel>Select an issue type</FormLabel>
              <RadioGroup
                name="issueType"
                onChange={formik.handleChange}
              >
                {issueTypes.map(type => (
                  <FormControlLabel key={type.id} value={type.value} label={type.name} control={<Radio />} />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Stack direction='row' justifyContent="space-between" sx={{ padding: '1rem', pt: '0' }}>
          <Button
            variant="outlined"
            size='small'
            onClick={handleReset}
            sx={{ minWidth: '40%'}}>
            Cancel
          </Button>
          <Button
            variant="contained"
            size='small'
            onClick={formik.handleSubmit}
            sx={{ minWidth: '40%'}}
          >
            Submit
          </Button>
        </Stack>
      </Box>
    </Dialog>
  )
}

export default TechnicianIssueDialog