import { useState, useContext, useEffect } from 'react'
import { TextField, TableRow, TableCell, Button } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { UserContext } from 'UserStore'
import { bearerTokenHeaders, diff } from 'tools'

const InlinePartEditorGrid = ({
  refetch,
  workOrder,
  part,
  currentEditingPart,
  setCurrentEditingPart,
  editingDisabled,
}) => {
  const [user] = useContext(UserContext)
  const [waiting, setWaiting] = useState(false)
  const [changed, setChanged] = useState(false)

  useEffect(() => {
    formik.resetForm({ values: part })
  }, [part])

  const unsetEditingPart = () => {
    if (currentEditingPart === formik.values.id) {
      setCurrentEditingPart('')
    }
  }

  const handleRemovePart = partId => {
    setWaiting(true)

    fetch(`https://${process.env.REACT_APP_API_HOST}/remove_part_from_b_to_b_work_order`, {
      method: 'POST',
      headers: bearerTokenHeaders(user.token),
      body: JSON.stringify({
        userId: user.id,
        bToBWorkOrderId: workOrder.id,
        partId: partId,
      }),
    })
      .then(res => res.ok || window.alert('Error'))
      .then(res => refetch())
      .finally(() => unsetEditingPart())
  }

  const handleClickSave = () => {
    setWaiting(true)

    fetch(`https://${process.env.REACT_APP_API_HOST}/update_attributes_for_b_to_b_part`, {
      method: 'POST',
      headers: bearerTokenHeaders(user.token),
      body: JSON.stringify({
        userId: user.id,
        partId: part.id,
        updatedAttributes: {
          name: formik.values.name,
        },
      }),
    })
      .then(res => res.ok || window.alert('Error'))
      .then(res => refetch())
      .finally(() => {
        setWaiting(false)
      })
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('required'),
    number: Yup.string().required('required'),
    cost: Yup.number().nullable(),
    price: Yup.number(),
    isPaid: Yup.boolean().nullable(),
    prePaymentRequired: Yup.boolean(),
  })

  const formik = useFormik({
    initialValues: part,
    validationSchema: validationSchema,
    onSubmit: handleClickSave,
  })

  useEffect(() => {
    const payload = {
      ...diff(formik.values, formik.initialValues),
    }

    if (Object.keys(payload).length === 0) {
      setChanged(false)

      // This part was manually changed back to it's original value and is no longer
      // in an editing state so we need to notify the parent component
      unsetEditingPart()
      return
    }
    setChanged(true)
    setCurrentEditingPart(part.id)
  }, [formik.values])

  useEffect(() => {
    formik.resetForm({ values: part })
    setWaiting(false)
    setChanged(false)
  }, [part])

  useEffect(() => {
    if (changed && currentEditingPart !== part.id) {
      formik.resetForm({ values: part })
    }
  }, [currentEditingPart])

  useEffect(() => {
    if (editingDisabled) {
      formik.resetForm({ values: part })
    }
  }, [editingDisabled])

  const disabled = waiting || editingDisabled

  return (
    <>
      <TableRow>
        <TableCell>
          <TextField
            fullWidth
            multiline
            size='small'
            name='name'
            onChange={formik.handleChange}
            type='text'
            value={formik.values.name}
            variant='standard'
            disabled={disabled}
          />
        </TableCell>
        <TableCell align='right'>
          <IconButton
            onClick={() => window.confirm('are you sure?') && handleRemovePart(part.id)}
            disabled={disabled}
            id='delete-part-button'
          >
            <DeleteIcon fontSize='small' />
          </IconButton>
        </TableCell>
      </TableRow>
      {changed && (
        <TableRow sx={{ background: '#f1f1f1' }}>
          <TableCell>
            <Button
              size='small'
              variant='outlined'
              onClick={() => {
                if (waiting) {
                  return
                }
                formik.resetForm({ values: part })
                setCurrentEditingPart('')
              }}
              disabled={disabled}
            >
              reset
            </Button>
          </TableCell>
          <TableCell align='right'>
            <Button
              sx={{ minWidth: '20px' }}
              size='small'
              variant='contained'
              onClick={handleClickSave}
              disabled={disabled}
            >
              save
            </Button>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default InlinePartEditorGrid
