import { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import PartsDrawer from './PartsDrawer'

const useStyles = makeStyles(theme => ({
  partsSectionHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    backgroundColor: '#4ed7dd',
    padding: '0.5rem',
    borderRadius: '3px 3px 0 0',
    color: '#111',
    borderBottom: '1px solid #0d8c91',
  },
  partsListSectionHeading: {
    fontWeight: 'bold!important',
    borderBottom: '2px solid rgba(0, 0, 0, 0.25)',
    fontSize: '12px!important',
    padding: '.25rem .5rem',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  removed: {
    opacity: 0.85,
    backgroundColor: '#eaeae8',
  },
  returned: {
    backgroundColor: '#eaeae8',
  },
  readyForPickup: {
    backgroundColor: '#90EE9080',
  },
  waitingToArrive: {
    backgroundColor: '#77889940',
  },
  waitingToBeOrdered: {
    backgroundColor: '#f0808040',
  },
  needsToBeReturned: {
    backgroundColor: '#ff00ff4f',
  },
}))

const Parts = ({ parts, job, refetch }) => {
  const classes = useStyles()
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)

  const partNotCanceledReturnedOrRefunded = part =>
    !part.canceledAt && !part.returnInitiatedAt && !part.refundedAt && !part.removedFromQuoteAt
  const partsReadyForPickupAndUse = parts.filter(
    part => part.readyForPickupAt && partNotCanceledReturnedOrRefunded(part)
  )
  const partsWaitingForReadyForPickup = parts.filter(
    part => part.orderPlacedAt && !part.readyForPickupAt && partNotCanceledReturnedOrRefunded(part)
  )
  const partsWaitingToBeOrdered = parts.filter(part => !part.orderPlacedAt && partNotCanceledReturnedOrRefunded(part))
  const partsThatNeedToBeReturned = parts.filter(
    part =>
      (part.returnInitiatedAt && !part.returnedToVendorAt) ||
      (part.coreRefundInitiatedAt && !part.coreReturnedToVendorAt)
  )
  const returnedParts = parts.filter(part => part.returnedToVendorAt || part.coreReturnedToVendorAt)

  return (
    <>
      <Paper onClick={() => setDrawerIsOpen(true)}>
        <Box
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          className={classes.partsSectionHeading}
        >
          <Typography>
            <b>Parts</b>
          </Typography>
          <Button
            size='small'
            sx={{ p: 0 }}
            onClick={() => setDrawerIsOpen(true)}
            cy-data='parts-click-for-details-button'
          >
            Click for Details
          </Button>
        </Box>

        {partsReadyForPickupAndUse.length > 0 && (
          <>
            <Typography className={classes.partsListSectionHeading} style={{ backgroundColor: '#90EE90' }}>
              READY FOR PICK UP &#8212; {partsReadyForPickupAndUse.length}
            </Typography>
            <PartsList parts={partsReadyForPickupAndUse} bgColor='#90EE9080' />
          </>
        )}

        {partsWaitingForReadyForPickup.length > 0 && (
          <>
            <Typography className={classes.partsListSectionHeading} style={{ backgroundColor: '#778899bf' }}>
              WAITING TO ARRIVE &#8212; {partsWaitingForReadyForPickup.length}
            </Typography>
            <PartsList parts={partsWaitingForReadyForPickup} bgColor='#77889940' />
          </>
        )}

        {partsWaitingToBeOrdered.length > 0 && (
          <>
            <Typography className={classes.partsListSectionHeading} style={{ backgroundColor: '#f08080' }}>
              WAITING TO BE ORDERED &#8212; {partsWaitingToBeOrdered.length}
            </Typography>
            <PartsList parts={partsWaitingToBeOrdered} bgColor='#f0808040' />
          </>
        )}

        {partsThatNeedToBeReturned.length > 0 && (
          <>
            <Typography className={classes.partsListSectionHeading} style={{ backgroundColor: 'magenta' }}>
              PARTS TO RETURN &#8212; {partsThatNeedToBeReturned.length}
            </Typography>
            <PartsList parts={partsThatNeedToBeReturned} bgColor='#ff00ff4f' />
          </>
        )}

        {returnedParts.length > 0 && (
          <>
            <Typography className={classes.partsListSectionHeading} style={{ backgroundColor: '#aaa' }}>
              RETURNED PARTS &#8212; {returnedParts.length}
            </Typography>
            <PartsList parts={returnedParts} bgColor='#eaeae8' />
          </>
        )}
      </Paper>
      <PartsDrawer
        drawerIsOpen={drawerIsOpen}
        setDrawerIsOpen={setDrawerIsOpen}
        parts={parts}
        job={job}
        classes={classes}
        refetch={refetch}
      />
    </>
  )
}

const PartsList = ({ parts, bgColor }) => {
  return parts.map((part, index) => (
    <Paper
      key={part.id}
      style={{
        margin: '0px',
        background: bgColor,
        borderBottom: index === parts.length - 1 ? '0px' : '2px solid rgba(0, 0, 0, 0.25)',
        borderRadius: '0px',
        padding: '.25rem 0rem',
        position: 'relative',
      }}
      elevation='0'
    >
      <Grid container style={{ padding: '0 0.5em' }} spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              Name: <b>{part.name}</b>
            </Grid>
            <Grid item xs={12}>
              Number: <b>{part.number}</b>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  ))
}

export default Parts
