import { useState, useCallback, useMemo } from 'react'
import localForage from 'localforage'

const get = async (key, setReady) => {
  const v = await localForage.getItem(key)
  setReady(true)
  return v
}

const set = (key, setVal) => async val => {
  await localForage.setItem(key, val)
  setVal(val)
}

const useLocalForage = (key, initialValue) => {
  const [val, setVal] = useState(initialValue)
  const [ready, setReady] = useState(false)
  const setValue = useCallback(set(key, setVal), [key])

  useMemo(() => get(key, setReady).then(v => v && setVal(v)), [key])

  return [val, setValue, ready]
}

export default useLocalForage
