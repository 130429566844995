import { Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material'
import { useQuery, gql } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import { bearerTokenHeaders } from 'tools'
import { UserContext } from 'UserStore'
import { TechnicianContext } from 'GlobalStore'

const VEHICLE_YEARS_QUERY = gql`
  query fgVehicleYears {
    fgVehicleYears
  }
`

const VEHICLE_MAKE_QUERY = gql`
  query fgVehicleMakes($year: String) {
    fgVehicleMakes(year: $year) {
      makeId
      makeName
      makeShortName
    }
  }
`

const VEHICLE_MODEL_QUERY = gql`
  query fgVehicleModels($year: String, $makeShortName: String) {
    fgVehicleModels(year: $year, makeShortName: $makeShortName) {
      modelId
      modelName
      modelShortName
    }
  }
`

export const VehiclePicker = ({ invoice }: { invoice: any }) => {
  const [technician] = useContext(TechnicianContext)
  const [user] = useContext(UserContext)
  const [selectsDisabled, setSelectsDisabled] = useState(!!invoice.completedAt)

  useEffect(() => {
    invoice && setSelectsDisabled(!!invoice.completedAt)
  }, [invoice])

  const { data: yearsData, loading: yearsLoading, error: yearsError } = useQuery(VEHICLE_YEARS_QUERY)

  const {
    data: makesData,
    loading: makesLoading,
    error: makesError,
  } = useQuery(VEHICLE_MAKE_QUERY, {
    variables: {
      year: invoice?.year?.toString(),
    },
  })

  const {
    data: modelsData,
    loading: modelsLoading,
    error: modelsError,
  } = useQuery(VEHICLE_MODEL_QUERY, {
    variables: {
      year: invoice?.year?.toString(),
      makeShortName: invoice?.makeShortName,
    },
  })

  const updateAttribute = (attr: any) => {
    setSelectsDisabled(true)

    fetch(`https://${process.env.REACT_APP_API_HOST}/update_b_to_b_invoice_attributes`, {
      method: 'POST',
      headers: bearerTokenHeaders(user.token),
      body: JSON.stringify({
        bToBInvoiceId: invoice.id,
        updatedAttributes: attr,
        actorType: 'technician',
        actorId: technician.id,
      }),
    })
      .catch(() => window.alert('Error'))
      .finally(() => setSelectsDisabled(false))
  }

  const handleSelectedYear = (year: Number) => updateAttribute({ year })
  const handleSelectedMake = (make: string) =>
    updateAttribute({
      make: make,
      makeShortName: make
        .replace(/[^a-zA-Z0-9]/, ' ')
        .replace(/\s+/, '_')
        .toLowerCase()
        .trim(),
    })
  const handleSelectedModel = (model: string) =>
    updateAttribute({
      model: model,
      modelShortName: model
        .replace(/[^a-zA-Z0-9]/, ' ')
        .replace(/\s+/, '_')
        .toLowerCase()
        .trim(),
    })

  const yearsForSelect =
    (yearsData && yearsData.fgVehicleYears.slice().sort((a: any, b: any) => (a < b ? 1 : -1))) || []

  const makesForSelect =
    (makesData && makesData.fgVehicleMakes.slice().sort((a: any, b: any) => (a.makeName > b.makeName ? 1 : -1))) || []

  const modelsForSelect =
    (modelsData && modelsData.fgVehicleModels.slice().sort((a: any, b: any) => (a.modelName > b.modelName ? 1 : -1))) ||
    []

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={4} sx={{ pr: '.5rem' }}>
          <FormControl fullWidth size='small'>
            <InputLabel>Year</InputLabel>
            <Select
              disabled={selectsDisabled}
              value={invoice.year}
              label='year'
              onChange={e => handleSelectedYear(e.target.value)}
            >
              {yearsForSelect.map((year: string) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4} sx={{ pl: '.5rem', pr: '.5rem' }}>
          <FormControl fullWidth size='small'>
            <InputLabel>Make</InputLabel>
            <Select
              value={invoice.make}
              label='make'
              disabled={makesLoading || selectsDisabled}
              onChange={e => handleSelectedMake(e.target.value)}
            >
              {invoice.make && (
                <MenuItem disabled value={invoice.make}>
                  {invoice.make}
                </MenuItem>
              )}
              {makesForSelect.map(({ makeShortName, makeName }: { makeShortName: string; makeName: string }) => (
                <MenuItem key={makeShortName} value={makeName}>
                  {makeName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4} sx={{ pl: '.5rem' }}>
          <FormControl fullWidth size='small'>
            <InputLabel>Model</InputLabel>
            <Select
              value={invoice.model}
              label='model'
              disabled={modelsLoading || selectsDisabled || !invoice.make}
              onChange={e => handleSelectedModel(e.target.value)}
            >
              {invoice.model && modelsForSelect.length === 0 && (
                <MenuItem disabled value={invoice.model}>
                  {invoice.model}
                </MenuItem>
              )}
              {modelsForSelect.map(({ modelName, modelShortName }: { modelName: string; modelShortName: string }) => (
                <MenuItem key={modelShortName} value={modelName}>
                  {modelName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  )
}
