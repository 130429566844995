import React, { useContext } from 'react'
import { isIOS, isAndroid, isMobileSafari, osName, osVersion, browserName, getUA } from 'react-device-detect'
import { useTheme, Button } from '@mui/material'

import { UserContext } from 'UserStore'
import { Dollars } from 'tools'

const SQUARE_APP_ID = process.env.REACT_APP_SQUARE_APP_ID
const SQUARE_CALLBACK_URL = `https://${process.env.REACT_APP_API_HOST}/square_payment_callback_58bf4d77-5301-48c2-85b0-0b10998cba5b`

const PaymentButton = ({ lead, quote, job, disabled }) => {
  const [user] = useContext(UserContext)
  const theme = useTheme()

  const squarePosNote = `Quote$${quote.id}`
  const squareChargeAmount = Number(quote.balanceAmountDue) * 100
  const squareCallbackState = {
    lead_id: lead.id,
    quote_id: quote.id,
    job_id: job.id,
    technician_id: user.id,
  }

  const iosSquareParams = {
    amount_money: {
      amount: squareChargeAmount,
      currency_code: 'USD',
    },
    callback_url: SQUARE_CALLBACK_URL,
    client_id: SQUARE_APP_ID,
    version: '1.3',
    notes: squarePosNote,
    options: {
      supported_tender_types: ['CREDIT_CARD', 'CASH'],
    },
    state: encodeURIComponent(JSON.stringify(squareCallbackState)),
  }

  const androidSquarePaymentHref = `intent:#Intent;action=com.squareup.pos.action.CHARGE;package=com.squareup;S.com.squareup.pos.WEB_CALLBACK_URI=${SQUARE_CALLBACK_URL};S.com.squareup.pos.CLIENT_ID=${SQUARE_APP_ID};S.com.squareup.pos.NOTE=${squarePosNote};S.com.squareup.pos.REQUEST_METADATA=${encodeURIComponent(
    JSON.stringify(squareCallbackState)
  )};S.com.squareup.pos.API_VERSION=v2.0;i.com.squareup.pos.TOTAL_AMOUNT=${squareChargeAmount};S.com.squareup.pos.CURRENCY_CODE=USD;S.com.squareup.pos.TENDER_TYPES=com.squareup.pos.TENDER_CARD,com.squareup.pos.TENDER_CASH;end`

  if (!isAndroid && !isIOS && !isMobileSafari && osName !== 'Linux')
    return (
      <>
        [<span style={{ color: 'red' }}>Error: Send to Square requires Android or iOS. </span>
        <span>
          OS:"{osName}" Version:"{osVersion}" Browser:"{browserName}" User-Agent: "{getUA}"
        </span>
        ]
      </>
    )

  return (
    <Button disabled={disabled} variant='contained' color='primary'>
      {/*
        ///
        ///
          Android phones may display OS name as "Linux" when the browser is set to display as desktop site.
          This will display Android payment link if the device is definitely Android OR the OS is Linux and definitely not iOS
        ///
        ///
      */}
      {(isAndroid || osName === 'Linux') && !isIOS && !isMobileSafari && (
        <div>
          <a style={{ color: theme.palette.mode === 'dark' ? '#111' : '#fff' }} href={androidSquarePaymentHref}>
            pay with square: <Dollars value={quote.balanceAmountDue} />
          </a>
        </div>
      )}

      {(isIOS || isMobileSafari) && (
        <div>
          <a
            style={{ color: theme.palette.mode === 'dark' ? '#111' : '#fff' }}
            href={`square-commerce-v1://payment/create?data=${encodeURIComponent(JSON.stringify(iosSquareParams))}`}
          >
            pay with square: <Dollars value={quote.balanceAmountDue} />
          </a>
        </div>
      )}
    </Button>
  )
}

export default PaymentButton
