import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  workOrderCard: {
    padding: '.125rem 1rem .25rem 1rem',
    borderTop: '0px',
    color: '#555',
    '& p': {
      display: 'block',
      fontFamily: 'Open Sans',
      color: '#3E3745',
      fontWeight: 600,
      paddingBottom: '0px',
      margin: '0px',
    },
    '& span': {
      fontFamily: 'Open Sans',
      color: '#3E3745',
    },
  },
  summaryCard: {
    padding: '.5rem',
    marginBottom: '1.5rem',
  },
  payoutsTitle: {
    fontsize: '2rem',
    fontFamily: 'Open Sans',
    color: '#373845',
    fontWeight: 700,
    display: 'block',
  },
  sectionHeader: {
    borderTop: '1px solid ' + (theme.mode === 'dark' ? '#fff' : '#373845' || '#757575'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem .5rem',
  },
  fixedBottomContainer: {
    position: 'fixed',
    bottom: '0',
    background: '#fff',
    zIndex: '10',
    borderTop: '1px solid #cacac8',
    padding: '16px 8px',
    minWidth: '100%',
    marginLeft: '-8px!important',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
