import React, { useContext } from 'react'
import { UserContext } from './UserStore'
import Chat from 'Chat'
import PhotoUploader from './PhotoUploader'

const BToBChat = () => {
  const [user] = useContext(UserContext)

  return <>
    <Chat contextType="TechnicianBToB" contextId={user.id} />
    <br />
    <PhotoUploader />
  </>
}

export default BToBChat
