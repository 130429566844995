import React, { useState, useLayoutEffect, useContext, useEffect, useMemo } from 'react'
import { useQuery, gql } from '@apollo/client'
import { UserContext } from './UserStore'
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material'
import { theme, darkTheme } from 'presentation/styles/tech-app.theme'
import { logout } from 'auth'

import Loading from 'presentation/components/Loading'
import localforage from 'localforage'

export const GlobalContext = React.createContext()
export const TechnicianContext = React.createContext()
export const ThemeContext = React.createContext()
export const TechnicianChannelContext = React.createContext()

const USERS_QUERY = gql`
  query users {
    users {
      id
      firstName
      lastName
    }
  }
`
const TECHNICIAN_QUERY = gql`
  query technician($id: ID!) {
    technician(id: $id) {
      id
      email
      firstName
      lastName
      businessName
      commissionPercent
      commissionAmount
      state
      market
      isEmployee
      isBToB
      bToBBusinessName
      notifications
      hourlyRate
      quoteCommissionStructure
      suppliesCostPercentage
      netLaborRevenuePercentage
      bToBDealers {
        id
        businessName
      }
    }
  }
`

const ThemeStore = ({ children }) => {
  const [darkmode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'true' ? true : false)
  const [currentTheme, setCurrentTheme] = useState(localStorage.getItem('darkMode') === 'true' ? darkTheme : theme)

  useEffect(() => {
    setCurrentTheme(theme)
    // if (darkmode) {
    //   setCurrentTheme(darkTheme)
    // }
    // else {
    //   setCurrentTheme(theme)
    // }
  }, [darkmode])

  return (
    <ThemeProvider theme={createTheme(currentTheme)}>
      <CssBaseline />
      <ThemeContext.Provider value={[darkmode, setDarkMode]}>{children}</ThemeContext.Provider>
    </ThemeProvider>
  )
}

// const TechnicianChannelStore = ({ children }) => {
//   const [technicianChannel, setTechnicianChannel] = useState(null)

//   return <TechnicianContext.Provider value={[technician, setTechnician]}>
//     {children}
//   </TechnicianContext.Provider>
// }

const TechnicianStore = ({ children }) => {
  const [user] = useContext(UserContext)
  const { loading, data, error } = useQuery(TECHNICIAN_QUERY, {
    variables: { id: user.id },
  })

  const technician = useMemo(() => data?.technician, [data])

  if (loading) return <Loading />
  if (error) {
    window.setTimeout(async () => {
      await localforage.removeItem('user')
      window.location.reload()
    }, 0)
    return <>You have been logged out...</>
  }

  return <TechnicianContext.Provider value={[technician, !!technician]}>{children}</TechnicianContext.Provider>
}

const GlobalStore = ({ children }) => {
  const { loading, error, data } = useQuery(USERS_QUERY)

  const global = useMemo(() => ({ csrs: data?.users }), [data])
  const ready = useMemo(() => !!global.csrs, [global])

  useEffect(() => error && logout(), [error])

  if (loading) return <Loading />

  return (
    <GlobalContext.Provider value={[global, ready]}>
      <TechnicianStore>
        <ThemeStore>{children}</ThemeStore>
      </TechnicianStore>
    </GlobalContext.Provider>
  )
}

export default GlobalStore
