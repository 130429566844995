import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { API_COMMAND_HEADERS } from 'tools'
import { login } from 'auth'

const LoginByTechnicianId = () => {
  const { technicianId } = useParams()

  useEffect(() => {
    fetch(`https://${process.env.REACT_APP_API_HOST}/app_session_by_technician_id`, {
      method: 'POST',
      headers: API_COMMAND_HEADERS,
      body: JSON.stringify({
        technician_id: technicianId,
      }),
    })
      .then(async res => {
        if (res.ok) login(await res.json())
        else throw 'Error'
      })
      .catch(e => console.error(e))
  }, [])

  return 'Logging you in ...'
}

export default LoginByTechnicianId
