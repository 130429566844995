import { useContext } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'

import Index from './Index'
import New from './components/New'
import Open from './Open'
import SuppliesOrderRouter from '../SuppliesOrder/Router'
import { TechnicianContext } from 'GlobalStore'

const ITEMS_QUERY = gql`
  query suppliesOrderItems($state: String!) {
    suppliesOrderItems(state: $state) {
      id
      type
      description
      size
      quantity
    }
  }
`

const Router = () => {
  const { path } = useRouteMatch()
  const [technician] = useContext(TechnicianContext)
  const { loading, error, data } = useQuery(ITEMS_QUERY, {
    variables: { state: technician.state },
  })

  if (error) return <div>Error!</div>
  if (loading) return <div>Loading...</div>

  const items = data ? [...data.suppliesOrderItems] : []
  // const orders = ordersData ? [...ordersData.suppliesOrders] : []
  // const { open: ordersOpen, done: ordersDone } = mapOrdersByPlaced(orders)

  return (
    <Switch>
      <Route path={`/supplies-orders/new`}>
        <New items={items} />
      </Route>
      <Route path={`/supplies-orders/open`}>
        <Open items={items} />
      </Route>
      <Route path={`/supplies-orders/:suppliesOrderId`}>
        <SuppliesOrderRouter items={items} />
      </Route>
      <Route path={path}>
        <Index items={items} />
      </Route>
    </Switch>
  )
}

export default Router
