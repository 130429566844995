import { useState, useEffect } from 'react'

export const useLocalStorage = (storageKey, fallbackState, parseState = false) => {
  const stateExists = !!localStorage.getItem(storageKey)

  const getLocalStorageValue = () => {
    if (parseState) {
     return  stateExists ? JSON.parse(localStorage.getItem(storageKey)) : null
    } else {
     return  stateExists ? localStorage.getItem(storageKey) : null
    }
  }
  const [value, setValue] = useState(getLocalStorageValue() ?? fallbackState)

  useEffect(() => {
    !parseState && localStorage.setItem(storageKey, value)
    parseState && localStorage.setItem(storageKey, JSON.stringify(value))
  }, [value, storageKey])

  return [value, setValue]
}
