import { bearerTokenHeaders, getUploadParamsForDropzone } from 'tools'
import Dropzone from 'react-dropzone-uploader'
import { UserContext } from 'UserStore'
import { TechnicianContext } from 'GlobalStore'
import { useContext } from 'react'

export const Uploader = ({ url, closeDrawer, multiple, workOrder, refetch }) => {
  const [user] = useContext(UserContext)
  const [technician] = useContext(TechnicianContext)

  const handleSubmit = files =>
    fetch(`https://${process.env.REACT_APP_API_HOST}/${url}`, {
      method: 'POST',
      headers: bearerTokenHeaders(user.token),
      body: JSON.stringify({
        actor_id: technician.id,
        actor_type: 'technician',
        photo_urls: files.map(file => file.meta.fileUrl),
        photo_url: files.map(file => file.meta.fileUrl)[0],
        b_to_b_work_order_id: workOrder.id,
      }),
    })
      .then(res => {
        if (res.ok) {
          files.forEach(file => file.remove())
          closeDrawer()
          refetch()

          return true
        } else {
          throw Error('error')
        }
      })
      .catch(e => window.alert(e.message))

  return (
    <Dropzone
      getUploadParams={getUploadParamsForDropzone}
      multiple={multiple}
      accept='image/jpg,image/jpeg,image/png'
      onSubmit={handleSubmit}
      submitButton
      inputContent='Click to Select Photos to Upload'
    />
  )
}
