import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Geocode from 'react-geocode'
import {
  DistanceMatrixService, Marker,
  GoogleMap, useLoadScript
} from '@react-google-maps/api'

import Launch from '@mui/icons-material/Launch'

import LoadingIndicator from 'presentation/components/LoadingIndicator'
import { addressDirectionsLink } from 'tools'

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
Geocode.setApiKey(GOOGLE_MAPS_API_KEY)

const useStyles = makeStyles(theme => ({
  mapsLinks: {
    padding: '0.75rem',
    display: 'flex',
    background: 'rgba(13, 51, 147, 0.75)',
    color: '#fff',
    fontSize: '1.125rem',
    borderRadius: '0 0 4px 4px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderTop: '0',
    textDecoration: 'none'
  }
}))

const Map = ({ address, label, showOpenInMapsLink }) => {
  const classes = useStyles()
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null })
  const [googleApi, setGoogleApi] = useState(null)
  const [mapInstance, setMapInstance] = useState(null)

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY
  })

  const onLoad = React.useCallback(
    function onLoad (mapInstance) {
      setMapInstance(mapInstance)
      setGoogleApi(window.google)
    }
  )

  useEffect(() => {
    if (address.addressLineOne) {
      Geocode.fromAddress(`${address.addressLineOne}, ${address.city}, ${address.state}`).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location
          setCoordinates({ lat, lng })
        },
        error => {
          console.error(error)
        }
      )
    }
    return () => {
      setGoogleApi(null)
      setMapInstance(null)
    }
  }, [])

  const renderMap = () => {
    if (coordinates.lat && coordinates.lng) {
      return (
        <>
          <GoogleMap
            mapContainerStyle={{
              height: '300px',
              width: '100%'
            }}
            zoom={9}
            center={coordinates}
            onLoad={onLoad}
          >
            <Marker position={coordinates} shape='' label={label} />
          </GoogleMap>
          {showOpenInMapsLink &&
            <a
              className={classes.mapsLinks}
              target='_blank'
              rel='noreferrer'
              href={addressDirectionsLink(address)}
            >
              Open on Google Maps
              <Launch style={{ marginLeft: '1rem' }} />
            </a>}
        </>
      )
    } else {
      return <LoadingIndicator message='Loading Google Map Data...' height='208px' />
    }
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : <LoadingIndicator message='Loading Google Map Data...' />
}

export default Map
