import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from 'GlobalStore'
import { UserContext } from 'UserStore'
import { DateTime } from 'luxon'
import { TextField, Button, Typography, Modal } from '@mui/material'
import { basicDateTimeFormat, getUploadParamsForDropzone } from 'tools'
import { useChannel, useEventHandler } from '@ericlathrop/phoenix-js-react-hooks'
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@mui/material/Skeleton'
import VisibilitySensor from 'react-visibility-sensor-v2'

const Styles = makeStyles(theme => ({
  modalClose: {
    top: '0.5em',
    right: '0.5em',
    position: 'absolute',
    fontSize: '1.5em',
    color: 'red',
    border: '1px solid red',
    padding: '0 0.3em',
    borderRadius: '5px',
    zIndex: '10',
    cursor: 'pointer',
    backgroundColor: 'white'
  },
  modalPaper: {
    display: 'flex',
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    boxShadow: theme.shadows[5],
    padding: 0
  }
}))

const Chat = ({ contextType, contextId }) => {
  const classes = Styles()
  const [messages, setMessages] = useState([])
  const [global] = useContext(GlobalContext)
  const [user] = useContext(UserContext)
  const [body, setBody] = useState('')
  const [photoModalUrl, setPhotoModalUrl] = useState(null)
  const [loadingInitialMessages, setLoadingInitialMessages] = useState(true)

  const windowHeight = window && window.innerHeight

  const scrollToBottom = () => {
    let element = document.getElementById('chatMessageList')
    if (element)
      element.scrollTop = element.scrollHeight - element.clientHeight
  }

  const channel = useChannel(`chat:${contextType}$${contextId}`, undefined, (channel, { chatMessages }) => {
    setMessages(
      chatMessages.map((message, index) =>
        ({...message, renderPhotos: index < 4})
      ).reverse()
    )
    setTimeout(() => {
      setLoadingInitialMessages(false)
      scrollToBottom()
    }, 300)
  })

  useEventHandler(channel, 'new_chat_message', ({chatMessage}) => {
    setMessages(messages => [...messages, {...chatMessage, renderPhotos: true}])
    setTimeout(scrollToBottom, 250)
  })

  const csrById = id => global.csrs.find(csr => csr.id === id)
  const csrName = id => {
    let csr = csrById(id)
    return `${csr.firstName} ${csr.lastName[0]}.`
  }

  const authorName = message =>
    message.authorType === 'csr'
      && csrName(message.authorId)
    || message.authorType === 'tech'
      && message.authorId === user.id
      && 'me'

  const createChatMessage = () =>
    fetch(`https://${process.env.REACT_APP_API_HOST}/create_chat_message`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user.token}`
      },
      body: JSON.stringify({
        authorId: user.id,
        authorType: 'tech',
        body: body.trim(),
        contextType: contextType,
        contextId: contextId,
      })
    })
    .then( response => {
      if (response.ok) {
        setBody('')
        return true
      } else {
        throw Error('error')
      }
    })
    .catch( e => {
      alert(e.message)
    })

  const handleVisibilityOfMessagePhotos = (messageId, visibility) => {
    if (!visibility)
      return null

    setMessages(messages =>
      messages.map(message => message.id === messageId ? {...message, renderPhotos: true} : message)
    )
  }

  return (
    <div>
      <Typography variant="h6">Business to Business activity</Typography>

      <div
        id="chatMessageList"
        style={{
          fontSize: '1.1em',
          height: `${windowHeight * 0.5}px`,
          overflow: 'auto',
          backgroundColor: 'white'
        }}
      >
        {loadingInitialMessages && <div>Loading...</div> || messages.length === 0 &&
        <div style={{marginTop: '6em'}}>
          Post photos and messages for Business-to-Business invoice activity here
          <br />
          <br />
          Please do not text us this info any longer
        </div> }

        {messages.map(message => <div key={message.id}>
          <div style={{whiteSpace: 'pre-wrap', padding: '0.5em', margin: '0.2em'}}>
            <b>{authorName(message)}</b>&nbsp;
            <small style={{color: '#747474'}}>{DateTime.fromISO(message.createdAt).toLocaleString(DateTime.DATETIME_FULL)}</small>
            <br />
            <div style={{margin: '0 0.75em'}}>
            {message.body}
            </div>
            {(message.mediaUrls || []).map(mediaUrl => {
              let photoSideNumber = Math.floor(Math.max((120 / message.mediaUrls.length), 80))
              let photoSidePx = `${photoSideNumber}px`

              return <div
                key={mediaUrl}
                style={{
                  border: '2px solid #efefef',
                  display: 'inline-block',
                  width: photoSidePx,
                  height: photoSidePx
                }}
                onClick={() => setPhotoModalUrl(mediaUrl)}
              >
                <VisibilitySensor
                  onChange={isVisible => handleVisibilityOfMessagePhotos(message.id, isVisible)}
                  intervalDelay={250}
                >
                  {message.renderPhotos &&
                    <img
                      src={mediaUrl}
                      style={{
                        objectFit: 'cover',
                        height: photoSidePx,
                        width: photoSidePx,
                        cursor: 'pointer'
                      }}
                    />
                      ||
                    <Skeleton variant="rectangular" width={photoSideNumber} height={photoSideNumber} />
                  }
                </VisibilitySensor>
              </div>
            })}
          </div>
        </div>)}
      </div>

      <TextField value={body}
        style={{backgroundColor: 'white', width: '100%'}}
        multiline
        onChange={e => setBody(e.target.value)}
      />

      <Button style={{marginTop: '5px'}} variant="outlined" color="primary" disabled={body.length === 0} onClick={createChatMessage}>Send</Button>

      <Modal
        open={!!photoModalUrl}
        onClose={() => setPhotoModalUrl(null)}
      >
        <div className={classes.modalPaper}>
          <div
            onClick={() => setPhotoModalUrl(null)}
            className={classes.modalClose}
          >
            X
          </div>
          <PinchZoomPan position='center' maxScale={2}>
            <img src={photoModalUrl} />
          </PinchZoomPan>
        </div>
      </Modal>
    </div>
  )
}

export default Chat
