import { Switch, Route, useParams } from 'react-router-dom'
import { useLazyQuery, gql } from '@apollo/client'
import { useEffect, useContext } from 'react'
import { keysToCamel } from 'tools'
import ShowEdit from './ShowEdit'
import { TechnicianChannelContext } from 'TechnicianChannelStore'
import { useEventHandler } from '@ericlathrop/phoenix-js-react-hooks'

const QUERY = gql`
  query bToBWorkOrder($id: ID!) {
    bToBWorkOrder(id: $id) {
      id
      acceptedAt
      createdAt
      completedAt
      businessName
      preJobPhotos
      year
      make
      makeShortName
      model
      modelShortName
      vinPhoto
      completedAt
      deletedAt
      dealerId
      partIds
      lineItems
      stock
      repairOrder
      parts {
        id
        name
      }
    }
  }
`

const BtoBWorkOrderRouter = ({ workOrder: prefetchedWorkOrder }) => {
  const { bToBWorkOrderId } = useParams()
  const channel = useContext(TechnicianChannelContext)

  const [getWorkOrder, { data, error, refetch }] = useLazyQuery(QUERY, {
    variables: { id: bToBWorkOrderId },
  })

  useEventHandler(channel, 'b_to_b_work_order_updated', ({ bToBWorkOrder }) => {
    // bToBWorkOrder.id === bToBWorkOrderId && setUpdatedInvoice(bToBWorkOrder)
    bToBWorkOrder.id === bToBWorkOrderId && refetch()
  })

  useEffect(() => {
    prefetchedWorkOrder || getWorkOrder()
  }, [])

  const workOrder = (data && data.bToBWorkOrder) || prefetchedWorkOrder

  if (!workOrder) return <>loading...</>
  if (error) return <>error...</>

  return (
    <Switch>
      <Route path='/b-to-b-work-orders/:bToBWorkOrderId'>
        <ShowEdit refetch={refetch} workOrder={keysToCamel(workOrder)} />
      </Route>
    </Switch>
  )
}

export default BtoBWorkOrderRouter
