import {
  Grid,
  TableBody,
  TableCell,
  TableRow,
  Table,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  Button,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { Dollars, formatFullDate, Percent, underscoreToTitleCase } from '../../../../tools'
import { useStyles } from 'presentation/pages/Payout/styles'
import { useState } from 'react'

export const WorkOrders = ({ sortedWorkOrders }) => {
  const [showingDetails, setShowingDetails] = useState(false)
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <>
      {sortedWorkOrders.length > 0 && (
        <>
          <Grid item xs={12} className={classes.sectionHeader}>
            <div style={{ color: theme.mode === 'dark' ? '#fff' : '#373845', fontSize: '1.125rem' }}> Work Orders </div>
            <div style={{ textAlign: 'right', fontSize: '.9rem' }}>
              <Button
                size='small'
                variant='outlined'
                onClick={() => {
                  setShowingDetails(!showingDetails)
                }}
              >
                <span style={{ lineHeight: '19px' }}>{showingDetails ? 'Hide' : 'More'} info</span>
              </Button>
            </div>
          </Grid>
          {sortedWorkOrders.map((workOrder, index) => {
            const payoutData = workOrder.payoutData
            const lead = workOrder.lead

            return (
              <Accordion className={classes.workOrderCard} elevation={1} key={index}>
                <AccordionSummary expandIcon={<ExpandMore />} style={{ padding: '0px', width: '100%' }}>
                  <Grid container>
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ fontWeight: 600, color: '#999', fontSize: '1.75rem' }}>
                        <Dollars value={payoutData.totalPayout} />
                      </span>
                      <span
                        style={{
                          color: '#545454',
                          fontSize: '1rem',
                          marginLeft: 'auto',
                          marginRight: '1rem',
                          display: 'block',
                          textAlign: 'right',
                        }}
                      >
                        <div>{formatFullDate(workOrder.mostRecentJob.startDatetime)}</div>
                        <div style={{ color: '#aaa', fontSize: '.8rem' }}>
                          {lead.year} {underscoreToTitleCase(lead.makeShortName)}{' '}
                          {underscoreToTitleCase(lead.modelShortName)}{' '}
                        </div>
                      </span>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '0 0 .75rem 0', borderTop: '1px solid #eee', color: '#3a3a3a' }}>
                  <Table>
                    <TableBody>
                      {showingDetails && (
                        <>
                          <TableRow>
                            <TableCell
                              style={{ padding: '0 0 0 .5rem', borderBottomStyle: 'dotted', borderBottomWidth: 2 }}
                            >
                              Total labor amount
                            </TableCell>
                            <TableCell align='right' style={{ borderBottomStyle: 'dotted', borderBottomWidth: 2 }}>
                              <Dollars
                                color={theme.mode === 'dark' ? '#fff' : '#373845'}
                                value={payoutData.totalLaborAmount}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: '0 0 0 .5rem' }}>Commission percent</TableCell>
                            <TableCell align='right'>{payoutData.commissionPercentApplied}%</TableCell>
                          </TableRow>
                        </>
                      )}

                      <TableRow>
                        <TableCell style={{ padding: '0 0 0 .5rem' }}>Commission</TableCell>
                        <TableCell align='right'>
                          <Dollars
                            color={theme.mode === 'dark' ? '#fff' : '#373845'}
                            value={payoutData.laborCommissionBeforeCcFee}
                          />
                        </TableCell>
                      </TableRow>

                      {showingDetails && (
                        <>
                          <TableRow>
                            <TableCell style={{ padding: '0 0 0 .5rem' }}>Commission credit card fee</TableCell>
                            <TableCell align='right'>
                              (
                              <Dollars
                                color={theme.mode === 'dark' ? '#fff' : '#373845'}
                                value={payoutData.laborCommissionCcFee}
                              />
                              )
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: '0 0 0 .5rem' }}>Commission after credit card fee</TableCell>
                            <TableCell align='right'>
                              <b>
                                <Dollars
                                  color={theme.mode === 'dark' ? '#fff' : '#373845'}
                                  value={payoutData.laborCommissionAfterCcFee}
                                />
                              </b>
                            </TableCell>
                          </TableRow>
                        </>
                      )}

                      {payoutData.fullPayoutItemsAfterCcFee > 0 && (
                        <>
                          <TableRow>
                            <TableCell style={{ padding: '0 0 0 .5rem' }}>Direct Payout Items</TableCell>
                            <TableCell align='right'>
                              <Dollars
                                color={theme.mode === 'dark' ? '#fff' : '#373845'}
                                value={payoutData.fullPayoutItemsBeforeCcFee}
                              />
                            </TableCell>
                          </TableRow>

                          {showingDetails && (
                            <>
                              <TableRow>
                                <TableCell style={{ padding: '0 0 0 .5rem' }}>
                                  Direct payout items credit card fee
                                </TableCell>
                                <TableCell align='right'>
                                  (
                                  <Dollars
                                    color={theme.mode === 'dark' ? '#fff' : '#373845'}
                                    value={payoutData.fullPayoutItemsCcFee}
                                  />
                                  )
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{ padding: '0 0 0 .5rem' }}>
                                  Direct payout after items credit card fee
                                </TableCell>
                                <TableCell align='right'>
                                  <b>
                                    <Dollars
                                      color={theme.mode === 'dark' ? '#fff' : '#373845'}
                                      value={payoutData.fullPayoutItemsAfterCcFee}
                                    />
                                  </b>
                                </TableCell>
                              </TableRow>
                            </>
                          )}
                        </>
                      )}

                      {payoutData.tipAfterFee > 0 && (
                        <>
                          <TableRow>
                            <TableCell style={{ padding: '0 0 0 .5rem' }}>Tip</TableCell>
                            <TableCell align='right'>
                              <Dollars
                                color={theme.mode === 'dark' ? '#fff' : '#373845'}
                                value={payoutData.tipBeforeCcFee}
                              />
                            </TableCell>
                          </TableRow>

                          {showingDetails && (
                            <>
                              <TableRow>
                                <TableCell style={{ padding: '0 0 0 .5rem' }}>Tip credit card fee</TableCell>
                                <TableCell align='right'>
                                  (
                                  <Dollars
                                    color={theme.mode === 'dark' ? '#fff' : '#373845'}
                                    value={payoutData.tipCcFee}
                                  />
                                  )
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{ padding: '0 0 0 .5rem' }}>Tip after credit card fee</TableCell>
                                <TableCell align='right'>
                                  <b>
                                    <Dollars
                                      color={theme.mode === 'dark' ? '#fff' : '#373845'}
                                      value={payoutData.tipAfterFee}
                                    />
                                  </b>
                                </TableCell>
                              </TableRow>
                            </>
                          )}
                        </>
                      )}

                      {payoutData.tipAfterFee === 0 && (
                        <TableRow>
                          <TableCell style={{ padding: '0 0 0 .5rem' }}>Tip</TableCell>
                          <TableCell align='right'>
                            <Dollars color={theme.mode === 'dark' ? '#fff' : '#373845'} value='0' />
                          </TableCell>
                        </TableRow>
                      )}

                      <TableRow>
                        <TableCell style={{ padding: '0 0 0 .5rem' }}>
                          Total credit card fees @&nbsp;
                          {payoutData.deducedCcFeePercent > 0 && (
                            <Percent
                              color={theme.mode === 'dark' ? '#fff' : '#373845'}
                              value={payoutData.deducedCcFeePercent * 100}
                              decimals={2}
                            />
                          )}
                        </TableCell>
                        <TableCell align='right'>
                          (
                          <Dollars
                            color={theme.mode === 'dark' ? '#fff' : '#373845'}
                            value={payoutData.technicianCcFee}
                          />
                          )
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ background: theme.palette.background.highlight }}>
                        <TableCell style={{ borderBottom: 'none', padding: '0 0 0 .5rem', fontWeight: 600 }}>
                          Total payout
                        </TableCell>
                        <TableCell align='right' style={{ borderBottom: 'none', fontWeight: 600 }}>
                          <Dollars color={theme.mode === 'dark' ? '#fff' : '#373845'} value={payoutData.totalPayout} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </>
      )}
    </>
  )
}
