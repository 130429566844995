import React, { useEffect, useState, useContext, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { keysToCamel } from 'tools'
import PostJobPhotosSection from './components/PostJobPhotosSection'
import PreJobPhotosSection from './components/PreJobPhotosSection'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

import Appointment from './components/Appointment'
import CustomerPhotos from './components/CustomerPhotos'
import Labor from './components/Labor'
import Map from './components/Map'
import Notes from './components/Notes'
import Parts from './components/Parts'
import PaymentButton from './components/PaymentButton'
import Payments from './components/Payments'
import LoadingIndicator from 'presentation/components/LoadingIndicator'

import { useEventHandler } from '@ericlathrop/phoenix-js-react-hooks'
import { TechnicianChannelContext } from 'TechnicianChannelStore'
import { JOB } from 'data/queries/Job/Job'

const Job = () => {
  const { jobId } = useParams()
  const channel = useContext(TechnicianChannelContext)
  const messageToCsrRef = useRef(null)
  const [payButtonIsDisabled, setPayButtonIsDisabled] = useState(false)

  const { loading, error, data, refetch } = useQuery(JOB, {
    variables: { id: jobId }
  })

  useEffect(() => {
    const listener = window.addEventListener('visibilitychange', event => {
      event.target.visibilityState === 'visible' && refetch()
    })
    return () => window.removeEventListener('visibilitychange', listener)
  }, [])

  const partOnJob = updatedPart => {
    const quote = data ? data.job.quote : null
    return quote ? quote.parts.find(part => part.id === updatedPart.id) : false
  }

  const handlePartCreatedOrUpdated = ({ part }) => {
    if (partOnJob(part)) {
      refetch()
    }
  }

  useEventHandler(channel, 'part_created', handlePartCreatedOrUpdated)
  useEventHandler(channel, 'part_updated', handlePartCreatedOrUpdated)
  useEventHandler(channel, 'quote_updated', ({ quote }) => {
    const jobQuote = data ? data.job.quote : null
    if (jobQuote && jobQuote.id === quote.id) {
      refetch()
    }
  })

  if (loading) return <LoadingIndicator message='Retreiving Job Data...' />
  if (error) return <div>Error!</div>

  const job = keysToCamel(data.job)
  const quote = job.quote
  const technician = job.technician
  const lead = quote.lead
  const qcr = job.quote.calculatorResult

  const activePartCountByVendorId = quote.parts.reduce((acc, part) => {
    if (['canceled', 'refunded', 'removed', 'returned'].includes(part.status)) {
      return acc
    }
    acc[part.partVendorId] = acc[part.partVendorId] || 0
    acc[part.partVendorId]++
    return acc
  }, {})

  const techHasInterestInPart = part =>
    (part.removedFromQuoteAt && part.orderPlacedAt && activePartCountByVendorId[part.partVendorId]) ||
    !part.removedFromQuoteAt ||
    part.pickupStatus === 'picked_up'
  const parts = quote.parts.filter(part => !['canceled', 'refunded'].includes(part.status) && techHasInterestInPart(part))

  // useEffect(() => {
  //   if (job.postJobPhotoUrls ? (job.postJobPhotoUrls.length > 0) : false ) {
  //     setPayButtonIsDisabled(false)
  //   }
  // }, [job.postJobPhotoUrls])

  // useEffect(() => {
  //   document.documentElement.scrollTop = '0'
  //   document.body.scrollTop = '0'
  // }, [])

  const panelDamageItems = quote.labor && quote.labor.filter(item => item.type !== 'upcharge')
  const upchargeItems = quote.labor && quote.labor.filter(item => item.type === 'upcharge')

  // show tech parts that have been marked as order requested or greater
  // but NOT canceled or greater down the negative path
  const partNotCanceledReturnedOrRefunded = part =>
    !part.canceledAt && !part.returnInitiatedAt && !part.refundedAt && !part.removedFromQuoteAt
  const partsReadyForPickupAndUse = parts.filter(part => part.readyForPickupAt && partNotCanceledReturnedOrRefunded(part))

  const anyReadyPartsMissingPickupStatus = partsReadyForPickupAndUse.reduce((acc, part) => acc || !part.pickupStatus, false)

  return (
    <Container disableGutters>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Appointment job={job} />
        </Grid>

        <PreJobPhotosSection job={job} refetch={refetch} />

        <Grid item xs={12}>
          <Map showOpenInMapsLink address={lead} label={`Customer: ${lead.name} | ${lead.repairLocation}`} />
        </Grid>

        <Grid item xs={12}>
          <CustomerPhotos lead={lead} />
        </Grid>

        {parts.length > 0 && (
          <Grid item xs={12}>
            <Parts parts={parts} job={job} />
          </Grid>
        )}

        <Grid item xs={12}>
          <Notes parentType='job' parentId={job.id} leadId={lead.id} quoteId={quote.id} jobId={job.id} />
        </Grid>

        <Grid item xs={12}>
          <Labor qcr={qcr} quote={quote} />
        </Grid>

        <PostJobPhotosSection setPayButtonIsDisabled={setPayButtonIsDisabled} job={job} refetch={refetch} />

        {Number(quote.balanceAmountDue) > 0 && (
          <Grid item xs={12}>
            {anyReadyPartsMissingPickupStatus && (
              <p style={{ margin: '5px 0', color: 'red' }}>&#9757; Must mark each part as picked-up or not-picked-up &#9757;</p>
            )}

            <PaymentButton lead={lead} quote={quote} job={job} disabled={anyReadyPartsMissingPickupStatus || payButtonIsDisabled} />
          </Grid>
        )}

        {quote.groupedPayments && (
          <Grid item xs={12}>
            <Payments quote={quote} />
          </Grid>
        )}
      </Grid>
    </Container>
  )
}

export default Job
