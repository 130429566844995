import {
  Grid,
  TableBody,
  TableCell,
  TableRow,
  Table,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from '@mui/material'
import Button from '@mui/material/Button'
import { ExpandMore } from '@mui/icons-material'
import { DateTime } from 'luxon'
import { Dollars } from '../../../../tools'
import { useStyles } from 'presentation/pages/Payout/styles'
import { useState } from 'react'

export const TimeCards = ({ timeCards }) => {
  const [showingDetails, setShowingDetails] = useState(false)
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <>
      {timeCards.length > 0 && (
        <>
          <Grid item xs={12} className={classes.sectionHeader}>
            <div style={{ color: theme.mode === 'dark' ? '#fff' : '#373845', fontSize: '1.125rem' }}>
              Weekly Commission {showingDetails && <> + Hourly Pay</>}
            </div>
            <div style={{ textAlign: 'right', fontSize: '.9rem' }}>
              <Button
                size='small'
                variant='outlined'
                onClick={() => {
                  setShowingDetails(!showingDetails)
                }}
              >
                <span style={{ lineHeight: '19px' }}>{showingDetails ? 'Hide' : 'More'} info</span>
              </Button>
            </div>
          </Grid>

          {timeCards.map((timeCard, index) => {
            const payoutData = timeCard.payoutData
            const workLogs = timeCard.timeLogs.filter(workLog => workLog.type === 'work_log')

            return (
              Number(timeCard.payoutData.totalPayout) > 0 && (
                <Accordion className={classes.workOrderCard} elevation={1} key={index}>
                  <AccordionSummary expandIcon={<ExpandMore />} style={{ padding: '0px', width: '100%' }}>
                    <Grid container>
                      <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ fontWeight: 600, color: '#999', fontSize: '1.75rem' }}>
                          <Dollars
                            value={
                              payoutData.totalPayout -
                              payoutData.totalHourlyPay +
                              ((showingDetails && payoutData.totalHourlyPay) || 0)
                            }
                          />
                        </span>
                        <span
                          style={{
                            color: '#545454',
                            fontSize: '1rem',
                            marginLeft: 'auto',
                            marginRight: '1rem',
                            display: 'block',
                            textAlign: 'right',
                          }}
                        >
                          <div>{DateTime.fromFormat(timeCard.timeCardDate, 'yyyy-MM-dd').toFormat('DD')}</div>
                          <div style={{ color: '#aaa', fontSize: '.8rem' }}></div>
                        </span>
                      </Grid>
                    </Grid>
                  </AccordionSummary>

                  <AccordionDetails style={{ padding: '0 0 .75rem 0', borderTop: '1px solid #eee', color: '#3a3a3a' }}>
                    <Table>
                      <TableBody>
                        {showingDetails && (
                          <>
                            <TableRow sx={{ background: '#f1f1f1' }}>
                              <TableCell style={{ padding: '0 0 0 .5rem' }}>Work hours</TableCell>
                              <TableCell align='right'>
                                <b>{payoutData.totalWorkHours.toFixed(2)}</b>
                              </TableCell>
                            </TableRow>
                            <TableRow sx={{ background: '#f1f1f1' }}>
                              <TableCell style={{ padding: '0 0 0 .5rem' }}>Paid travel hours</TableCell>
                              <TableCell align='right'>
                                <b>{payoutData.paidTravelTotalHours.toFixed(2)}</b>
                              </TableCell>
                            </TableRow>
                            <TableRow sx={{ background: '#f1f1f1' }}>
                              <TableCell style={{ padding: '0 0 0 .5rem' }}>Unpaid travel hours</TableCell>
                              <TableCell align='right'>
                                <b>{payoutData.unpaidTravelTotalHours.toFixed(2)}</b>
                              </TableCell>
                            </TableRow>
                            <TableRow sx={{ background: '#f1f1f1' }}>
                              <TableCell style={{ padding: '0 0 0 .5rem' }}>Total hours</TableCell>
                              <TableCell align='right'>
                                <b>{payoutData.totalHours.toFixed(2)}</b>
                              </TableCell>
                            </TableRow>
                            <TableRow sx={{ background: '#f1f1f1' }}>
                              <TableCell style={{ padding: '0 0 0 .5rem' }}>Total hourly pay</TableCell>
                              <TableCell align='right'>
                                <b>
                                  <Dollars value={payoutData.totalHourlyPay} />
                                </b>
                              </TableCell>
                            </TableRow>
                          </>
                        )}

                        {workLogs.map(workLog => (
                          <TableRow key={workLog.id}>
                            <TableCell style={{ padding: '.25rem 0 .25rem .5rem' }}>
                              {workLog?.job?.lead?.year} {workLog?.job?.lead?.make} {workLog?.job?.lead?.model} <br />
                              <span style={{ fontSize: '11px', color: '#777' }}>
                                {' '}
                                {workLog.payoutData?.commissionDescription}
                              </span>
                            </TableCell>
                            <TableCell align='right'>
                              <Dollars
                                color={theme.mode === 'dark' ? '#fff' : '#373845'}
                                value={workLog.payoutData?.commissionAmount}
                              />
                            </TableCell>
                          </TableRow>
                        ))}

                        <TableRow sx={{ fontWeight: 'bold' }}>
                          <TableCell style={{ padding: '0 0 0 .5rem', fontWeight: 'bold' }}>Total Commission</TableCell>
                          <TableCell
                            align='right'
                            sx={{
                              fontWeight: 'bold',
                            }}
                          >
                            <Dollars
                              color={theme.mode === 'dark' ? '#fff' : '#373845'}
                              value={payoutData.totalCommissionAmount}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ padding: '0 0 0 .5rem', fontWeight: 'bold' }}>Tips</TableCell>
                          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
                            <Dollars
                              color={theme.mode === 'dark' ? '#fff' : '#373845'}
                              value={payoutData.totalTipsForDay}
                            />
                          </TableCell>
                        </TableRow>

                        {payoutData.fullPayoutItemsAfterCcFee > 0 && (
                          <>
                            <TableRow>
                              <TableCell style={{ padding: '0 0 0 .5rem' }}>Direct Payout Items</TableCell>
                              <TableCell align='right'>
                                <Dollars
                                  color={theme.mode === 'dark' ? '#fff' : '#373845'}
                                  value={payoutData.fullPayoutItemsBeforeCcFee}
                                />
                              </TableCell>
                            </TableRow>

                            {showingDetails && (
                              <>
                                <TableRow>
                                  <TableCell style={{ padding: '0 0 0 .5rem' }}>
                                    Direct payout items credit card fee
                                  </TableCell>
                                  <TableCell align='right'>
                                    (
                                    <Dollars
                                      color={theme.mode === 'dark' ? '#fff' : '#373845'}
                                      value={payoutData.fullPayoutItemsCcFee}
                                    />
                                    )
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ padding: '0 0 0 .5rem' }}>
                                    Direct payout after items credit card fee
                                  </TableCell>
                                  <TableCell align='right'>
                                    <b>
                                      <Dollars
                                        color={theme.mode === 'dark' ? '#fff' : '#373845'}
                                        value={payoutData.fullPayoutItemsAfterCcFee}
                                      />
                                    </b>
                                  </TableCell>
                                </TableRow>
                              </>
                            )}
                          </>
                        )}

                        {payoutData.tipAfterFee > 0 && (
                          <>
                            <TableRow>
                              <TableCell style={{ padding: '0 0 0 .5rem' }}>Tip</TableCell>
                              <TableCell align='right'>
                                <Dollars
                                  color={theme.mode === 'dark' ? '#fff' : '#373845'}
                                  value={payoutData.tipBeforeCcFee}
                                />
                              </TableCell>
                            </TableRow>

                            {showingDetails && (
                              <>
                                <TableRow>
                                  <TableCell style={{ padding: '0 0 0 .5rem' }}>Tip credit card fee</TableCell>
                                  <TableCell align='right'>
                                    (
                                    <Dollars
                                      color={theme.mode === 'dark' ? '#fff' : '#373845'}
                                      value={payoutData.tipCcFee}
                                    />
                                    )
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ padding: '0 0 0 .5rem' }}>Tip after credit card fee</TableCell>
                                  <TableCell align='right'>
                                    <b>
                                      <Dollars
                                        color={theme.mode === 'dark' ? '#fff' : '#373845'}
                                        value={payoutData.tipAfterFee}
                                      />
                                    </b>
                                  </TableCell>
                                </TableRow>
                              </>
                            )}
                          </>
                        )}

                        {payoutData.tipAfterFee === 0 && (
                          <TableRow>
                            <TableCell style={{ padding: '0 0 0 .5rem' }}>Tip</TableCell>
                            <TableCell align='right'>
                              <Dollars color={theme.mode === 'dark' ? '#fff' : '#373845'} value='0' />
                            </TableCell>
                          </TableRow>
                        )}

                        <TableRow style={{ background: theme.palette.background.highlight }}>
                          <TableCell style={{ borderBottom: 'none', padding: '0 0 0 .5rem', fontWeight: 'bold' }}>
                            Total payout {showingDetails && <> + hourly pay</>}
                          </TableCell>
                          <TableCell align='right' style={{ borderBottom: 'none', fontWeight: 'bold' }}>
                            <Dollars
                              color={theme.mode === 'dark' ? '#fff' : '#373845'}
                              value={
                                payoutData.totalPayout -
                                payoutData.totalHourlyPay +
                                ((showingDetails && payoutData.totalHourlyPay) || 0)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              )
            )
          })}
        </>
      )}
    </>
  )
}
