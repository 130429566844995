import React, { useContext } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import Login from './Login'
import LoginByTechnicianId from './LoginByTechnicianId'
import ActivateSession from './ActivateSession'

const AuthGateway = () => {
  return (
    <Switch>
      <Route path='/technician/:technicianId'>
        <LoginByTechnicianId />
      </Route>
      <Route path='/activate_session'>
        <ActivateSession />
      </Route>
      <Route path='/'>
        <Login />
      </Route>
    </Switch>
  )
}

export default AuthGateway
