import {
  Grid,
  TableBody,
  TableCell,
  TableRow,
  Table,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { Dollars, underscoreToTitleCase } from '../../../../tools'
import { useStyles } from 'presentation/pages/Payout/styles'

export const LoanInfo = ({ payout }) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <>
      <Grid item xs={12} className={classes.sectionHeader}>
        <div style={{ color: theme.mode === 'dark' ? '#fff' : '#373845', fontSize: '1.125rem' }}> Loan Details </div>
      </Grid>

      {(payout.loanDeductionAmount > 0 || payout.loanBalanceAfterThisPayout > 0) && (
        <Accordion className={classes.workOrderCard} elevation={1} key='equipLoan'>
          <AccordionSummary expandIcon={<ExpandMore />} style={{ padding: '0px', width: '100%' }}>
            <Grid container>
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ fontWeight: 600 }}>Equipment Loan</span>
                <span style={{ color: '#aaa', marginLeft: 'auto', marginRight: '1rem', display: 'block' }}>
                  <Dollars value={payout.loanBalanceAfterThisPayout} />
                </span>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0 0 .75rem 0', borderTop: '1px solid #eee', color: '#3a3a3a' }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ padding: '0 0 0 .5rem', borderBottomStyle: 'dotted', borderBottomWidth: 2 }}>
                    Previous Loan Balance
                  </TableCell>
                  <TableCell align='right' style={{ borderBottomStyle: 'dotted', borderBottomWidth: 2 }}>
                    <Dollars
                      color={theme.mode === 'dark' ? '#fff' : '#373845'}
                      value={payout.loanBalanceBeforeThisPayout}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ padding: '0 0 0 .5rem', borderBottomStyle: 'dotted', borderBottomWidth: 2 }}>
                    Loan Deduction
                  </TableCell>
                  <TableCell align='right' style={{ borderBottomStyle: 'dotted', borderBottomWidth: 2 }}>
                    (
                    <Dollars color={theme.mode === 'dark' ? '#fff' : '#373845'} value={payout.loanDeductionAmount} />)
                  </TableCell>
                </TableRow>

                <TableRow style={{ background: theme.palette.background.highlight }}>
                  <TableCell style={{ borderBottom: 'none', padding: '0 0 0 .5rem', fontWeight: 600 }}>
                    Current Balance
                  </TableCell>
                  <TableCell align='right' style={{ borderBottom: 'none', fontWeight: 600 }}>
                    <Dollars
                      color={theme.mode === 'dark' ? '#fff' : '#373845'}
                      value={payout.loanBalanceAfterThisPayout}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      )}

      {(payout.otherLoanDeductionAmount > 0 || payout.otherLoanBalanceAfterThisPayout > 0) && (
        <Accordion className={classes.workOrderCard} elevation={1} key='equipLoan'>
          <AccordionSummary expandIcon={<ExpandMore />} style={{ padding: '0px', width: '100%' }}>
            <Grid container>
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ fontWeight: 600 }}>Misc. Loan</span>
                <span style={{ color: '#aaa', marginLeft: 'auto', marginRight: '1rem', display: 'block' }}>
                  <Dollars value={payout.otherLoanBalanceAfterThisPayout} />
                </span>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0 0 .75rem 0', borderTop: '1px solid #eee', color: '#3a3a3a' }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ padding: '0 0 0 .5rem', borderBottomStyle: 'dotted', borderBottomWidth: 2 }}>
                    Previous Loan Balance
                  </TableCell>
                  <TableCell align='right' style={{ borderBottomStyle: 'dotted', borderBottomWidth: 2 }}>
                    <Dollars
                      color={theme.mode === 'dark' ? '#fff' : '#373845'}
                      value={payout.otherLoanBalanceBeforeThisPayout}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ padding: '0 0 0 .5rem', borderBottomStyle: 'dotted', borderBottomWidth: 2 }}>
                    Loan Deduction
                  </TableCell>
                  <TableCell align='right' style={{ borderBottomStyle: 'dotted', borderBottomWidth: 2 }}>
                    (
                    <Dollars
                      color={theme.mode === 'dark' ? '#fff' : '#373845'}
                      value={payout.otherLoanDeductionAmount}
                    />
                    )
                  </TableCell>
                </TableRow>

                <TableRow style={{ background: theme.palette.background.highlight }}>
                  <TableCell style={{ borderBottom: 'none', padding: '0 0 0 .5rem', fontWeight: 600 }}>
                    Current Balance
                  </TableCell>
                  <TableCell align='right' style={{ borderBottom: 'none', fontWeight: 600 }}>
                    <Dollars
                      color={theme.mode === 'dark' ? '#fff' : '#373845'}
                      value={payout.otherLoanBalanceAfterThisPayout}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      )}
      <br />
    </>
  )
}
