import useLocalForage from 'util/hooks/useLocalForage'
import { createContext } from 'react'

export const UserContext = createContext()

const UserStore = ({ children }) => {
  const [user, setUser, ready] = useLocalForage('user', {})

  if (!ready) return 'Loading'

  return (
    <UserContext.Provider value={[user, ready]}>{children}</UserContext.Provider>
  )
}

export default UserStore
