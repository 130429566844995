import { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { API_COMMAND_HEADERS, emailRegex } from 'tools'

const Login = () => {
  const [requestSent, setRequestSent] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [email, setEmail] = useState('')

  const handleEmailChanged = e => {
    setEmail(e.target.value.trim())
  }

  const handleClickGetLoginCode = () => {
    setIsSubmitting(true)
    fetch(`https://${process.env.REACT_APP_API_HOST}/app_session_request`, {
      method: 'POST',
      headers: API_COMMAND_HEADERS,
      body: JSON.stringify({ email }),
    })
      .then(res => {
        if (res.ok) setRequestSent(true)
        else throw 'error, probably wrong email'
      })
      .catch(e => {
        window.alert(e)
        setIsSubmitting(false)
      })
  }

  if (requestSent)
    return (
      <div style={{ margin: '1em' }}>
        <Typography>
          You'll be sent a link via email and text.
          <br />
          After you click it you can return back here and you'll be logged in.
          <br />
          <br />
          If you've clicked the login link and returned here, try reloading this page.
          <br />
          Or if you've added this to your homescreen, close this and re-open it.
          <br />
          <br />
          <Button variant='outlined' color='primary' onClick={() => window.location.reload()}>
            reload
          </Button>
        </Typography>
      </div>
    )

  const emailIsValid = emailRegex.test(email)

  return (
    <div style={{ margin: '1em' }}>
      <Typography variant='h6'>Login with your email address</Typography>

      <br />
      <Typography style={{ padding: '1em', border: '1px dashed red', fontSize: '17px', color: 'red' }} variant='h6'>
        It's best to add this to your homescreen before you request the login link
      </Typography>
      <br />

      <Typography style={{ padding: '1em', border: '1px dashed red', fontSize: '17px', color: 'red' }} variant='h6'>
        Use only Chrome or Safari browsers
      </Typography>
      <br />

      <TextField
        type='email'
        label='email'
        variant='outlined'
        size='small'
        onChange={handleEmailChanged}
        value={email}
      />

      <br />
      <br />
      <Button
        onClick={handleClickGetLoginCode}
        variant='contained'
        color='primary'
        disabled={isSubmitting || !emailIsValid}
      >
        Get login link
      </Button>
    </div>
  )
}

export default Login
