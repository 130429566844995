import { useEffect, useContext } from 'react'
import { UserContext } from './UserStore'
import Typography from '@material-ui/core/Typography'
import useQueryParams from 'util/hooks/useQueryParams'
import { login } from 'auth'
import { API_COMMAND_HEADERS } from 'tools'

const ActivateSession = () => {
  const query = useQueryParams()
  const [user] = useContext(UserContext)

  const key = query.get('key')
  const session_id = query.get('session_id')

  useEffect(() => {
    if (!key || !session_id) return
    fetch(`https://${process.env.REACT_APP_API_HOST}/app_session_activate`, {
      method: 'POST',
      headers: API_COMMAND_HEADERS,
      body: JSON.stringify({
        key: key,
        session_id: session_id,
      }),
    }).then(async res => {
      if (res.ok) login(await res.json())
      else throw 'failed to activate session'
    }).catch(e => {
      alert(e)
      window.location.href = '/'
    })
  }, [])

  return (
    (user.id && (
      <Typography>
        You're logged in now. Press the HOME button above to continue here or you can return to where you initiated the
        login link request.
      </Typography>
    )) || <Typography>Activating Session</Typography>
  )
}

export default ActivateSession
