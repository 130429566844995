import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { DateTime } from 'luxon'

const visitCountStyles = makeStyles(theme => ({
  secondVisit: {
    backgroundColor: '#fbd798',
    border: '2px dotted #cf8151',
    borderRight: '0px',
    borderLeft: '0px'
  },
  initialVisit: {
    backgroundColor: 'palegreen',
    border: '2px dotted #0d933e',
    borderRight: '0px',
    borderLeft: '0px'
  },
  thirdVisit: {
    backgroundColor: '#fb9898',
    border: '2px dotted #930d0d',
    borderRight: '0px',
    borderLeft: '0px'
  },
  visitNumberLoading: {
    display: 'block', padding: '.25rem .5rem', textAlign: 'center', fontSize: '.9rem'
  }
}))

const VisitCount = ({ job }) => {
  const classes = visitCountStyles()

  const activeJobs = job.quote.jobs.filter(job => job.isActive)

  const visitNumber = activeJobs.slice().sort((a, b) =>
    DateTime.fromISO(a.startDatetime) > DateTime.fromISO(b.startDatetime) ? 1 : -1
  ).findIndex(thisJob => thisJob.id === job.id) + 1

  return (
    <b
      className={visitNumber > 2 ? classes.thirdVisit : visitNumber > 1 ? classes.secondVisit : classes.initialVisit}
      style={{
        display: 'block',
        padding: '.25rem .5rem',
        textAlign: 'center',
        fontSize: '.9rem',
        color: '#111'
      }}
    >
      {visitNumber > 1 ? `Visit #${visitNumber} for this customer` : 'Initial visit'}
    </b>
  )
}

export default VisitCount
