import { gql } from "@apollo/client";

export const JOB = gql`
  query getJob($id: ID!) {
    job(id: $id) {
      id
      leadId
      quoteId
      postJobPhotos
      preJobPhotos
      startDatetime
      endDatetime
      technician {
        id
        commissionPercent
      }
      quote {
        id
        leadId
        calculatorPayload
        calculatorResult
        balanceAmountDue
        offeredDiscount
        laborSumAfterAllDiscounts
        technicianChargesSum
        primaryLaborSum
        partsSum
        carbodylabChargesSum
        postTaxGrandTotal
        taxableAmount
        taxTotal
        taxRate
        groupedPayments {
          id
          createdAt
          totalAmount
          tipAmount
          paymentType
          refundedAmount
          receiptUrl
          amountBeforeTip
          processingFeeAmount
          updatedAt
          origin
          paymentType
          sourceType
          squarePaymentId
          photoUrls
        }
        primaryLaborItems {
          id
          name
          panel
          side
          price
          totalHours
          totalPrepHours
          totalPaintHours
          totalBodyWorkHours
          totalMinorPartHours
          totalMajorPartHours
        }
        otherLaborItems {
          id
          name
          price
        }
        carbodylabCharges {
          id
          name
          price
        }
        technicianCharges {
          id
          name
          price
        }
        commissionPercentOverride
        payoutData
        jobs {
          id
          isActive
        }
        parts {
          id
          name
          number
          cost
          price
          status
          pickupStatus
          pickupHistory
          readyForPickupAt
          orderRequestedAt
          orderPlacedAt
          readyForPickupAt
          canceledAt
          returnInitiatedAt
          returnedToVendorAt
          returnCompletedAt
          refundInitiatedAt
          refundCompletedAt
          refundedAt
          etaBusinessDays
          isPaid
          invoiceNumber
          partVendorId
          partVendorContact
          hasCoreRefund
          coreRefundExpectedAmount
          coreRefundInitiatedAt
          coreReturnedToVendorAt
          coreRefundCompletedAt
          removedFromQuoteAt
          vendor {
            id
            name
            addressLineOne
            addressLineTwo
            city
            state
            zip
            phone
            website
            email
            paymentNotes
            lat
            lng
          }
        }
        lead {
          id
          name
          phone
          addressLineOne
          addressLineTwo
          city
          state
          zip
          make
          model
          year
          description
          photos
          repairLocation
          makeShortName
          modelShortName
          garageConfirmed
        }
      }
    }
  }
`