import {
  FormControlLabel,
  Checkbox,
  Modal,
  Typography,
  Container,
  Grid,
  Grow,
  Collapse,
  Select,
  MenuItem,
  FormControl,
  Drawer,
  InputLabel,
  Button,
  Box,
} from '@mui/material'
import { KeyboardArrowDown, Cancel } from '@mui/icons-material'
import { useState, useContext } from 'react'
import { UserContext } from 'UserStore'
import { bearerTokenHeaders, getUploadParamsForDropzone, thumbnailUrlForPhoto, fullSizeUrlForPhoto } from 'tools'
import Dropzone from 'react-dropzone-uploader'
import { TechnicianContext } from '../../../../GlobalStore'
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '700px',
  maxHeight: '80%',
  width: '1100px',
  maxWidth: '100%',
  boxShadow: 24,
  border: '1px solid black',
}

const Uploader = ({ url, closeDrawer, multiple, job, refetch }) => {
  const [user] = useContext(UserContext)
  const [technician] = useContext(TechnicianContext)

  const handleSubmit = files => {
    files.forEach(file =>
      window
        .fetch(`https://${process.env.REACT_APP_API_HOST}/${url}`, {
          method: 'POST',
          headers: bearerTokenHeaders(user.token),
          body: JSON.stringify({
            job_id: job.id,
            technician_id: job.technician.id,
            photo_url: file.meta.fileUrl,
          }),
        })
        .then(res => {
          if (res.ok) {
            files.forEach(file => file.remove())
            closeDrawer()
            refetch()
            return true
          } else {
            throw Error('error')
          }
        })
        .catch(e => window.alert(e.message))
    )
  }

  return (
    <Dropzone
      getUploadParams={getUploadParamsForDropzone}
      multiple={multiple}
      accept='image/jpg,image/jpeg,image/png'
      onSubmit={handleSubmit}
      submitButton
      inputContent='Click to Select Photos to Upload'
    />
  )
}

const PostJobPhotosSection = ({ refetch, job }) => {
  const [user] = useContext(UserContext)
  const [openPostJobPhotosDrawer, setOpenPostJobPhotosDrawer] = useState(false)
  const [expandPhotoSection, setExpandPhotoSection] = useState(false)
  const [showDeleteIconsOnPhotos, setShowDeleteIconsOnPhotos] = useState(false)
  const [currentFullScreenPhoto, setCurrentFullScreenPhoto] = useState(null)
  const postJobPhotos = job.postJobPhotos || []

  const deletePhoto = (e, photo) => {
    e.stopPropagation()

    window.confirm('Are you sure you want to delete this photo?') &&
      window
        .fetch(`https://${process.env.REACT_APP_API_HOST}/remove_post_job_photo_from_job`, {
          method: 'POST',
          headers: bearerTokenHeaders(user.token),
          body: JSON.stringify({
            actor_id: job.technician.id,
            actor_type: 'technician',
            photo_id: photo.id,
            job_id: job.id,
          }),
        })
        .then(res => {
          if (res.ok) {
            refetch()
            return true
          } else {
            throw Error('error')
          }
        })
        .catch(e => window.alert(e.message))
  }

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            borderRadius: '6px 6px 0px 0px',
            fontWeight: 700,
            fontSize: '14px',
            padding: '.5rem 1rem',
            background: '#bbc7d3',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Post-Job Photos
          {postJobPhotos.length > 0 && (
            <>
              <Button
                size='small'
                color='secondary'
                disabled={showDeleteIconsOnPhotos}
                onClick={() => setOpenPostJobPhotosDrawer(!openPostJobPhotosDrawer)}
                sx={{ ml: 'auto', padding: '0rem 1rem' }}
              >
                Add More
              </Button>
              <Button
                onClick={() => setShowDeleteIconsOnPhotos(!showDeleteIconsOnPhotos)}
                disableElevation
                size='small'
                sx={{
                  ml: '.25rem',
                  color: showDeleteIconsOnPhotos ? '#4765A4' : '#222',
                  padding: '0rem .5rem',
                }}
              >
                [ EDIT{showDeleteIconsOnPhotos && 'ING'} ]
              </Button>
            </>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            borderRadius: postJobPhotos.length > 0 && postJobPhotos.length < 9 ? '0px 0px 6px 6px' : '0px',
            background: '#fff',
            padding: '1rem .5rem',
            border: '1px solid #ddd',
            borderBottom: postJobPhotos.length > 0 && postJobPhotos.length < 8 ? '1px solid #ddd' : '1px solid #ddd',
            borderTop: '0px',
            textAlign: 'center',
          }}
        >
          {postJobPhotos.length > 0 ? (
            <Grid container spacing={2}>
              {postJobPhotos.map(
                (photo, index) =>
                  (expandPhotoSection || index < 8) && (
                    <Grid item xs={3} sm={3} md={2} key={photo.originalUrl}>
                      <Grid
                        onClick={() => setCurrentFullScreenPhoto(photo)}
                        sx={{
                          borderRadius: '3px',
                          minHeight: '75px',
                          background: `url(${thumbnailUrlForPhoto(photo)})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center center',
                        }}
                      >
                        <Grow in={showDeleteIconsOnPhotos} sx={{ width: '100%' }}>
                          <Grid container sx={{ display: 'flex' }}>
                            <Button
                              onClick={e => deletePhoto(e, photo)}
                              color='error'
                              sx={{ ml: 'auto', mt: '-.5rem', mr: '-.5rem' }}
                            >
                              <Cancel
                                color='error'
                                sx={{
                                  background: '#fff',
                                  ml: 'auto',
                                  mt: '-.5rem',
                                  mr: '-.5rem',
                                  borderRadius: '50px',
                                  border: '2px solid #E62937',
                                }}
                              />
                            </Button>
                          </Grid>
                        </Grow>
                      </Grid>
                    </Grid>
                  )
              )}
            </Grid>
          ) : (
            <Grid item xs={12}>
              Take photos of your work after you have finished. Protect yourself from future fraudulent claims.
            </Grid>
          )}
        </Grid>

        {postJobPhotos?.length > 8 && (
          <Grid
            item
            xs={12}
            onClick={() => setExpandPhotoSection(!expandPhotoSection)}
            sx={{
              border: '1px solid #ddd',
              fontSize: '14px',
              borderRadius: '0px 0px 6px 6px',
              display: 'flex',
              padding: '.25rem .5rem',
              background: '#f1f1f1',
              justifyContent: 'center',
              alignItems: 'center',
              '&:active': {
                background: '#eee',
              },
            }}
          >
            See
            {expandPhotoSection ? ' less' : ' all'}
            <KeyboardArrowDown
              sx={{
                transform: expandPhotoSection ? 'rotate(180deg)' : '',
                ml: '1rem',
                transition: 'all .25s ease-in-out',
              }}
            />
          </Grid>
        )}

        {postJobPhotos?.length < 1 && (
          <Grid
            item
            xs={12}
            sx={{
              border: '1px solid #ddd',
              fontSize: '14px',
              borderRadius: '0px 0px 6px 6px',
              display: 'flex',
              padding: '.25rem .5rem',
              background: '#f1f1f1',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              cy-data='upload-post-job-photos-button'
              size='small'
              variant='contained'
              onClick={() => setOpenPostJobPhotosDrawer(!openPostJobPhotosDrawer)}
              sx={{
                padding: '.25rem 1rem',
              }}
            >
              Upload Photos
            </Button>
          </Grid>
        )}
      </Grid>

      <Drawer
        open={openPostJobPhotosDrawer}
        onClose={() => setOpenPostJobPhotosDrawer(false)}
        anchor='bottom'
        PaperProps={{
          sx: {
            textAlign: 'center',
            pt: '1rem',
          },
        }}
      >
        <Grid container sx={{ padding: '0rem 1rem 1rem 1rem' }}>
          <Grid item xs={12} sx={{ pb: '1rem' }}>
            <Typography variant='h6'>Post-Job Photos</Typography>
          </Grid>
          <Grid item xs={12}>
            <div id='postJobPhotosUploader'>
              <Uploader
                multiple
                job={job}
                refetch={refetch}
                closeDrawer={() => setOpenPostJobPhotosDrawer(false)}
                url='add_post_job_photo_to_job'
              />
            </div>
          </Grid>
        </Grid>
      </Drawer>

      <Modal open={!!currentFullScreenPhoto} onClose={() => setCurrentFullScreenPhoto(null)}>
        <Box sx={modalStyle}>
          <div style={{ position: 'relative', height: '100%', width: '100%' }}>
            <Cancel
              sx={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                fontSize: '30px',
                color: '#fff',
                border: '2px solid #fff',
                borderRadius: '20px',
              }}
              onClick={() => setCurrentFullScreenPhoto(null)}
            />
            <PinchZoomPan position='center' maxScale={2}>
              <img src={currentFullScreenPhoto && currentFullScreenPhoto.originalUrl} />
            </PinchZoomPan>
          </div>
        </Box>
      </Modal>
    </Grid>
  )
}

export default PostJobPhotosSection
