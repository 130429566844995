import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom'
import Show from './Show'

const Router = ({ items }) => {
  const { path } = useRouteMatch()
  const { suppliesOrderId } = useParams()

  return (
    <Switch>
      <Route path={path}>
        <Show items={items} suppliesOrderId={suppliesOrderId} />
      </Route>
    </Switch>
  )
}

export default Router
