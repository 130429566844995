import { useContext, useState } from 'react'
import { UserContext } from './UserStore'
import { Redirect, Switch, Route, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Login from './Login'
import PayoutsRouter from './presentation/pages/Payout/Router'
import ActivateSession from './ActivateSession'
import Home from './Home'
import Job from './presentation/pages/Job/Job'
import { AppBar, Toolbar, useTheme } from '@mui/material'
import { Menu, CalendarToday } from '@mui/icons-material'
import Settings from './Settings'
// @ts-ignore
import BToBChat from 'BToBChat'
// @ts-ignore
import BToBInvoicesRouter from 'presentation/pages/BToBInvoices/Router'
// @ts-ignore
import Uploader from 'Uploader'
// @ts-ignore
import SuppliesOrdersRouter from './presentation/pages/SuppliesOrders/Router'
import TechnicianIssueDialog from 'presentation/components/TechnicianIssueDialog'
import Disclaimers from 'Disclaimers'
import { LeftDrawer } from 'presentation/components/LeftDrawer/LeftDrawer'
import PartsRouter from 'presentation/pages/Parts/Router'

const App = () => {
  const [user] = useContext(UserContext)
  const [menuOpen, setMenuOpen] = useState(false)
  const history = useHistory()
  const theme = useTheme()

  const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: '80px',
      paddingBottom: '15px',
      flexGrow: 1,
      padding: theme.spacing(1),
    },
    sideBarHeader: {
      padding: '.75rem .125rem 1rem .125rem',
      minHeight: '153px',
      minWidth: '300px',
      borderBottom: '2px solid #4295E0',
      background: 'linear-gradient(69.18deg, #4295E0 -1.33%, rgba(13, 51, 147, 0.75) 98.85%)',
      color: '#fff',
      fontFamily: 'Open Sans',
      fontWeight: 500,
    },
    buttonIcon: {
      justifyContent: 'center',
    },
    activeLabel: {
      backgroundColor: '#4295E0 !important',
    },
    label: {
      backgroundColor: 'transparent',
    },
    menuButton: {
      minHeight: '40px',
      marginBottom: '6px',
    },
  }))

  const classes = useStyles(theme)

  const UserRoute = ({ children, path }: { children: JSX.Element; path: string }) => {
    return (
      <Route
        render={({ location }) =>
          user.id && user.token ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          )
        }
      />
    )
  }

  const handleClickMenuPath = (path: string) => {
    setMenuOpen(false)
    history.push(path)
  }

  return (
    <>
      <AppBar position='fixed'>
        <Disclaimers />
        <Toolbar
          sx={{
            '& .MuiBadge-badge': {
              color: '#0d3393bf',
              backgroundColor: '#fff',
            },
          }}
        >
          <Menu style={{ marginRight: 'auto' }} onClick={() => setMenuOpen(true)} />
          {user.id && <CalendarToday onClick={() => handleClickMenuPath('/schedule')} />}
          {/* { user.id && process.env.NODE_ENV === "development" &&
            <Button onClick={logout}>logout</Button> } */}
        </Toolbar>
      </AppBar>
      <LeftDrawer
        classes={classes}
        setMenuOpen={setMenuOpen}
        menuOpen={menuOpen}
        handleClickMenuPath={handleClickMenuPath}
      />

      <main className={classes.content}>
        <Switch>
          <Route path='/login'>{user.id ? <Redirect to='/' /> : <Login />}</Route>
          <Route path='/activate_session'>{user.id ? <Redirect to='/' /> : <ActivateSession />}</Route>
          <Route path='/jobs/:jobId'>
            <Job />
          </Route>
          <Route path='/parts'>
            <PartsRouter />
          </Route>
          <Route path='/payouts'>
            <PayoutsRouter />
          </Route>
          <Route path='/settings'>
            <Settings />
          </Route>
          <Route path='/schedule'>
            <Home />
          </Route>
          <UserRoute path='/supplies-orders'>
            <SuppliesOrdersRouter />
          </UserRoute>
          <UserRoute path='/b-to-b-chat'>
            <BToBChat />
          </UserRoute>
          <UserRoute path='/b-to-b-invoices'>
            <BToBInvoicesRouter />
          </UserRoute>
          <UserRoute path='/b-to-b-work-orders'>
            <BToBInvoicesRouter />
          </UserRoute>
          <UserRoute path='/uploader'>
            <Uploader />
          </UserRoute>

          <UserRoute path='/'>
            <Home />
          </UserRoute>
        </Switch>
      </main>
    </>
  )
}

export default App
