import React, { useState } from 'react'

import PinchZoomPan from 'react-responsive-pinch-zoom-pan'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'

import { fullSizeUrlForPhoto, thumbnailUrlForPhoto } from 'tools'

const useStyles = makeStyles(theme => ({
  thumbnailPaper: {
    color: theme.palette.text.secondary,
    width: '33%',
    maxWidth: '160px',
    display: 'inline-flex',
    padding: 0,
    maxHeight: '100px'
  },
  thumbnailImg: {
    padding: '2px'
  },
  modalClose: {
    top: '0.5em',
    right: '0.5em',
    position: 'absolute',
    fontSize: '1.5em',
    color: 'red',
    border: '1px solid red',
    padding: '0 0.3em',
    borderRadius: '5px',
    zIndex: '10',
    cursor: 'pointer',
    backgroundColor: 'white'
  },
  modalPaper: {
    display: 'flex',
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    boxShadow: theme.shadows[5],
    padding: 0
  },
  customerPhotosHeader: {
    position: 'sticky',
    zIndex: 2,
    top: 0,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    backgroundColor: '#c798fb',
    padding: '0.5rem',
    borderRadius: '3px 3px 0 0',
    color: '#111',
    borderBottom: '1px solid #930d50',
    marginBottom: '.5rem'
  }
}))

const CustomerPhotos = ({ lead }) => {
  const classes = useStyles()
  const handleClosePhotoModal = () => setPhotoModalOpen(false)
  const [photoModalOpen, setPhotoModalOpen] = useState(false)
  const [currentPhotoModalUrl, setCurrentPhotoModalUrl] = useState('')

  const handleClickPhotoThumbnail = url => {
    setCurrentPhotoModalUrl(url)
    setPhotoModalOpen(true)
  }

  return (
    <>
      <Paper>
        <Typography className={classes.customerPhotosHeader}>
          Customer Photos
        </Typography>

        {lead.photos.map((photo, index) =>
          <Paper key={index} className={classes.thumbnailPaper}>
            <img
              className={classes.thumbnailImg}
              style={{ width: '100%' }}
              onClick={() => handleClickPhotoThumbnail(fullSizeUrlForPhoto(photo))}
              src={thumbnailUrlForPhoto(photo)}
            />
          </Paper>
        )}
      </Paper>

      <Modal
        open={photoModalOpen}
        onClose={handleClosePhotoModal}
      >
        <div className={classes.modalPaper}>
          <div
            onClick={() => setPhotoModalOpen(false)}
            className={classes.modalClose}
          >
            X
          </div>
          <PinchZoomPan position='center' maxScale={2}>
            <img src={currentPhotoModalUrl} />
          </PinchZoomPan>
        </div>
      </Modal>
    </>
  )
}

export default CustomerPhotos
