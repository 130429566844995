import { Switch, Route, useParams } from 'react-router-dom'
import { useLazyQuery, gql } from '@apollo/client'
import { useState, useEffect, useContext } from 'react'
import { useEventHandler } from '@ericlathrop/phoenix-js-react-hooks'
import { keysToCamel } from 'tools'
import ShowEdit from './components/ShowEdit'
import ShowEditV1 from './components/ShowEditV1'
import { TechnicianChannelContext } from 'TechnicianChannelStore'

const QUERY = gql`
  query bToBInvoice($id: ID!) {
    bToBInvoice(id: $id) {
      id
      createdAt
      businessName
      lineItems
      lineItemsSum
      grandTotal
      discountPercent
      discountPercentTotal
      preJobPhotos
      postJobPhotos
      year
      make
      makeShortName
      model
      modelShortName
      payoutData
      vinPhoto
      completedAt
      squareInvoiceId
      deletedAt
      dealerId
      invoiceVersion
      label
      vin
      stock
      repairOrder

      dealer {
        id
        businessName
      }
      workOrder {
        id
        parts {
          id
          name
        }
      }
    }
  }
`

const BtoBInvoiceRouter = ({ invoice: prefetchedInvoice }) => {
  const { bToBInvoiceId } = useParams()
  const channel = useContext(TechnicianChannelContext)
  const [updatedInvoice, setUpdatedInvoice] = useState(null)

  const [getInvoice, { data, error, refetch }] = useLazyQuery(QUERY, {
    variables: { id: bToBInvoiceId },
  })

  useEventHandler(channel, 'b_to_b_invoice_updated', ({ bToBInvoice }) => {
    // bToBInvoice.id === bToBInvoiceId && setUpdatedInvoice(bToBInvoice)
    bToBInvoice.id === bToBInvoiceId && refetch()
  })

  useEffect(() => {
    prefetchedInvoice || getInvoice()
  }, [])

  const invoice = updatedInvoice || (data && data.bToBInvoice) || prefetchedInvoice

  if (!invoice) return <>loading...</>
  if (error) return <>error...</>

  return (
    <Switch>
      <Route path='/b-to-b-invoices/:bToBInvoiceId'>
        {invoice.invoiceVersion && invoice.invoiceVersion > 1 ? (
          <ShowEdit refetch={refetch} invoice={keysToCamel(invoice)} />
        ) : (
          <ShowEditV1 invoice={keysToCamel(invoice)} />
        )}
      </Route>
    </Switch>
  )
}

export default BtoBInvoiceRouter
