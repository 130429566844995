import React, { useState, useContext } from 'react'
import { GlobalContext } from 'GlobalStore'
import { UserContext } from 'UserStore'
import { DateTime } from 'luxon'
import { Collapse, Grid, TextField, Button, Modal, Box } from '@mui/material'
import { useChannel, useEventHandler } from '@ericlathrop/phoenix-js-react-hooks'
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@mui/material/Skeleton'
import VisibilitySensor from 'react-visibility-sensor-v2'
import { PhotoUploader } from './ChatPhotoUploader'
import { BadgeRounded, KeyboardArrowDown, StarRounded, VerifiedRounded } from '@mui/icons-material'

const Styles = makeStyles(theme => ({
  modalClose: {
    top: '0.5em',
    right: '0.5em',
    position: 'absolute',
    fontSize: '1.5em',
    color: 'red',
    border: '1px solid red',
    padding: '0 0.3em',
    borderRadius: '5px',
    zIndex: '10',
    cursor: 'pointer',
    backgroundColor: 'white',
  },
  modalPaper: {
    display: 'flex',
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    boxShadow: theme.shadows[5],
    padding: 0,
  },
}))

export const Chat = ({ contextType, contextId }) => {
  const classes = Styles()
  const [messages, setMessages] = useState([])
  const [global] = useContext(GlobalContext)
  const [user] = useContext(UserContext)
  const [body, setBody] = useState('')
  const [photoModalUrl, setPhotoModalUrl] = useState(null)
  const [loadingInitialMessages, setLoadingInitialMessages] = useState(true)
  const [openPhotoSelector, setOpenPhotoSelector] = useState(false)

  const windowHeight = window && window.innerHeight

  const scrollToBottom = () => {
    let element = document.getElementById('chatMessageList')
    if (element) element.scrollTop = element.scrollHeight - element.clientHeight
  }

  const channel = useChannel(`chat:${contextType}$${contextId}`, undefined, (channel, { chatMessages }) => {
    setMessages(chatMessages.map((message, index) => ({ ...message, renderPhotos: index < 4 })).reverse())
    setTimeout(() => {
      setLoadingInitialMessages(false)
      scrollToBottom()
    }, 300)
  })

  useEventHandler(channel, 'new_chat_message', ({ chatMessage }) => {
    setMessages(messages => [...messages, { ...chatMessage, renderPhotos: true }])
    setTimeout(scrollToBottom, 250)
  })

  const csrById = id => global.csrs.find(csr => csr.id === id)
  const csrName = id => {
    let csr = csrById(id)
    return `${csr.firstName} ${csr.lastName[0]}.`
  }

  const authorName = message =>
    (message.authorType === 'csr' && csrName(message.authorId)) ||
    (message.authorType === 'dealer' && (
      <Box sx={{ '& svg': { height: '12px', margin: '0px' } }}>
        Dealer<VerifiedRounded />
      </Box>
    )) ||
    (message.authorType === 'tech' && message.authorId === user.id && 'me')

  const createChatMessage = () =>
    fetch(`https://${process.env.REACT_APP_API_HOST}/create_chat_message`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        authorId: user.id,
        authorType: 'tech',
        body: body.trim(),
        contextType: contextType,
        contextId: contextId,
      }),
    })
      .then(response => {
        if (response.ok) {
          setBody('')
          return true
        } else {
          throw Error('error')
        }
      })
      .catch(e => {
        alert(e.message)
      })

  const handleVisibilityOfMessagePhotos = (messageId, visibility) => {
    if (!visibility) return null

    setMessages(messages =>
      messages.map(message => (message.id === messageId ? { ...message, renderPhotos: true } : message))
    )
  }

  return (
    <Grid item xs={12}>
      <Grid
        container
        sx={{
          mt: '1rem',
          border: '1px solid #ddd',
          borderRadius: '6px',
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            borderRadius: '6px 6px 0px 0px',
            fontWeight: 700,
            padding: '.5rem 1rem',
            background: '#BFD5ED',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Invoice notes/chat
        </Grid>

        <Grid
          item
          xs={12}
          id='chatMessageList'
          style={{
            fontSize: '1em',
            overflow: 'auto',
            backgroundColor: 'white',
            maxHeight: '50vh',
            overflowY: 'auto',
          }}
        >
          {(loadingInitialMessages && <div>Loading...</div>) ||
            (messages.length === 0 && (
              <div style={{ marginTop: '2em', padding: '0rem 1rem 1rem 1rem' }}>No messages</div>
            ))}

          {messages.map(message => (
            <div key={message.id}>
              <div style={{ whiteSpace: 'pre-wrap', padding: '0.5em', margin: '0.2em' }}>
                <b>{authorName(message)}</b>&nbsp;
                <small style={{ color: '#747474' }}>
                  {DateTime.fromISO(message.createdAt).toLocaleString(DateTime.DATETIME_FULL)}
                </small>
                <br />
                <div style={{ margin: '0 0.75em' }}>{message.body}</div>
                {(message.mediaUrls || []).map(mediaUrl => {
                  let photoSideNumber = Math.floor(Math.max(120 / message.mediaUrls.length, 80))
                  let photoSidePx = `${photoSideNumber}px`

                  return (
                    <div
                      key={mediaUrl}
                      style={{
                        border: '2px solid #efefef',
                        display: 'inline-block',
                        width: photoSidePx,
                        height: photoSidePx,
                      }}
                      onClick={() => setPhotoModalUrl(mediaUrl)}
                    >
                      <VisibilitySensor
                        onChange={isVisible => handleVisibilityOfMessagePhotos(message.id, isVisible)}
                        intervalDelay={250}
                      >
                        {(message.renderPhotos && (
                          <img
                            src={mediaUrl}
                            style={{
                              objectFit: 'cover',
                              height: photoSidePx,
                              width: photoSidePx,
                              cursor: 'pointer',
                            }}
                          />
                        )) || <Skeleton variant='rectangular' width={photoSideNumber} height={photoSideNumber} />}
                      </VisibilitySensor>
                    </div>
                  )
                })}
              </div>
            </div>
          ))}
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            background: '#fefefe',
            padding: '.5rem 1rem',
            display: 'flex',
            borderRadius: '0px 0px 6px 6px',
            flexDirection: 'column',
          }}
        >
          <TextField
            value={body}
            style={{ backgroundColor: 'white', width: '100%' }}
            multiline
            onChange={e => setBody(e.target.value)}
          />

          <Button
            sx={{ ml: 'auto', marginTop: '5px' }}
            variant='outlined'
            color='primary'
            disabled={body.length === 0}
            onClick={createChatMessage}
          >
            Send
          </Button>
        </Grid>

        <Modal open={!!photoModalUrl} onClose={() => setPhotoModalUrl(null)}>
          <div className={classes.modalPaper}>
            <div onClick={() => setPhotoModalUrl(null)} className={classes.modalClose}>
              X
            </div>
            <PinchZoomPan position='center' maxScale={2}>
              <img src={photoModalUrl} />
            </PinchZoomPan>
          </div>
        </Modal>

        <Collapse in={openPhotoSelector} sx={{ width: '100%' }}>
          <PhotoUploader contextType={contextType} contextId={contextId} />
        </Collapse>

        <Grid
          item
          xs={12}
          onClick={() => setOpenPhotoSelector(!openPhotoSelector)}
          sx={{
            border: '1px solid #ddd',
            fontSize: '14px',
            borderRadius: '0px 0px 6px 6px',
            display: 'flex',
            padding: '.25rem .5rem',
            background: '#f1f1f1',
            justifyContent: 'center',
            alignItems: 'center',
            '&:active': {
              background: '#eee',
            },
          }}
        >
          {openPhotoSelector ? <> Hide Photo Selection </> : <> Add Photo </>}
          <KeyboardArrowDown
            sx={{
              transform: openPhotoSelector ? 'rotate(180deg)' : '',
              ml: '1rem',
              transition: 'all .25s ease-in-out',
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Chat
