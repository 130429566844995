import NumberFormat from 'react-number-format'
import { DateTime } from 'luxon'

const API_AUTH_STRING = 'Basic ' + btoa(`${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`)

export const API_COMMAND_HEADERS = {
  Authorization: API_AUTH_STRING,
  'Content-Type': 'application/json'
}

export const utcToday = () => DateTime.fromISO(DateTime.local().toISODate(), { zone: 'utc' })

export const Dollars = ({ value, color }) => (
  <NumberFormat
    style={{ color: color }}
    value={value}
    decimalScale={2}
    fixedDecimalScale
    displayType='text'
    thousandSeparator
    prefix='$'
  />
)

export const Percent = ({ value, decimals = 1, color }) => (
  <NumberFormat style={{ color: color }} value={value} decimalScale={decimals} displayType='text' suffix='%' />
)

// Takes a string timestamp and returns the formatted date
export const formatFullDate = s => DateTime.fromJSDate(new Date(s)).toFormat('DD')

export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0
    const v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const bearerTokenHeaders = token => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
})

const toCamel = s => {
  return s.replace(/([-_][a-z])/gi, $1 => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

const isObject = o => {
  return o === Object(o) && !isArray(o) && typeof o !== 'function'
}

const isArray = function (a) {
  return Array.isArray(a)
}

export const keysToCamel = o => {
  if (isObject(o)) {
    const n = {}

    Object.keys(o).forEach(k => {
      n[toCamel(k)] = keysToCamel(o[k])
    })

    return n
  } else if (isArray(o)) {
    return o.map(i => {
      return keysToCamel(i)
    })
  }

  return o
}

export const nowAsIfInUtc = () => DateTime.fromISO(DateTime.local().toISO({ includeOffset: false }), { zone: 'utc' })

const getPresignedUploadParams = async filename =>
  fetch(`https://${process.env.REACT_APP_API_HOST}/get_presigned_s3_url`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({ filename }),
  })
    .then(res => res.ok && res.json())
    .then(data => data)

export const getUploadParamsForDropzone = async ({ file, meta: { name } }) => {
  const { uploadUrl, fileUrl } = await getPresignedUploadParams(name)
  return { body: file, meta: { fileUrl }, url: uploadUrl, method: 'put' }
}

export const basicDateTimeFormat = 'yyyy-LL-dd, hh:mm a'

export const thumbnailUrlForPhoto = photo => {
  return encodeURI(decodeURI((photo.thumbnailUrl || photo.largeUrl || photo.originalUrl).replace(/^http:/, 'https:')))
}

export const fullSizeUrlForPhoto = photo => {
  return encodeURI(decodeURI((photo.largeUrl || photo.originalUrl).replace(/^http:/, 'https:')))
}

export const toProperCase = o =>
  o && o.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())

export const underscoreToTitleCase = o => toProperCase(o && o.replace(/_/g, ' '))

export const addressDirectionsLink = address =>
  'https://www.google.com/maps/place/' +
  encodeURIComponent(address.addressLineOne + ',' + address.city + ',' + address.state + ',' + address.zip)

export const onlyUniqueFilter = (value, index, self) => self.indexOf(value) === index

export const pluralizeText = (str, num) => {
  if (!str) {
    return ''
  }

  if (str.slice(-1) === 's') {
    return str
  }

  return num > 1 || num < 1 ? str + 's' : str
}

export const diff = (obj1, obj2, exclude) => {
  obj1 = obj1 || {}
  obj2 = obj2 || {}
  exclude = exclude || []
  const r = {}

  for (const prop in obj1) {
    if (Object.hasOwnProperty.call(obj1, prop) && prop !== '__proto__' && exclude.indexOf(prop) === -1) {
      // check if obj2 has prop
      if (!Object.hasOwnProperty.call(obj2, prop)) r[prop] = obj1[prop]
      // check if prop is object and
      // NOT a JavaScript engine object (i.e. __proto__), if so, recursive diff
      else if (obj1[prop] === Object(obj1[prop])) {
        const difference = diff(obj1[prop], obj2[prop])
        if (Object.keys(difference).length > 0) r[prop] = difference
      }

      // check if obj1 and obj2 are equal
      else if (obj1[prop] !== obj2[prop]) {
        if (obj1[prop] === undefined) {
          r[prop] = 'undefined'
        }
        if (obj1[prop] === null) {
          r[prop] = null
        } else if (typeof obj1[prop] === 'function') {
          r[prop] = 'function'
        } else if (typeof obj1[prop] === 'object') {
          r[prop] = 'object'
        } else {
          r[prop] = obj1[prop]
        }
      }
    }
  }

  return r
}
